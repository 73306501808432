"use strict";
import C from '../../actions/constants';
const initialState = {
    selectedSalesRep: [],
}
export default function salesRepFilterReducer(state = initialState, action) {
    switch (action.type) {
        case C.SET_SALES_REP:
            return {
                ...state,
                selectedSalesRep: action.payload

            }
        default:
            return state;
    }
}