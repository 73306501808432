import React, { Component } from 'react';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDateFilter, setHolisticFilterDate } from '../../actions/filter/DateFilterAction';
import * as moments from 'moment'
const moment = extendMoment(originalMoment);
const $ = window.$;

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2018-02-28
*/

class DateFilter extends Component {

    constructor(props) {
        super(props);
        const today = moment();
        const numberOfDaysInMonth = moment(today.clone().date(0).format('YYYY-MM-DD'), "YYYY-MM").daysInMonth();
        // if (localStorage.getItem("toDate") !== null || localStorage.getItem("toDate") !== '' || localStorage.getItem("toDate") !== undefined)
        this.state = {
            value: moment.range(today.clone().date(1).subtract(numberOfDaysInMonth, "days"), today.clone().date(0)),
            isOpen: false,
            dateFilter: this.props.dateFilter,
            holisticDate: false,
        };
        this.onSelect = this.onSelect.bind(this);
        this.dateSelectColor = this.dateSelectColor.bind(this);
        this.onSelectStart = this.onSelectStart.bind(this);
        this.renderSelectionValue = this.renderSelectionValue.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);

        // this.props.setDateFilter({
        //     fromDate:this.state.value.start.format('YYYY-MM-DD'),
        //     toDate:this.state.value.end.format('YYYY-MM-DD')
        // });
    }


    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }
    onToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
        this.dateSelectColor();
    }
    renderSelectionValue = () => {
        let startdate = moments(this.props.dateFilter.holisticFromDate, 'YYYY-MM-DD')
        let enddate = moments(this.props.dateFilter.holisticToDate, 'YYYY-MM-DD')
        console.log('startdatestartdate', startdate)
        if (this.state.holisticDate) {
            return (
                <span style={{ "margin-left": "23px" }} onClick={this.onToggle} className="date">
                    {startdate.format("DD/MMM/YYYY")}
                    {" to "}
                    {enddate.format("DD/MMM/YYYY")}
                </span>
            );
        } else {
            return (
                <span style={{ "margin-left": "23px" }} onClick={this.onToggle} className="date">
                    {this.state.value.start.format("DD/MMM/YYYY")}
                    {" to "}
                    {this.state.value.end.format("DD/MMM/YYYY")}
                </span>
            );
        }
    }

    onSelect = (value, states) => {
        this.setState({
            isOpen: !this.state.isOpen,
            value: value
        });

        if (this.state.holisticDate) {
            this.props.setHolisticFilterDate({
                holisticFromDate: value.start.format('YYYY-MM-DD'),
                holisticToDate: value.end.format('YYYY-MM-DD')
            })
        } else {
            this.props.setDateFilter({
                fromDate: value.start.format('YYYY-MM-DD'),
                toDate: value.end.format('YYYY-MM-DD')
            });
        }
        this.dateSelectColor();
    }
    dateSelectColor() {
        var st = setInterval(function () {

            if (document.getElementsByClassName('DateRangePicker')[0]) {

                $(document).find('.DateRangePicker__MonthDates tbody .DateRangePicker__Date').removeAttr('styleattr');
                $(document).find('.DateRangePicker__MonthDates tbody .DateRangePicker__Date span + div').each(function () {
                    var lastClass = $(this).attr('class').split(' ').pop();
                    $(this).parent().attr('styleattr', lastClass);
                });

                var ele = document.querySelectorAll('[styleattr="DateRangePicker__CalendarSelection--start"]');
                var seg = document.querySelectorAll('[styleattr="DateRangePicker__CalendarSelection--segment"]');
                var startP = document.querySelectorAll('[styleattr="DateRangePicker__CalendarSelection--start--is-pending"]')
                if (!(ele.length > 0) && !(startP.length > 0) && !(document.querySelectorAll('[styleattr="DateRangePicker__CalendarSelection--single--is-pending"]').length > 0)) {
                    if (seg[0] != undefined && seg[0].previousElementSibling) {
                        seg[0].previousElementSibling.setAttribute('startDate', 'startDate');
                    }
                    else {
                        try {
                            seg[0].parentElement.previousElementSibling.lastChild.setAttribute('startDate', 'startDate');
                        } catch (e) { }
                    }
                }
                else {
                    if (document.querySelectorAll('[startDate="startDate"]')[0]) {
                        document.querySelectorAll('[startDate="startDate"]')[0].setAttribute('startDate', '')
                    }
                }

            }

        }, 70);
    }
    onSelectStart = (value, states) => {
        this.dateSelectColor();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.dateFilter != this.props.dateFilter) {
            this.setState({
                fromDate: newProps.dateFilter.fromDate,
                toDate: newProps.dateFilter.toDate,
                dateFilter: newProps.dateFilter,
                value: moment.range(moment(newProps.dateFilter.fromDate), moment(newProps.dateFilter.toDate))
            });
            this.renderSelectionValue();
        }
    }
    updateDateFilter = () => {
        const { dateFilter } = this.state;
        this.setState({
            fromDate: dateFilter.fromDate,
            toDate: dateFilter.toDate,
            value: moment.range(moment(dateFilter.fromDate), moment(dateFilter.toDate))
        });
        this.renderSelectionValue();
    }
    componentDidMount() {

        var locationPath = window.location.pathname;
        var arrVars = locationPath.split("/");
        if (arrVars[1]) {
            this.setState({ holisticDate: true })
        }
    }
    componentWillMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.updateDateFilter();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        return (

            <li>

                {this.props.displayName != "Usage" ? <div class="input-field reportsDateRangeWrap" onClick={this.mixpanel}>
                    <div id="reportsDateRange" type="text" onClick={this.onToggle}>

                        <img src={process.env.PUBLIC_URL + '/assets/images/calender.svg'} />
                        {this.renderSelectionValue()}
                        <i class="material-icons">arrow_drop_down</i>
                    </div>
                    <span ref={this.setWrapperRef}>
                        {
                            this.state.isOpen && (
                                <DateRangePicker
                                    value={this.state.value}
                                    onSelect={this.onSelect}
                                    singleDateRange={true}
                                    numberOfCalendars={2}
                                    onSelectStart={this.onSelectStart}
                                //maximumDate={moment()}
                                />
                            )}
                    </span>
                </div> : ""}
            </li>

        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setDateFilter: setDateFilter,
    setHolisticFilterDate: setHolisticFilterDate
}, dispatch);

const mapStateToProps = state => {
    return {
        dateFilter: state.dateFilter,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);