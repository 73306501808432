
import config from '../config/config';
import axios from 'axios';

class AutoSuggestService {

    fetchMetaDataConfig=()=>{
    
        let postObj={};
        postObj.method='POST';
        postObj.url=`config.reports.SEARCH_BOX_FILTER?text=${this.value}&field=${this.field}`;
        postObj.crossDomain=true;
        postObj.withCredentials=true;
        postObj.data=this.getBody();
        postObj.headers=this.getHeader();
        return axios(postObj);
    }
    getUrl=()=>{
        return config.reports.METADATA;
    }
    getBody=()=>{
        return {indexPrefix: config.index.LINEITEM_AGGREGATE_METRICS};
    }
    getHeader=()=>{
        return {corp:localStorage.getItem('CorpId'),'content-type': 'application/json'};
    }
}


export default AutoSuggestService;