import React from 'react';
import { connect } from 'react-redux';

const ExportPreloader=(props)=>{
    if (props.spinner.isActive) return <div id="cover-spin"></div>;return null;
}

const mapStateToProps=state=>{
    return {
        spinner:state.spinner
    }
}

export default connect(mapStateToProps, null)(ExportPreloader)