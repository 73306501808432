"use strict";
import C from '../../actions/constants';
const initialState = {
  regions: [],
  allRegionFilter: []
}
export default function regionFilterReducer(state = initialState, action) {
  switch (action.type) {
    case C.SET_REGION_FILTER:
      return {
        ...state,
        regions: action.regionFilter
      };
    case C.RESET_REGION_FILTER_DATA:
      return {
        ...state,
        regions: []
      }
    case C.SET_All_REGION_FILTER:
      return {
        ...state,
        allRegionFilter: action.regionFilter
      }
    case C.REMOVE_REGION:
      let index = state.regions.findIndex(f => f == action.payload);
      state.regions.splice(index, 1);
      return {
        ...state,
        regions: [...state.regions]

      }
    default:
      return state;
  }
};