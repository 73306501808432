"use strict";
import C from '../../actions/constants';
import ActivityIndicator from "../../components/common/ActivityIndicator";
import React from "react";
  export default function topMetricsCount(state={
    campaignCount: <ActivityIndicator height="20" width="20" indicating={true} />,
    impression: <ActivityIndicator height="20" width="20" indicating={true} />,
    clicks: <ActivityIndicator height="20" width="20" indicating={true} />,
    ctr: <ActivityIndicator height="20" width="20" indicating={true} />
  }, action) {
    switch(action.type) {
      case C.TOP_METRICS_COUNT:
        return {
          ...state,
          campaignCount:action.data['campaignCount'],
          impression:action.data['impression'],
          clicks: action.data['clicks'],
          ctr: action.data['ctr']
      };
      default:
        return state;
    }
  };
  