const styles = theme => ({
    drawChattable: {
        '& #dashboardTable': {
            '& td:nth-child(1)': {
                paddingLeft: '20px !important'
            }
        },
        // '& td:nth-child(2)': {
        //     '& span::first-word': {
        //         padding: '10px',
        //         background: 'red'
        //     }
        // },
        '& .histrolicLogo ': {
            margin: '0 20px'
        }
    },
    overView: {
        boxShadow: 'unset !important',
        WebkitBoxShadow: 'none !important',
        MozBoxShadow: 'none !important',
        // boxShadow: 'none !important',
    },
    holistheader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: '3rem',
        height: '54px'

    },
    cliveShort: {
        marginRight: '0px !important',
        '& h2': { marginTop: '0 !important' }
    },
    cliveShort2: {
        '& p': {
            fontSize: '15px',
            color: '#666',
            float: 'right'
        },
        display: 'none',
        background: 'white',
        border: '1px solid #cec6c6',
        boxShadow: '0 0 1px rgba(255, 255, 255, .8)',
        padding: '15px 15px',
        overflow: 'hidden',
        marginRight: '0px !important',
        '& h2': {
            marginTop: '0 !important',
            float: 'left'
        }
    },
    dateHolist: {
        lineHeight: '54px',
        marginRight: '0px !important',
        height: '54px'
    },
    drawContainer: {
        margin: '130px 20px 20px 70px',
        '& h2': {
            fontSize: '1.20rem',
            color: '#888',
            textTransform: 'uppercase',
            margin: '25px 0 0 0',
            fontWeight: '600',
        },
        "& $cliveShort": {
            marginRight: '0px !important',
            '& h2': { marginTop: '0' }
        },
    },
    claimed: {
        background: '#dc3d45',
        color: 'white',
        width: '15%',
        height: '140px',
        fontSize: '1.675rem',
        fontWeight: '600',
        padding: 15,
        position: "absolute",
        right: 40,
        top: 136,
        borderRadius: 5,
        margin: '130px 0px 20px 70px',
        ['@media (max-width:1024px)']: {
            width: 200,
            fontSize: 'inherit'
        },
    },
    claimedYes: {
        background: '#13e13d',
        color: 'white',
        width: '16%',
        height: '140px',
        fontSize: '1.675rem',
        fontWeight: '600',
        padding: 15,
        position: "absolute",
        right: 40,
        top: 136,
        borderRadius: 5,
        margin: '130px 0px 20px 70px',
        ['@media (max-width:1024px)']: {
            width: 200,
            fontSize: 'inherit'
        },
    },
    claimed1: {
        display: "block",
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '18px'
    },
    claimed2: {
        position: "absolute",
        right: 16,
        bottom: 16,
    },
    drawChat: {
        '& canvas': {
            width: '80% !important',
            paddingRight: 15,
            borderRight: '1px solid #eee',
            height: '300px !important'
        },
    },
    centraldrawChat: {
        width: '80% !important',
        '& canvas': {
            width: '100% !important',
            // paddingRight: 15,
            // // borderRight: '1px solid #eee',
            height: '300px !important'
        },
    },
    drawChatimpression: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .boxContainer': {
            width: '19%',
            '& h2': {
                fontSize: '0.75rem',
                lineHeight: '1',
                paddingBottom: 0,
                '& span': {
                    display: 'block',
                    fontSize: '1.7rem',
                    marginTop: 5,
                    fontWeight: '600',
                },
            },
            '& .headingtagWrap': {
                borderBottom: 'none',
            }
        },
        '& .graphDetails': {
            padding: '5px 10px',
            textAlign: 'center'
        },
        width: '100% !important',
        height: '225px !important',
        '& canvas': {

            paddingRight: '0px !important',
            width: '100% !important',
            // borderRight: '1px solid #eee',
            height: '125px !important'
        },
    },
    dropdownWrap: {
        margin: 0,
        '& >div:after, & >div:before': {
            borderBottomWidth: '0px !important',
        }
    },
    selAdvertiser:{
        
        cursor: 'pointer !important',
        backgroundColor:'#EDEDED !important',
    },
    selURN:{
        display:'none', 
        cursor: 'pointer !important',
        backgroundColor:'#EDEDED !important',
    },
    dropdown: {
        background: 'rgb(245, 245, 245)',
        fontSize: 14,
        border: 'none',
        position: 'relative',
        top: '-2px',
        color: '#6a6666',
        paddingLeft: 5,
        minWidth: 150,
        '&:focus': {
            background: 'none',
        },
    },
    awarenessperformanceContainer: {
        margin: '130px 20px 20px 70px',
        '& h2': {
            fontSize: '1.20rem',
            color: '#888',
            textTransform: 'uppercase',
            margin: '25px 0 0 0',
            fontWeight: '600',
        },
    },

});
export default styles;