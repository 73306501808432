import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from '../../../../config/config';
import {setLevelSearchBoxFilter} from '../../../../actions/filter/SearchBoxFilterACtion';
import AutoSuggestService from '../../../../service/AutoSuggestService';
import FilterUtil from '../../../../utils/FilterUtil';

const styles= {
    'overflow-y': 'scroll',
    'outline': 'none',
    'display': 'block'
};
const style2= {
    'overflow-y': 'hidden',
    'outline': 'none',
    'display': 'block',
    'height': '60px'
};

class LevelSearchBox extends React.Component {

    state = {
        level:[],
        isOpen:false,
        styles:{},
        selectedLevel:'',
        noSearchResult:false,
        indexPrefix : ''
    }
getLevelPrefix(){
    if(this.props.level == 'Level 1') {
        return 'level1';
    }
    else if(this.props.level == 'Level 2'){
     return 'level2';
     }
     else if(this.props.level == 'Level 3'){
     return 'level3';
     }
     else
     return 'level1';
}
    handleOnChang=(e)=>{
        console.log("working...", e.target.value);
    }

    setTextBoxSerachValue=(value)=>{
        this.props.setLevelSearchBoxFilter(value);
        this.setState({level:[], styles:{},selectedLevel:value});
    }
    handleLevelSelectClick=(e)=>{
        let value=e.target.text;
        this.setTextBoxSerachValue(value);
    }

    componentWillReceiveProps(newProps){
        if(newProps.searchLevel=='') {
            this.setState({selectedLevel:''});
        }
    }
    handleChangeText=(e)=>{
        this.setState({styles:{}});
        let value=e.target.value;
        if(value=="") this.props.setLevelSearchBoxFilter("");
        this.setState({selectedLevel: value,noSearchResult:false});
        let autoSuggestService = new AutoSuggestService();
        autoSuggestService.value=value;
        autoSuggestService.field=  this.props.level;
        let url=`${config.reports.SEARCH_BOX_FILTER}?text=${value}&field=${FilterUtil.getFilterMap(this.props.level)}.text`;
        let postObj = {};
        postObj.method='POST';
        postObj.url=url;
        postObj.crossDomain=true;
        postObj.withCredentials=true;
        postObj.data={indexPrefix: this.getLevelPrefix()  };
        postObj.headers={'content-type': 'application/json'};
        axios(postObj)
        .then(res=>{
            if(res.hasOwnProperty('data') && Array.isArray(res.data) && res.data.length>0) {
                this.setState({isOpen: true, level: res.data, styles: styles, noSearchResult: false});
            }
            else{
                this.setState({isOpen:true,level:[], styles:style2,noSearchResult:true});
            }
        }).catch(error=>{ console.log("error", error)});
    }

    handleClickOutside=(event)=>{
        if(!this.node.contains(event.target) && this.state.isOpen){
            this.setState({isOpen:false,noSearchResult:false, styles:{}});
        }
    }
    componentWillMount(){
        this.props.setLevelSearchBoxFilter("");
        document.addEventListener("mousedown", this.handleClickOutside,false);
    }
    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside,false);
    }

    keyPress=(e)=>{
        if(e.keyCode == 13 && this.state.level.length==1){
           this.setTextBoxSerachValue(e.target.value);
        }
    }
    render() {
        return (
            <li>
                <div class="dpSearchBig input-field clearable" ref={node=>this.node=node}>
                    <i class="material-icons prefix">search</i>
                    <input type="text" onKeyDown={this.keyPress}  onChange={this.handleChangeText} value={this.state.selectedLevel} placeholder="Search" autoComplete="off" />
                    <span className="search-clear"></span>
                    <div className="searchDropdown" style={this.state.styles}>
                    <span className="arrow-up"></span>
                    <div className="searchDropdownCon">
                        <ul>
                            {
                                this.state.level.map(poNumber=>{
                                    return <li><a style ={{'width':'100%'}} onClick={this.handleLevelSelectClick}>{poNumber}</a></li>
                                }, this)
                            }
                            { this.state.noSearchResult?<ul><li><a>No Result</a></li></ul>:''}
                          
                        </ul>
                    </div>
                    </div>
                </div>
            </li>

         );
    }
}

const mapStateToProps = state => {
    return {
        level:state.levelType.level,
        searchLevel:state.search.searchLevel
    }
 }

const mapDispatchToProps=dispatch=>bindActionCreators({
    setLevelSearchBoxFilter:setLevelSearchBoxFilter
},dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(LevelSearchBox); 