import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import ToastContent from './ToastContent';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    }
});

class CustomizedSnackbars extends React.PureComponent {

    constructor(props){
        super(props);
        this.state={
            open:''
        }
        this.handleClick=this.handleClick.bind(this);
        this.handleClose=this.handleClose.bind(this);
    }

    handleClick() {
        this.setState({open:false});
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open:false});
    }
    componentWillMount(){
      const { open, variant, message } = this.props;
      this.setState({
        open, variant, message
      });
    }

  render(){

    const { classes } = this.props;
    const { open, message,variant } = this.state;
    
  return (
    <>
     {/*  <Button variant="outlined" className={classes.margin} onClick={this.handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={this.handleClose}
      >
        <ToastContent
          onClose={this.handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
      {/* <MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message="This is an error message!"
      />
      <MySnackbarContentWrapper
        variant="warning"
        className={classes.margin}
        message="This is a warning message!"
      />
      <MySnackbarContentWrapper
        variant="info"
        className={classes.margin}
        message="This is an information message!"
      />
      <MySnackbarContentWrapper
        variant="success"
        className={classes.margin}
        message="This is a success message!"
      /> */}
    </>
  );
    }
}

export default withStyles(styles)(CustomizedSnackbars);