"use strict";
import C from '../../actions/constants';
const initialState = {
    defaultFilters: [],
    updateFiterName:''
}
export default function defaultFiltersReducer(state = initialState, action) {
    switch (action.type) {
        case C.SET_DEFAULT_FILTERS_LIST:
            return {
                ...state,
                defaultFilters: action.payload

            }
        case C.ADD_DEFAULT_FILTERS_LIST:
            return {
                ...state,
                defaultFilters: [...state.defaultFilters, action.payload]

            }
        case C.DELETE_DEFAULT_FILTERS_LIST:
            let index = state.defaultFilters.findIndex(f => f.id == action.payload);
            state.defaultFilters.splice(index, 1);
            return {
                ...state,
                defaultFilters: [...state.defaultFilters]

            }
        case C.UPDATE_DEFAULT_FILTERS_LIST:
            let update_name = state.defaultFilters.findIndex(f => f.id == action.payload.id);
            state.defaultFilters.splice(update_name, 1, action.payload);
            return {
                ...state,
                defaultFilters: [...state.defaultFilters]

            }
        case C.SET_UPDATE_FILTER:
            return {
                ...state,
                updateFiterName: action.payload

            }
        default:
            return state;
    }
};