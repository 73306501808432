import React from 'react';
import DataFetchService from '../../../service/DataFetchService';
import CommonUtil from '../../../utils/CommonUtil';
import _ from 'lodash';
import ActivityIndicator from '../../common/ActivityIndicator';

const styles = {
    "margin-left": "0px",
    "margin-top": "28px"
};

class DisplayTopMetrics extends React.PureComponent {
    
    state = {
        metric: 0,
        displayName: '',
        className: '',
        indicating: true
    }
    setMetrics = (metricsObj) => {

        const { metaData } = this.props;
        let metric = 0;
        let displayName = metaData.displayName;
        try {
            if (displayName == 'ARR')
                metric = `${metricsObj['ARR'].toFixed(2)}`;
            if (displayName == 'CTR')
                metric = `${metricsObj.CTR.toFixed(2)}%`;
            if (displayName == 'Average eCPM')
                metric = metricsObj['Average eCPM'].toFixed(2);
            if (displayName == 'Viewablity')
                metric = `${metricsObj['Viewability'].toFixed(2)}%`;
            if (displayName == 'Total Spend' && !_.isNil(metricsObj[0]['Total Spend']))
                metric = `£${CommonUtil.numToMillion(metricsObj[0]['Total Spend'])}`;
        }
        catch (e) { console.log("error at DisplayTopMetrics at line 28", e) };
        this.setState({
            metric,
            className: metaData.chartStyle.className,
            displayName: displayName,
            indicating: false
        });

    }
    setRequestParam = () => {
        const { metaData, filters } = this.props;
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.operationType = metaData.operationType;
        dataFetchService.metricObj = metaData.metrics;
        dataFetchService.filters = filters.filter;
        dataFetchService.rangeQueryData = filters.rangeQueryData;
        dataFetchService.fethData()
            .then(response => {
                if (!_.isNil(response.data)) {
                    if (!_.isNil(response.data.resultAsMap)) this.setMetrics(response.data.resultAsMap);
                    else this.setMetrics(response.data.resultAsList);
                }

            }).catch(error => {
                console.log("Exception occured at DisplayTopMetrics in Benchmarking report ", error);
                this.setState({
                    className: metaData.chartStyle.className,
                    displayName: metaData.displayName
                });
            });
    }

    componentWillMount() {
        this.setRequestParam();
    }

    handleMetricClick = () => {
        let { displayName } = this.state;
        const { history } = this.props.props;
        if (displayName === 'Best CTR Advertiser') {
            history.replace(`/`);
        }
    }

    render() {
        const { className, indicating, displayName, metric } = this.state;
        return (
            <li className={className} onClick={this.handleMetricClick}>
                <dl>
                    {
                        indicating ? <ActivityIndicator
                            style={styles}
                            height="20"
                            width="20"
                            indicating={indicating} /> :
                            <>{displayName === 'Best CTR Advertiser'?
                                <dt className="bestCtr">{displayName}</dt>:
                                <dt>{displayName}</dt>}
                            {metric===0?<dd></dd>:<dd>{metric}</dd>}
                            </>
                    }
                </dl>
            </li>
        )
    }
}

export default DisplayTopMetrics;