"use strict";
import C from '../constants';
export  function setRegion(regionFilter) {
    return function(dispatch){
        dispatch({
            type:C.SET_REGION_FILTER,
            regionFilter
        });
    }    
};

export  function reSetRegion() {
    return function(dispatch){
        dispatch({
            type:C.RESET_REGION_FILTER_DATA
        });
    }    
};
export  function setAllRegion(regionFilter) {
    return function(dispatch){
        dispatch({
            type:C.SET_All_REGION_FILTER,
            regionFilter
        });
    }    
};
export  function removeRegion(payload) {
    return function(dispatch){
        dispatch({
            type:C.REMOVE_REGION,
            payload
        });
    }    
};