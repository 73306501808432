"use strict";
import C from '../constants';
export function setAdvertiser(advertiserFilter) {
    return function (dispatch) {
        dispatch({
            type: C.SET_ADVERTISER_FILTER,
            advertiserFilter
        });
    }
};

export function reSetAdvertiser() {
    return function (dispatch) {
        dispatch({
            type: C.RESET_ADVERTISER_FILTER
        });
    }
};

export function setAutoSuggestAdvertiser(advertiserId, advertiserName) {
    return function (dispatch) {
        dispatch({
            type: C.SET_AUTO_SUGGEST_ADVERTISER_FILTER,
            data: { autoSuggestAdvertiserId: advertiserId, autoSuggestAdvertiserName: advertiserName }
        });
    }
};

export function setTotalBnechMarkAdvertiserCount(totalBenchMarkAdvertiserCount) {
    return function (dispatch) {
        dispatch({
            type: C.TOTAL_BENCHMARK_ADVERTISER_COUNT,
            totalBenchMarkAdvertiserCount
        });
    }
};

export function addSetAdvertiserList(advertiserList) {
    return function (dispatch) {
        dispatch({
            type: C.ADD_SET_ADVERTISER_FILTER_NAME,
            advertiserList
        });
    }
};

export function setAdvertiserList(advertiserList) {
    return function (dispatch) {
        dispatch({
            type: C.SET_ADVERTISER_FILTER_NAME,
            advertiserList
        });
    }
};
export function removeAdvertiser(payload) {
    return function (dispatch) {
        dispatch({
            type: C.REMOVE_ADVERTISER,
            payload
        });
    }
};
export function autoAdvFilterName(advName) {
    return function (dispatch) {
        dispatch({
            type: C.AUTO_ADV_FILTER_NAME,
            advName
        });
    }
};