"use strict"

import C from '../constants';

export  function getMetricsCount(data) {
    return function(dispatch){
        dispatch({
            type:C.TOP_METRICS_COUNT,
            data
        });
    }
};
