import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DataFetchService from '../../service/DataFetchService';
import CommonUtil from '../../utils/CommonUtil';
import DrawChart from './DrawChart';
import config from '../../config/config';
import _, { isEmpty } from 'lodash';

const LineChart = (props) => {
    const advertiserName = useSelector(state => state.advertiser.autoSuggestAdvertiserName);
    const advertiserList = useSelector(state => state.advertiserList);
    const levelSearch = useSelector(state => state.search.levelSearch);
    const advertiserLevels = useSelector(state => state.levelType.advertiserLevels);
    const [chartDataObjj,setChartDataObjj] = useState({});
    

    const formChartRequestData = (splineCharts) => {

        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = splineCharts.indexPrefix;
        dataFetchService.url = '';
        dataFetchService.size = 1000;
        dataFetchService.dimensions = splineCharts.dimensions;
        dataFetchService.metricObj = splineCharts.metrics;
        dataFetchService.operationType = splineCharts.operationType;
        return dataFetchService;

    }
    const getChartData = async () =>{
        let metaData = props.lineChartMetaData;
        const { filters } = props;
        let urlMetricParam = metaData.yaxis.dim.displayName;
        let urlDimParam = metaData.xaxis.dim.displayName;
        let splineCharts = metaData.splineCharts;
        let dataFetchService;
        let chartDataObj = { chartDataPoint: [] };
        let chartDisplayMetrics = [];
        let arrayOfDataPoints = [];
        let maxdata = 0;
        for (let item of splineCharts) {
            let filtersParams = filters.filter;
            if (item.jdSplineConfig.queryType === 'FILTER') {
                let canAdvertiserLineShow = true;
                for (let item of filtersParams) {
                    if (item.field == 'advertiserId') {
                        canAdvertiserLineShow = false;
                    }
                }
                if (canAdvertiserLineShow) {
                    
                    continue;
                }
                if (props.advertiserNameCI == undefined || props.advertiserNameCI == '' ||
                    props.advertiserNameCI == null) {
                    chartDisplayMetrics.push({
                        label: advertiserName,
                        color: item.chartStyle.color
                    });
                } else {
                    chartDisplayMetrics.push({
                        label: props.advertiserNameCI,
                        color: item.chartStyle.color
                    });
                }

            } else {
                filtersParams = filtersParams.filter(item => {
                    return item.field != 'advertiserId';
                });

                if (advertiserLevels.hasOwnProperty('level1') && advertiserLevels.hasOwnProperty('level2') && advertiserLevels.hasOwnProperty('level3')) {
                    if (advertiserLevels.level1 !== undefined && advertiserLevels.level2 !== undefined && advertiserLevels.level3 !== undefined
                        && levelSearch === '') {
                        filtersParams.push({ 'field': 'level1', values: [advertiserLevels.level1] });
                        filtersParams.push({ 'field': 'level2', values: [advertiserLevels.level2] });
                        filtersParams.push({ 'field': 'level3', values: [advertiserLevels.level3] });
                    }
                }
                chartDisplayMetrics.push({
                    label: item.displayName,
                    color: item.chartStyle.color
                });
            }
            dataFetchService = formChartRequestData(item);
            dataFetchService.filters = filtersParams;
            dataFetchService.rangeQueryData = filters.rangeQueryData;
            dataFetchService.url = `${config.reports.BENCHMARKING}?metric=${urlMetricParam}&type=${item.jdSplineConfig.queryType}&dim=${urlDimParam}`;
            let response = await dataFetchService.fetchDataSynch();
            let data = response.data;
            let flag = true;

            if (data.maxFieldName !== undefined) {
                _.forEach(advertiserList, (k, v) => {
                    if (k == data.maxFieldName) {
                        _.forEach(chartDisplayMetrics, (item) => {
                            if (item.label === "Best CTR" || item.label.includes("MAX")) {
                                if (v !== "" && v !== null && v !== undefined) {
                                    if (item.label === "Best CTR") {
                                        localStorage.setItem("advertiserName", v);
                                        localStorage.setItem("advertiserId", k);
                                    }
                                    item.label = v.length > 0 ? item.label + "\n( " + v + " )" : item.label;
                                }
                                flag = false;
                            }
                        })
                    }
                });

                if (!isEmpty(data.maxFieldName) && flag) {
                    let getMiscReport = new DataFetchService();
                    getMiscReport.indexPrefix = "advertisers";
                    getMiscReport.metricObj = [];
                    getMiscReport.filters = [
                        { field: "id", values: [ data.maxFieldName.toString() ] }
                    ];
                    getMiscReport.operationType = "COMPOSITE_GROUP_BY";
                    getMiscReport.rangeQueryData = [];
                    getMiscReport.dimensions = [
                        {
                            "name": "name",
                            "displayName": "Advertiser Name",
                            "op": "SELECT",
                            "isUIField": true
                        }
                    ];
                    let advertiserId = data.maxFieldName.toString();
                    getMiscReport.fethData()
                        .then(response => {
                            if (!_.isNil(response.data)) {
                                if (_.isNil(response.data.resultAsMap)) {
                                    _.forEach(chartDisplayMetrics, (item) => {
                                        if (item.label === "Best CTR" || item.label.includes("MAX")) {
                                            if (response.data.resultAsList[0]["Advertiser Name"] !== "" &&
                                                response.data.resultAsList[0]["Advertiser Name"] !== null &&
                                                response.data.resultAsList[0]["Advertiser Name"] !== undefined) {
                                                if (item.label === "Best CTR") {
                                                    localStorage.setItem("advertiserName", response.data.resultAsList[0]["Advertiser Name"]);
                                                    localStorage.setItem("advertiserId", advertiserId);
                                                }
                                                item.label = response.data.resultAsList[0]["Advertiser Name"].length > 0
                                                    ? item.label + " \n( " + response.data.resultAsList[0]["Advertiser Name"] + " )" : item.label;
                                            }
                                            flag = true;
                                        }
                                    })
                                }
                            }
                        }).catch(error => {
                            console.log("Exception occured at advertiser name ", error);
                        });
                }
            }

            if (data.maxData !== undefined) { data = data.maxData; }

            if (data == '') continue;

            if (item.jdSplineConfig.queryType != 'MEDIAN') {
                arrayOfDataPoints.push(data)
            } else {
                maxdata = data.toFixed(2);
            }

        }

        //Duplicating missing labels
        var defaultObj = arrayOfDataPoints.reduce((m, o) => (Object.keys(o).forEach(key => m[key] = 'noValue'), m), {});
        arrayOfDataPoints = arrayOfDataPoints.map(e => Object.assign({}, defaultObj, e));

        //sorting based on date
        arrayOfDataPoints = arrayOfDataPoints.map(e => {
            var ordered = {};
            Object.keys(e).sort().forEach(function (key) { ordered[key] = e[key]; });
            return ordered;
        });

        //adding empty values as null and converting data arrays and labels
        arrayOfDataPoints.map(obj => {
            let chartLabels = [];
            let chartData = [];
            for (let key in obj) {
                chartLabels.push(CommonUtil.parseChartLabelDate(key));
                chartData.push(obj[key] != 'noValue' ? obj[key].toFixed(2) : null);
            }
            chartDataObj.chartDataPoint.push(chartData);
            if (chartDataObj.chartLabel == undefined) chartDataObj.chartLabel = chartLabels;
        })

        //benchmark converted to array based on labels
        let maxChartData = []
        for (let i = 1; i <= chartDataObj.chartLabel.length; i++) {
            maxChartData.push(maxdata);
        }
        chartDataObj.chartDataPoint.push(maxChartData);

        chartDataObj.chartDisplayMetrics = chartDisplayMetrics;
        chartDataObj.displayName = urlMetricParam;

       
        setChartDataObjj(chartDataObj)

    }

    useEffect(() => {
        getChartData()
    },[])
  return (
    <div> {Object.keys(chartDataObjj).length > 0 ? <DrawChart {...chartDataObjj} /> : null}</div>
  )
}

export default LineChart