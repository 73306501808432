import React from 'react';


/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

export default ()=><div class="col s12" align="center" style={{'margin-top':"275px"}} >
<h3>404 - PAGE NOT FOUND</h3></div>
        