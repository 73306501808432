"use strict";
import C from '../../actions/constants';
const initialState = {
    advertiser:{},
    region:{},
    isTopFilterDataAvail:false
  }
  export default function topFilterMetaDataReducer(state=initialState, action) {

    switch(action.type) {
      case C.SET_TOP_FILTER_METADATA:
        return {
          ...state,
          advertiser:action.payload['Advertiser'],
          region:action.payload['Region'],
          isTopFilterDataAvail: true
      };
      default:
        return state;
    }
  };
  