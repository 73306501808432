
import config from '../config/config';
import axios from 'axios';

class ReportingConfigService {

    fetchMetaDataConfig=()=>{
        return axios.get(`${this.getUrl()}/${this.id}`);
    }

    getUrl=()=>{
        return config.reports.METADATA;
    }

}


export default ReportingConfigService;