import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDateTypeFilter } from '../../actions/filter/DateTypeFilterAction';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

class DateTypeFilter extends Component{

   constructor(props){
      super(props);
      this.state={
         isOpen:false,
         filterData:['Start Date','End Date','Run Date']
      };
      this.handleDateTypeFilterClick=this.handleDateTypeFilterClick.bind(this);
      this.setWrapperRef=this.setWrapperRef.bind(this);
      this.handleClickOutside=this.handleClickOutside.bind(this);
   }

   setWrapperRef(node) {
      this.wrapperRef = node;
   }
   handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.setState({ isOpen: false })
      }
   }
   componentWillMount(){
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount(){
      document.removeEventListener("mousedown", this.handleClickOutside);
  }
   
   handleDateTypeFilterClick = (e) => {

      var selectedFilterType = e.target.textContent;
         selectedFilterType=selectedFilterType.split('arrow_drop_down')[0];
      if(selectedFilterType.trim() == '') {
         this.setState({
            isOpen:!this.state.isOpen
         });
         return;
      }
      this.setState({
         isOpen:!this.state.isOpen
      });

      this.props.setDateTypeFilter(selectedFilterType)
   }

    render(){

        return(
          
            <li>
               <span class="taskstatus">
               <span ref={this.setWrapperRef} class="order-by dropdown " onClick={this.handleDateTypeFilterClick}>
                  <a  class="hover-link"><span class="jdicon jdicon-filter"></span>{this.props.dateTypeFilter}<i class="material-icons"> arrow_drop_down</i></a>
                  {
                     this.state.isOpen?<div class="dropdown-contents stay ">
                     <span class="arrow-up"></span>
                     <div class="scrollF" tabindex="12" style={{"overflow-y": "hidden", outline: "none"}}>
                        <ul class="dropdown-menu auto dropdown-select " id="media-sort" style={{"width":"0px"}}>
                         {
                            this.state.filterData.map(filter=>{
                              return <li class="subtaksRFType clickable"><a>{filter}</a></li>
                            })
                         }
                        </ul>
                     </div>
                  
                     <div id="filterTriggerScroll" class="nicescroll-rails nicescroll-rails-vr" style={{width: "3px", "z-index": 2, background: "rgb(221, 221, 221)", cursor: "default", position: "absolute", top: "59px", left: "186px", height: "66px", display: "none"}}>
                        <div class="nicescroll-cursors" style={{position: "relative", top: "0px", float: "right", width: "3px", height: "0px", "background-color": "rgb(0, 0, 0); border: 0px solid rgba(0, 0, 0, 0.8)", "background-clip": "padding-box", "border-radius": "8px"}}></div>
                     </div>
                  </div>:""}
                  
               </span>
               </span>    
            </li>        
        
        )
    }
}

const mapStateToProps = state =>{
   return {
      dateTypeFilter:state.dateType.dateFilterType,


   }
}

const mapDispatchToProps = dispatch => bindActionCreators({
   setDateTypeFilter:setDateTypeFilter
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (DateTypeFilter);