import React from 'react';
import { Line,Chart } from 'react-chartjs-2';
import CommonUtil from '../../utils/CommonUtil';

Chart.defaults.scale.gridLines.display = true;

const DrawChart=({chartDataPoint,chartLabel,chartDisplayMetrics,displayName})=>{
    const getChartData=()=>{
      let datasets=[]
      let obj={};
      for(var index in chartDataPoint) {
        obj={}
        obj.data=chartDataPoint[index];
        if (chartDisplayMetrics[index].label)
            obj.label=chartDisplayMetrics[index].label;
        obj.lineTension=0;
        obj.borderWidth=2;
        obj.fill=false;
        obj.borderColor=chartDisplayMetrics[index].color;
        obj.backgroundColor=chartDisplayMetrics[index].color;
        datasets.push(obj);
      }
      return {
        labels: chartLabel,
        datasets: datasets
      };
    }
    
    const getChartOption=()=>{

      var chartOptions = {}
      chartOptions.spanGaps=true;
      chartOptions.maintainAspectRatio = false;
      chartOptions.legend = {
          display: true,
          position: 'bottom',
          align:'center',
          fullWidth:true,
          labels: {
              // boxWidth: 40,
              fontColor: 'black',
              usePointStyle:true,
              padding:30,
          }
      };
      
      if (displayName == 'Total Spend') {
      
          chartOptions.scales = {
              xAxes: [{
                  gridLines: {
                      display: false
                  }
              }],
              yAxes: [{
                  ticks: {
                      callback: function(label, index, labels) {
                          return CommonUtil.numToMillion(label)
                      }
                  },
                  gridLines: {
                      drawBorder: false
                  }
              }]
          }
          chartOptions.tooltips = {
              callbacks: {
                  label: function(tooltipItem, data) {
                      return `${data.datasets[tooltipItem.datasetIndex].label}:${CommonUtil.numToMillion(tooltipItem.yLabel)}`;
                  }
              }
          }
      } else {
          chartOptions.scales = {
              xAxes: [{
                  gridLines: {
                      display: false
                  }
              }],
              yAxes: [{
                  gridLines: {
                      drawBorder: false
                  }
              }]
          }
      }
      return chartOptions;
    } 

    const renderMetricsNames=()=>{

      let display=[];
      let classes=chartDisplayMetrics.length==2?['dot3','dot1']:['dot2','dot3','dot1'];
      for(let index in chartDisplayMetrics){
        display.push(<li><span className={classes[index]}></span>{chartDisplayMetrics[index].label}</li>);
      }
      return display;
    }


    return (  
      
      <div class="boxContainer">
        <div class="headingtagWrap">
          <h2 class="left">{displayName}</h2>
        </div>
          <div class="graphDetails">
            <div class="graph">
              <Line  
              data={getChartData()}
              height={250}
              width={150}
              options={getChartOption()}
              />
            </div>
            <div class="graphDetails">
              <ul>
                {renderMetricsNames()}
              </ul>
            </div>
        </div>
      </div>
    );
}

export default DrawChart;