import React, { Component } from 'react';
import DataFetchService from '../../service/DataFetchService';
import FilterUtil from '../../utils/FilterUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { setSalesRep } from '../../actions/filter/SalesRepFilterAction';
import config from '../../config/config';
class SalesRepFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: '',
            salseRepData: [],
            isClearAllFlag: false,
            salseRep: [],
            selectedSalseRep: []
        };
        this.handleSalseRepShowHideClick = this.handleSalseRepShowHideClick.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleSalseRepFilterChange = this.handleSalseRepFilterChange.bind(this);
        this.getSalseRepFromDfs = this.getSalseRepFromDfs.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        // this.props.resetSalseRep();
        this.getSalseRepFromDfs();
    }

    handleClickOutside = (e) => {
        if (!this.node.contains(e.target)) {
            this.setState({ isOpen: '' });
        }
    }
    getSalseRepFromDfs = () => {

        var advertiserMetaData = {
            "reportType": "JD_METRICS",
            "indexPrefix": config.index.LINEITEM_SERVEDATE_METRICS,
            "offset": 0,
            "queryType": "AGG",
            "metrics": [{
                "name": "salesRep.keyword",
                "displayName": "salseRep",
                "op": "SELECT"
            }]
        };
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = advertiserMetaData.indexPrefix;
        dataFetchService.rangeQueryData = [];
        dataFetchService.dimensions = null;
        dataFetchService.metricObj = advertiserMetaData.metrics;
        dataFetchService.operationType = advertiserMetaData.queryType;
        dataFetchService.filters = [];
        dataFetchService.fethData()
            .then(response => {
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('resultAsMap') && response.data.resultAsMap != null) {
                    let salseRep = response.data.resultAsMap.salseRep;
                    // salseRep = this.handelNullValues(salseRep);
                    var salseRepList = [];
                    for (var index in salseRep) {
                        salseRepList.push({
                            name: salseRep[index],
                            isSelected: false
                        });
                    }
                    salseRepList.sort(function (a, b) {
                        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                        if (nameA < nameB) //sort string ascending
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0 //default return value (no sorting)
                    })
                    this.setState({
                        salseRepData: salseRepList,
                        salseRep: salseRepList
                    });
                }
            }).catch(error => { console.log("Exception:", error); });
    }
    handelNullValues = (arr) => {
        arr = arr.filter(function (n) {
            return (n !== undefined && n !== null && n !== "null" && n !== false && n !== 0 && n !== "" && isNaN() != NaN);
        });
        return arr;
    }
    handleSalseRepFilterChange = (e) => {
        this.setState({
            salseRep: FilterUtil.filterByName(
                this.state.salseRepData,
                e.target.value
            )
        });
    }

    handleSalseRepShowHideClick = (e) => {
        let className = e.target.className;
        if (className != '' && className != 'active'
            && className.indexOf("clickable") == -1
            && className.indexOf("ps__thumb-x") == -1
            && className.indexOf("ps__thumb-y") == -1
            && className.indexOf("ps__rail-x") == -1
            && className.indexOf("ps__rail-y") == -1
            && className.indexOf("dropdown-contents stay active") == -1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-x") == -1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-y") == -1
            && className.indexOf("clearable") == -1
            && className.indexOf("dropdown-select") == -1) {
            this.setState({
                isOpen: this.state.isOpen == '' ? 'active' : ''
            });
        }
    }

    handleClearAllClick = (e) => {
        let salseRep = this.state.salseRep;
        let selectedSalseRep = [];
        e.target.checked = true;
        for (let i in salseRep) {
            salseRep[i].isSelected = false;
        }
        this.setState({ selectedSalseRep, salseRep, isClearAllFlag: false });
    }

    handleCheckBoxClick = (e) => {
        let salseRep = this.state.salseRep;
        let selectedSalseRep = this.state.selectedSalseRep;
        let value = e.target.value;
        for (let i in salseRep) {
            if (salseRep[i].name == value) {
                let index = selectedSalseRep.indexOf(value);
                if (index == -1) selectedSalseRep.push(value)
                else selectedSalseRep.splice(index, 1);
                salseRep[i].isSelected = !salseRep[i].isSelected;
                break;
            }
        }
        this.state.selectedSalseRep = selectedSalseRep;
        selectedSalseRep.length > 0 ? this.setState({ isClearAllFlag: true }) : this.setState({ isClearAllFlag: false });
        this.setState({ salseRep });
        this.props.setSalesRep(selectedSalseRep);
    }

    componentWillMount = () => {
        document.addEventListener("mousedown", this.handleClickOutside, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside, false);
    }
    render() {
        return (

            <span ref={node => this.node = node}>
                <span class="taskstatus" onClick={this.handleSalseRepShowHideClick}>
                    <span class="order-by dropdown plainPop filterTrigger">
                        <a class="hover-link"><span class="jdicon jdicon-filter"></span>Sales Rep<i class="material-icons">arrow_drop_down</i></a>
                        <div class={`dropdown-contents stay ${this.state.isOpen}`}>
                            <div class="filter-options">
                                <div class="dpSearch input-field clearable">
                                    <i class="material-icons prefix">search</i>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        id="assetTypeSearch"
                                        style={{ "border-color": "rgba(0, 0, 0, 0.08)" }}
                                        onChange={this.handleSalseRepFilterChange}
                                    />
                                </div>
                            </div>
                            <ul class="dropdown-menu auto dropdown-select " id="media-sort">
                                <PerfectScrollbar>
                                    <div className={"scroller"}>
                                        {
                                            <li className="subtaksRFStatus clickable">
                                                {this.state.isClearAllFlag && <a href="javascript:void(0);" onClick={this.handleClearAllClick} class='clearAll'><u>Clear All</u></a>}
                                                {!this.state.isClearAllFlag && <h8 className="clearAllDisable">Clear All</h8>}
                                            </li>
                                        }
                                        {
                                            this.state.salseRep.map(item => {
                                                item.isSelected =false;
                                                if(this.props.selectedSalesRep.indexOf(item.name) !== -1)  item.isSelected =true;
                                                return <li class="subtaksRFStatus clickable">
                                                    <label onChange={this.handleCheckBoxClick} class="checkboxWrap commonCheck">
                                                        <input
                                                            type="checkbox"
                                                            class=""
                                                            value={item.name}
                                                            checked={item.isSelected}
                                                        />
                                                        <span>{item.name}</span>
                                                    </label>
                                                </li>
                                            }, this)
                                        }
                                    </div>
                                </PerfectScrollbar>
                            </ul>
                        </div>
                    </span>
                </span>
            </span>
        )
    }
}

const mapStateToProps = state => {
    return {
       selectedSalesRep:state.salesRepFilterReducer.selectedSalesRep
    }
 }

const mapDispatchToProps = dispatch => bindActionCreators({
    setSalesRep:setSalesRep
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesRepFilter);