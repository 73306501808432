import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataFetchService from '../../service/DataFetchService';
import ActivityIndicator from '../common/ActivityIndicator';
import FilterUtil from '../../utils/FilterUtil';
import CommonUtil from '../../utils/CommonUtil';
import Table from './Table';
import Result from '../common/Result';
import { bindActionCreators } from "redux";
import _ from 'lodash';
import { getMetricsCount } from "../../actions/campaign/topMetricsCountAction";
const dfpLogo = `${process.env.PUBLIC_URL}/assets/images/dfplogo.png`;
const dbmLogo = `${process.env.PUBLIC_URL}/assets/images/dbmlogo.png`;
const facebookLogo = `${process.env.PUBLIC_URL}/assets/images/facebook.jpg`;
const histrolicLogo = `${process.env.PUBLIC_URL}/assets/images/tickgreen.png`;
//import PropTypes from 'prop-types';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const styles = {
    "margin-left": "50%",
    'position': 'relative',
    'left': '-20px',
    // 'margin-top':'15px'
};

class CampaignListing extends Component {


    constructor(props) {
        super(props);
        const property = {
            'Tracking ID': 'trackingId',
            'Region': 'region',
            'Advertiser': 'advertiser',
            'Ad Server': 'adServer',
            'Impressions': 'impression',
            'Clicks': 'clicks',
            'CTR': 'ctr',
            'Total Amount': 'totalAmountSpent',
            'Start Date': 'startDate',
            'End Date': 'endDate'
        };
        let sortyByProerties = {};
        for (let key in property) {
            sortyByProerties[key] = false;
        }
        this.state = {
            openToast: true,
            campaignList: [],
            totalRecordCount: 0,
            tableDimensions: [],
            indicator: true,
            dataFetchService: new DataFetchService(),
            filters: [],
            adserverFilterCount: this.props.adserverFilterCount,
            property,
            sortyByProerties,
            size: 20,
            lastRowPoNumberForcompositeFilter: '',
            rangeQueryData: [],
            bucketSelectorConfig: {
                "name": "adServer",
                "loadFactor": 10,
                "bucketPath":
                    { "count": "TERMS_adServer._bucket_count" },
                "scriptExpression": `params.count == ${this.props.adserverFilterCount}`
            },
            isLoading: false,
            isScroll: false,
            advertiserId: null,
            holisticAdv: false,
            lastUpdatedDate: null,
        };
        this.renderData = this.renderData.bind(this);
        this.setlastRowPoNumberForcompositeFilter = this.setlastRowPoNumberForcompositeFilter.bind(this);
        this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
        this.setServiceObj = this.setServiceObj.bind(this);
        this.setTableData = this.setTableData.bind(this);
        this.sortByProperty = this.sortByProperty.bind(this);
        this.sortAscendingOrder = this.sortAscendingOrder.bind(this);
        this.sortDescendingOrder = this.sortDescendingOrder.bind(this);
        this.iterateCampaignListToValidate = this.iterateCampaignListToValidate.bind(this);
        this.renderResultMessage = this.renderResultMessage.bind(this);
    }

    setlastRowPoNumberForcompositeFilter = () => {

        let index = this.state.campaignList.length;
        if (index > 0) {
            let value = this.state.campaignList[index - 1]['URN'];
            this.state.lastRowPoNumberForcompositeFilter = value;
        }

    }
    sortAscendingOrder = (item1, item2) => {
        if (item1 < item2)
            return -1;
        if (item1 > item2)
            return 1;
        return 0;
    }
    sortDescendingOrder = (item1, item2) => {
        if (item1 > item2)
            return -1;
        if (item1 > item2)
            return 1;
        return 0;
    }

    sortByProperty = (property) => {
        let data = this.state.campaignList;
        let sortBy = this.state.sortyByProerties[property];
        if (property != undefined && !(property == 'startDate' || property == 'endDate')) {
            data.sort((a, b) => {
                if (CommonUtil.isNumber(a[property])) {
                    var item1 = a[property], item2 = b[property];
                }
                else {
                    try {
                        var item1 = a[property].toLowerCase(), item2 = b[property].toLowerCase();
                    }
                    catch (e) {
                        var item1 = a[property], item2 = b[property];
                    }
                }
                if (!sortBy)
                    return this.sortAscendingOrder(item1, item2);
                else
                    return this.sortDescendingOrder(item1, item2);
            });
        }
        else {
            data.sort((a, b) => {
                var item1 = new Date(a[property]).getTime(), item2 = new Date(b[property]).getTime();
                if (!sortBy)
                    return this.sortAscendingOrder(item1, item2);
                else
                    return this.sortDescendingOrder(item1, item2);
            });
        }
        this.state.sortyByProerties[property] = !sortBy;
        this.setState({ campaignList: data });
    }

    handleSortClick = (e) => {
        let value = e.target.text;
        this.sortByProperty(this.state.property[value]);
    }
    setTableData = (data) => {
        this.setState(data);
    }
    iterateCampaignListToValidate = (data) => {
        try {
            let obj = {};
            obj.URN = 'NA';
            obj.campaignName = 'NA';
            obj.advertiser = 'NA';
            obj.region = 'NA';
            obj.endDate = "NA";
            obj.amount = 'NA';
            if (data['Ad Server'] !== 'DBM') obj.totalAmountSpent = 0.00;
            obj.clicks = 0;
            obj.responsePer10KImpressions = 0;
            obj.impression = 0;
            try {
                obj.dateTypeFilter = this.state.rangeQueryData[0].field;
            }
            catch (e) {
                obj.dateTypeFilter = 'Start Date';
            }
            obj.ctr = '0';
            obj.orderId = 0;

            if (data['URN']) obj.URN = data['URN'];
            if (data['Campaign Name']) obj.campaignName = data['Campaign Name'];
            if (data['orderId']) obj.orderId = data['orderId'];
            if (data['Region']) obj.region = data['Region'];
            if (data['Advertiser'] != null) {
                obj.advertiser = data['Advertiser'];
                obj.advertiserId = data['Advertiser ID'];
            }
            if (data['Localiq  Facebook  Audience Ext.']) {
                data['Ad Server'] = data['Localiq  Facebook  Audience Ext.']
            }
            if (data['Ad Server']) {
                if (Array.isArray(data['Ad Server']) || Array.isArray(data['Localiq Facebook Audience Ext.'])) {
                    obj.adServer = CommonUtil.changeAdServerDisplayName(data['Ad Server'].join(','));
                } else obj.adServer = CommonUtil.changeAdServerDisplayName(data['Ad Server']);
                obj.adServer = obj.adServer.replace('FB', 'FACEBOOK');
                obj.adServer = obj.adServer.replace('DBM', 'Audience Extension');
                if ((data['Ad Server'].indexOf("GAM")) !== -1) {
                    obj.adServerLogo = <img width='25%' src={dfpLogo} alt="dfp logo" />;
                    if (this.state.holisticAdv) {
                        obj.adServerLogo = <img width='15%' src={dfpLogo} alt="dfp logo" />;
                    }
                    obj.histrolicLogo = [<img class="histrolicLogo" width='10%' src={histrolicLogo} alt="histrolic logo" />];
                }
                if ((data['Ad Server'].indexOf("DBM")) !== -1) {
                    obj.adServerLogo = <img width='25%' src={dbmLogo} alt="dbm logo" />;
                    if (this.state.holisticAdv) {
                        obj.adServerLogo = <img width='15%' src={dbmLogo} alt="dbm logo" />;
                    }
                    obj.histrolicLogo = <img width='10%' src={histrolicLogo} alt="histrolic logo" />;
                }
                if ((data['Ad Server'].indexOf("FB")) !== -1) {
                    obj.adServerLogo = <img width='25%' src={facebookLogo} alt="facebook logo" />;
                    if (this.state.holisticAdv) {
                        obj.adServerLogo = <img width='15%' src={facebookLogo} alt="facebook logo" />;
                    }
                    obj.histrolicLogo = <img width='10%' src={histrolicLogo} alt="histrolic logo" />;

                }
                if (typeof (data['Ad Server']) !== 'string' || typeof (data['Ad Server']) !== 'String') {
                    if ((data['Ad Server'].indexOf("GAM") !== -1) && (data['Ad Server'].indexOf("DBM") !== -1)) {
                        obj.adServerLogo = [<img width='25%' src={dfpLogo} alt="dfp logo" />, <img width='25%' src={dbmLogo} alt="dbm logo" />];
                        if (this.state.holisticAdv) {
                            obj.adServerLogo = [<img width='15%' src={dfpLogo} alt="dfp logo" />, <img width='15%' src={dbmLogo} alt="dbm logo" />];
                        }
                        obj.histrolicLogo = [<img width='10%' src={histrolicLogo} alt="histrolic logo" />, <img width='10%' src={histrolicLogo} alt="histrolic logo" />];

                    }
                    if ((data['Ad Server'].indexOf("GAM") !== -1) && (data['Ad Server'].indexOf("FB") !== -1)) {
                        obj.adServerLogo = [<img width='25%' src={dfpLogo} alt="dfp logo" />, <img width='25%' src={facebookLogo} alt="facebook logo" />];
                        if (this.state.holisticAdv) {
                            obj.adServerLogo = [<img width='15%' src={dfpLogo} alt="dfp logo" />, <img width='15%' src={facebookLogo} alt="facebook logo" />];
                        }
                        obj.histrolicLogo = [<img width='10%' src={histrolicLogo} alt="histrolic logo" />, <img width='10%' src={histrolicLogo} alt="histrolic logo" />];
                    }
                    if ((data['Ad Server'].indexOf("FB") !== -1) && (data['Ad Server'].indexOf("DBM") !== -1)) {
                        obj.adServerLogo = [<img width='25%' src={facebookLogo} alt="facebook logo" />, <img width='25%' src={dbmLogo} alt="dbm logo" />];
                        if (this.state.holisticAdv) {
                            obj.adServerLogo = [<img width='15%' src={facebookLogo} alt="facebook logo" />, <img width='15%' src={dbmLogo} alt="dbm logo" />];
                        }
                        obj.histrolicLogo = [<img width='10%' src={histrolicLogo} alt="histrolic logo" />, <img width='10%' src={histrolicLogo} alt="histrolic logo" />];
                    }
                    if ((data['Ad Server'].indexOf("GAM") !== -1) && (data['Ad Server'].indexOf("FB") !== -1) && (data['Ad Server'].indexOf("DBM") !== -1)) {
                        obj.adServerLogo = [<img width='25%' src={dfpLogo} alt="dfp logo" />, <img width='25%' src={dbmLogo} alt="dbm logo" />, <img width='25%' src={facebookLogo} alt="facebook logo" />];
                        if (this.state.holisticAdv) {
                            obj.adServerLogo = [<img width='15%' src={dfpLogo} alt="dfp logo" />, <img width='15%' src={dbmLogo} alt="dbm logo" />, <img width='15%' src={facebookLogo} alt="facebook logo" />];
                        }
                        obj.histrolicLogo = [<img width='10%' src={histrolicLogo} alt="histrolic logo" />, <img width='10%' src={histrolicLogo} alt="histrolic logo" />, <img width='10%' src={histrolicLogo} alt="histrolic logo" />];

                    }
                }
            }
            if (data['level 3'] && data['level 3'] != null) {
                obj.level3 = data['level 3'];
            }
            if (data['Start Date'] && data['Start Date'] != null) {
                if (data['Start Date'] !== null && data['Start Date'] !== undefined && typeof (data['Start Date']) !== 'number' && typeof (data['Start Date']) !== 'string') data['Start Date'] = CommonUtil.findSmallest(data['Start Date']);
                if (!CommonUtil.isNumber(data['Start Date'])) {
                    if (Array.isArray(data['Start Date']))
                        obj.startDate = CommonUtil.getDateWithFormat(data['Start Date'][0], 1);
                    else
                        obj.startDate = CommonUtil.getDateWithFormat(data['Start Date'], 1);
                }
                else
                    obj.startDate = CommonUtil.getDateFromTimeStamp(data['Start Date'], 1);
            }
            if (data['End Date'] && data['End Date'] != null) {
                if (data['End Date'] !== null && data['End Date'] !== undefined && typeof (data['End Date']) !== 'number' && typeof (data['End Date']) !== 'string') data['End Date'] = CommonUtil.findLargest(data['End Date']);
                if (!CommonUtil.isNumber(data['End Date'])) {
                    if (Array.isArray(data['End Date']))
                        obj.endDate = CommonUtil.getDateWithFormat(data['End Date'][0], 0);
                    else
                        obj.endDate = CommonUtil.getDateWithFormat(data['End Date'], 0);
                }
                else {
                    obj.endDate = CommonUtil.getDateFromTimeStamp(data['End Date'], 0);
                }
            } else if (data['End Date'] == null) {
                obj.endDate = 'Unlimited';
            }
            if (data['Clicks']) obj.clicks = CommonUtil.commaSeparatedNumber(data['Clicks']);
            if (data['Response per 10K Impressions']) obj.responsePer10KImpressions = (data['Response per 10K Impressions']);
            if (data['ARR']) obj.responsePer10KImpressions = (data['ARR']);
            if (data['Impressions']) obj.impression = CommonUtil.commaSeparatedNumber(data['Impressions']);
            if (data['CTR'] && data['CTR'] != null && data['CTR'] != 'NaN') obj.ctr = `${data['CTR'].toFixed(2)}%`;
            if (data['Total Amount']) obj.totalAmountSpent = `£${data['Total Amount'].toFixed(2)}`;
            if (obj.totalAmountSpent === 0) obj.totalAmountSpent = `£${obj.totalAmountSpent.toFixed(2)}`;
            return obj;
        } catch (e) {
            console.log('Exception occured while cleaning campiagn list', e);
        }

    }
    setServiceObj = (onLoadMoreFlag = false) => {

        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = this.props.metaData.indexPrefix;
        let tableDimensions = [];
        let metricsMetaData = this.props.metaData.metrics;
        let metaDataDimensions = this.props.metaData.dimensions;
        for (let index in metaDataDimensions) {
            if (metaDataDimensions[index].isUIField)
                tableDimensions.push(metaDataDimensions[index].displayName);
        }
        for (let i in metricsMetaData) {
            if (metricsMetaData[i].isUIField)
                tableDimensions.push(metricsMetaData[i].displayName);
        }
        //tableDimensions.push('Total Amount');
        if (onLoadMoreFlag && this.state.lastRowPoNumberForcompositeFilter != '') {
            dataFetchService.compositeFilter = { 'poNumber': this.state.lastRowPoNumberForcompositeFilter };
        }
        this.state.tableDimensions = tableDimensions;
        if (window.innerWidth > 1200 && window.innerHeight > 1100) this.state.size = 50;
        dataFetchService.size = this.state.size;
        dataFetchService.isTotalCount = true;
        dataFetchService.rangeQueryData = this.state.rangeQueryData;
        dataFetchService.dimensions = metaDataDimensions;
        dataFetchService.metricObj = metricsMetaData;
        if (this.state.adserverFilterCount >= 1) dataFetchService.bucketSelectorConfig = this.state.bucketSelectorConfig;
        dataFetchService.operationType = this.props.metaData.operationType;
        dataFetchService.filters = this.state.filters;
        this.state.dataFetchService = dataFetchService;
    }

    handleLoadMoreClick = () => {
        const { indicator, totalRecordCount, campaignList } = this.state;
        if (!indicator && totalRecordCount != campaignList.length) {
            this.setlastRowPoNumberForcompositeFilter();
            this.setState({ indicator: true });
            this.setServiceObj(true);
            this.renderData(true);
        }
        this.state.isScroll = true;
    }

    renderData = (flag = false) => {
        const { dataFetchService, tableDimensions } = this.state;
        dataFetchService.fethData()
            .then(response => {
                let campaignList = response.data.resultAsList;

                if (this.props.adServerFilter.length > 1) {
                    let impressionsInternal = 0;
                    let clicksInternal = 0;
                    _.forEach(campaignList, (item) => {
                        impressionsInternal += item.Impressions;
                        clicksInternal += item.Clicks;
                    })
                    if (this.props.topMetricsCount && this.state.isScroll) {
                        let totalMetricsCountObj = {
                            campaignCount: this.props.topMetricsCount.campaignCount + campaignList.length,
                            impression: this.props.topMetricsCount.impression + impressionsInternal,
                            clicks: this.props.topMetricsCount.clicks + clicksInternal,
                            ctr: this.calculateCTR((this.props.topMetricsCount.impression + impressionsInternal),
                                (this.props.topMetricsCount.clicks + clicksInternal))
                        }
                        this.props.getMetricsCount(totalMetricsCountObj);
                    } else {
                        this.props.getMetricsCount({
                            campaignCount: campaignList.length,
                            impression: impressionsInternal,
                            clicks: clicksInternal,
                            ctr: this.calculateCTR(impressionsInternal, clicksInternal)
                        });
                    }
                }
                if (campaignList.length == 0) {
                    if (flag) this.setState({ isLoading: true, indicator: false });
                    else this.setState({ indicator: false });
                    return;
                }
                let campData = [];

                for (let i in campaignList) {
                    //don't consider order which has poNumber blank
                    //if(campaignList[i]['Tracking ID']==='')continue;
                    campData.push(this.iterateCampaignListToValidate(campaignList[i]));
                };
                var obj = {
                    campaignList: campData,
                    tableDimensions: tableDimensions,
                    totalRecordCount: response.data.totalRecordCount,
                    indicator: false,
                    isLoading: false
                };
                if (flag) {
                    obj = {
                        campaignList: this.state.campaignList.concat(campData),
                        indicator: false,
                        isLoading: false
                    };
                }

                this.setTableData(obj);
            }).catch(error => {
                this.setTableData({ tableDimensions: tableDimensions, indicator: false });
            });
    }

    calculateCTR = (impressions, clicks) => {
        if (impressions == 0 || clicks == 0) return 0;
        return ((clicks / impressions) * 100).toFixed(2)
    }

    componentDidMount() {
        this.refs.iScroll.addEventListener("scroll", () => {
            if (Math.ceil(this.refs.iScroll.scrollTop) + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight - 2) {
                this.handleLoadMoreClick();
            }
        });
    }

    componentWillMount() {
        const { filters } = this.props;
        this.state.filters = FilterUtil.getFilterObj(FilterUtil.setAllSitesToFilter(filters));
        this.state.filters = CommonUtil.addNotBlankCondition(this.state.filters);
        this.state.rangeQueryData = FilterUtil.getDateRangeQuery(filters);
        this.setServiceObj();
        this.renderData();
        var locationPath = window.location.pathname;
        var arrVars = locationPath.split("/");
        if (arrVars[1] == 'holistic-view') {
            this.setState({ holisticAdv: true });
        }
        this.getLastUpdatedDate()
    }

    getLastUpdatedDate = async () => {
        const { dataFetchService } = this.state
        const response = await dataFetchService.fetchLastUpdatedDate()
        if (response.status !== 200 || _.isEmpty(response.data.lastUpdated)) return;

        this.setState({
            lastUpdatedDate: response.data.lastUpdated
        })
    }

    renderResultMessage = () => {
        const { isLoading, indicator, campaignList, totalRecordCount } = this.state;
        let camLen = campaignList.length;
        if (this.state.holisticAdv) {
            return null;
        }
        if (!indicator && camLen == 0)
            return <Result message='Sorry, we do not find any data for the selected filters' />;
        if (camLen != 0 && (isLoading || camLen == totalRecordCount))
            return this.props.adserverFilterCount < 2 ? <Result message={`Showing ${totalRecordCount} of ${totalRecordCount}`} /> : "";
        return null;
    }
    /*getUpdatedDataDate = () => {
        const { lastUpdatedDate } = this.state
        if (!_.isEmpty(lastUpdatedDate)) return;

        var today = new Date(Date.now() - 172800000);
        this.setState({
            lastUpdatedDate: `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}`
        })
    }*/

    render() {
        const { indicator, campaignList, tableDimensions, rangeQueryData, advertiserId, lastUpdatedDate } = this.state;
        //this.getUpdatedDataDate();
        
        return (
            <div class="boxContainer marTop20" >
                <div class="headingtagWrap">
                    <div class="lineItemTables">
                        <div class="countTitle">
                            <h3 style={{ 'margin-left': '-24px' }}>Campaigns
                            <span style={{ 'float': 'right' }}>{`Last Updated ${lastUpdatedDate !== null ? lastUpdatedDate : 'Loading...'}`}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className={"campaignListing"} ref="iScroll" style={{ overflow: "auto", height: "calc(100vh - 380px)" }}>
                    <Table
                        tableDimensions={tableDimensions}
                        campaignList={campaignList}
                        handleSortClick={this.handleSortClick}
                        rangeQueryData={rangeQueryData[0]}
                        advertiserId={advertiserId}
                    />
                    {this.renderResultMessage()}
                </div>

                <ActivityIndicator style={styles} height="40" width="40" indicating={indicator} />

            </div>
        )
    }
}

/* CampaignListing.propTypes={
    filters:PropTypes.string.isRequired
}
 */
const mapDispatchToProps = dispatch => bindActionCreators({
    getMetricsCount: getMetricsCount
}, dispatch);

const mapStateToProps = state => {
    return {
        adServerFilter: state.adServerFilter.adServers,
        topMetricsCount: state.topMetricsCount
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignListing);