"use strict";
import C from '../../actions/constants';
const initialState = {
    dateFilterType: 'Start Date',
}
export default function dateTypeFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.DATE_TYPE_FILTER:
        return {
          ...state,
          dateFilterType:action.dateFilterType
      };
      default:
        return state;
    }
};