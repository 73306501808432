import React from 'react';
import ActivityIndicator from '../../common/ActivityIndicator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import CommonUtil from '../../../utils/CommonUtil';
import CreativePreview from '../../modelPopup/CreativePreview';

const facebookLogo = `${process.env.PUBLIC_URL}/assets/images/facebook.jpg`;

const FbLineItemTable=({fbLineItems,indicator,totalMetrics})=>{

    const styles = {
        "margin-left": "520px"
    };

    const headerFormatter=(column)=>{
        return <span class="sort-by">{column.text}</span>;
    };
    
    const getTotalMetrics=()=>{
        const {Impressions,Clicks,Reach,Frequency,PostEngagement,UniqueLinkedClicks }=totalMetrics;
        return {
            'lineItemName':<span class="boldTotal">Total</span>,
            'Start date':'',
            'End date':'',
           // 'rate':<span class="boldTotal">£{Rate}</span>,
            'Impressions':<span class="boldTotal">{CommonUtil.commaSeparatedNumber(Impressions)}</span>,
            'Clicks':<span class="boldTotal">{CommonUtil.commaSeparatedNumber(Clicks)}</span>,
            'CTR':<span class="boldTotal">{CommonUtil.calculateCtr(Clicks,Impressions)}%</span>,
            "Response per 10K Impressions":<span class="boldTotal">{ CommonUtil.calculateResponsePer10K(Clicks, Impressions)}</span>,
            'Reach':<span class="boldTotal">{CommonUtil.commaSeparatedNumber(Reach)}</span>,
            'Frequency':<span class="boldTotal">{Frequency}</span>,
            'PostEngagement':<span class="boldTotal">{CommonUtil.commaSeparatedNumber(PostEngagement)}</span>,
            'UniqueLinkedClicks':<span class="boldTotal">{CommonUtil.commaSeparatedNumber(UniqueLinkedClicks)}</span>,
           // 'engagement':<span class="boldTotal">£00.00</span>
         };   
    }
    
    const getTableData=()=>{
        
        fbLineItems.unshift(getTotalMetrics());
        let columns = [{
            height:20,
            dataField: 'lineItemName',
            text: 'Ad Set Name',
            headerClasses: 'demo-row-odd',
            headerFormatter:headerFormatter,
            title:true
        },{
            dataField: 'ProductType',
            text: 'Product',
            title:true,
            headerClasses: 'demo-row-odd',
            headerFormatter:headerFormatter,
            sort:false
        },{
            dataField: 'startDate',
            text: 'Start date',
            headerFormatter:headerFormatter,
            sort:false,
            headerClasses: 'demo-row-odd'
        },{
            dataField: 'endDate',
            text: 'End date',
            headerFormatter:headerFormatter,
            sort:false,
            headerClasses: 'demo-row-odd'
        },/* {
            dataField: 'rate',
            text: 'Rate',
            headerFormatter:headerFormatter,
            sort:false
        }, */{
            dataField: 'Impressions',
            text: 'Impressions',
            headerFormatter:headerFormatter,
            sort:false,
            headerClasses: 'demo-row-odd'
        },{
            dataField: 'Clicks',
            text: 'Clicks',
            headerFormatter:headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },{
            dataField: 'CTR',
            text: 'CTR',
            headerFormatter:headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },{
            dataField: 'Response per 10K Impressions',
            text: 'Response per 10K Impressions',
            headerEvents: {
                onClick: () => { this.handleSort('Response per 10K Impressions') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: headerFormatter
        },{
            dataField: 'Reach',
            text: 'Reach',
            headerFormatter: headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },
        {
            dataField: 'Frequency',
            text: 'Frequency',
            headerFormatter: headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },
        {
            dataField: 'PostEngagement',
            text: 'Post Engagement',
            headerFormatter: headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },
        {
            dataField: 'UniqueLinkedClicks',
            text: 'Unique Link Clicks',
            headerFormatter: headerFormatter,
            headerClasses: 'demo-row-odd',
            sort:false
        },
        /* {
            dataField: 'engagement',
            text: 'Engagement',
            headerFormatter:headerFormatter,
            sort:true
        } */
        ];
        return columns;
    }
    
    const renderInnerTable = () => {
    
        let data = [];

        return {
            onlyOneExpanding: true,
            renderer: row => {
               return <div style={{ 'transition': 'height 1s' }}>
                    {data}
                </div>
            },
            expanded: false,
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return '';
                }
                return '';
            },
            expandColumnRenderer: ({ expanded, rowKey}) => {
               if(rowKey)
              return <CreativePreview  lineId={rowKey} />;
            },
            nonExpandable: [0, 1]
        };
    }

    return(
       //scrollTable
        <>
        <div class="countTitle">
        <h3 style={{'width':270}}><img style={{'padding-right':6}} width='13%' src={facebookLogo} alt="facebook logo"/>Facebook<span>{fbLineItems.length}</span></h3>
        </div>
        {indicator?
        <ActivityIndicator style={styles} height="40" width="40" indicating={indicator} />
        :<div class="infocontainer tableBorder">
            <BootstrapTable
            styles={{'height':'30px'}}
            keyField='Line Item Id'
            data={fbLineItems}
            expandRow={renderInnerTable()}
            columns={getTableData()}
            headerClasses='thHeader'
            />
         </div>
        }
        </>

    )

}
export default FbLineItemTable;