import config from '../config/config';
import axios from 'axios';
import AuthUtil from './../utils/AuthUtil';
class SavedFilterService {
    fetchDataSynch= async ()=>{
        var data = await axios(this.getPostObject())
        .then(
            res =>  res.data.response
            )
        .catch(err => console.log("saved filter error", err));
        let filterList = JSON.stringify(data)
        return filterList;
    }
    fethData=()=>{
        return axios(this.getPostObject());
    }
    getHeader=()=>{
        return {corp:localStorage.getItem('CorpId'),'content-type': 'application/json'};
    }
    getPostObject=()=>{
        let postObj = {};
        postObj.method=this.urlMethod;
        postObj.url=this.url;
        postObj.data=this.metaData;
        postObj.crossDomain=true;
        postObj.withCredentials=true;
        postObj.headers=this.getHeader();
        postObj.timeout =  '60000';
        return postObj;
    }
    getBody=()=>{
        let obj = {};
        var authUtil = new AuthUtil();
        obj.sessionID = authUtil.getCookieByKey('_sessionID');
        obj.userID = authUtil.getCookieByKey('_userID')
        return obj;
    } 
    getUrl = () => {
        return config.reports.DEFAULT_FILTER_URL_CMS;
    }
}
export default SavedFilterService; 