"use strict"
import C from '../constants';
import ReportingConfigService from '../../service/ReportingConfigService';
import config from '../../config/config';

export  function getCampaignInfoMetaData(dateTypeFilter) {
    return function(dispatch){
        
        var id=config.reports.ids.CAMPAIGN_START_END_DATE_ID;
        if(dateTypeFilter=='serveDate')
        id=config.reports.ids.CAMPAIGN_SERVE_DATE_ID;
        let reportingConfigService = new ReportingConfigService();
        reportingConfigService.id=id;
        reportingConfigService.fetchMetaDataConfig()
        .then(response=>{
           if(response!=undefined && response.hasOwnProperty("data") && response.data[0]!=undefined) {
                let data = response.data;
                var  payload = {};
                if(data.length>0 && data[0].hasOwnProperty("charts")) {
                    let reports = data[0].charts;
                    for(let i in reports) {
                        payload[reports[i].displayName]=reports[i];
                    }
                }
                dispatch({
                    type:C.CAMPAIGN_INFO_METADATA,
                    payload
                });
           }
           else {
              //trigger toast message
              console.log("No meta data found!");
           }
        })
        .catch(error=>{
           //trigger toast message
           console.log("error", error);
        });
    }    
};
