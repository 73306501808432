"use strict";
import C from '../../actions/constants';
import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);
const today = moment();
const numberOfDaysInMonth = moment(today.clone().date(0).format('YYYY-MM-DD'), "YYYY-MM").daysInMonth();
const value = moment.range(today.clone().date(1).subtract(numberOfDaysInMonth, "days"), today.clone().date(0));

const initialState = {
  fromDate: value.start.format('YYYY-MM-DD'),
  toDate: value.end.format('YYYY-MM-DD'),
  holisticFromDate: value.start.format('YYYY-MM-DD'),
  holisticToDate: value.end.format('YYYY-MM-DD'),
  benchMarkFromDate: value.start.format('YYYY-MM-DD'),
  benchMarkToDate: value.end.format('YYYY-MM-DD')
}
export default function dateFilterReducer(state = initialState, action) {
  switch (action.type) {
    case C.DATE_FILTER:
      return {
        ...state,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      };
    case C.HOLISTIC_DATE_FILTER:
      return {
        ...state,
        holisticFromDate: action.payload.holisticFromDate,
        holisticToDate: action.payload.holisticToDate
      };
    case C.BENCHMARK_DATE_FILTER:
      return {
        ...state,
        benchMarkFromDate: action.payload.benchMarkFromDate,
        benchMarkToDate: action.payload.benchMarkToDate
      };
    default:
      return state;
  }
};