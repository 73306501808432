"use strict"

import C from '../constants';
export function setDateFilter(payload) {
    return function (dispatch) {
        dispatch({
            type: C.DATE_FILTER,
            payload
        });
    }
};
export function setHolisticFilterDate(payload) {
    return function (dispatch) {
        dispatch({
            type: C.HOLISTIC_DATE_FILTER,
            payload
        });
    }
};
export function setBenchMarkReportFilterDate(payload) {
    return function (dispatch) {
        dispatch({
            type: C.BENCHMARK_DATE_FILTER,
            payload
        });
    }
};