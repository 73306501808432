"use strict";

const menus = [{
    id: 1,
    displayName: 'Dashboard Info',
    name: 'dashBoard',
    url: '/',
    isAtiveRoute: '',
    isPermissionAvail: true,
    className: 'report'
}, {
    id: 2,
    displayName: 'Reports',
    name: 'campaignReport',
    url: '/campaign-report',
    isAtiveRoute: '',
    isPermissionAvail: false,
    className: 'asset'
}, {
    id: 3,
    displayName: 'BenchMark Report',
    name: 'benchMarkReport',
    url: '/benchmark-report',
    isAtiveRoute: '',
    isPermissionAvail: false,
    className: 'report'
},
// {
//     id:5,
//     displayName:'Holistic View',
//     name:'holisticView',
//     url:'/holistic-view',
//     isAtiveRoute:'',
//     isPermissionAvail:false,
//     className:'holistic'
// },
{
    id: 4,
    displayName: 'Help',
    name: 'help',
    url: '/help',
    isPermissionAvail: true,
    isAtiveRoute: '',
    className: 'help'
}];
export default menus;
