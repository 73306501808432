import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import FilterService from '../../service/FilterService';
import Drawer from '@material-ui/core/Drawer';
import { bindActionCreators } from 'redux';
import { setDefaultFiltersList, setUpdateFilter } from '../../actions/filter/DefaultFiltersAction';
import { setAdServerFilter } from "../../actions/filter/AdServerFilterAction";
import { setAdvertiser,autoAdvFilterName } from "../../actions/filter/AdvertiserFilterAction";
import { setRegion } from "../../actions/filter/ReginFilterAction";
import { setSalesRep } from '../../actions/filter/SalesRepFilterAction';
import styles from './SaveFiltersStyles';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class SetDefaultFilters extends React.Component {
    state = {
        open: false,
        filterName: '',
        responseValue: '',
        setdefaultvalue: '',
        manageopen: false,
        search: '',
        setStateAdvertiser: [],
        setStateRegion: [],
        setStateAdServerFilter: [],
        setStateSalesRep: []
    };
    handleSearch = ({ target }) => this.setState({ search: target.value })
    handleFilterName = (e) => {
        this.setState({
            filterName: e.target.value
        })
    }
    toggles = () => {
        this.setState({ open: !this.state.open, filterName: '' });
            this.props.responseValue.map(item => {
               if( item.filterConfig[3]['filterValues'].length > 0 ){
                this.setState({
                    setStateAdvertiser:item.filterConfig[0]['filterValues'],
                    setStateRegion:item.filterConfig[1]['filterValues'],
                    setStateAdServerFilter:item.filterConfig[2]['filterValues'],
                    setStateSalesRep:item.filterConfig[3]['filterValues']
                 });
               }
            })
    }
    applyFilter = (item = '') => {
        if (item != '') {
            let applyFilter = item.filterConfig;
            this.props.setAdvertiser(applyFilter[0]['filterValues']);
            this.props.setRegion(applyFilter[1]['filterValues']);
            this.props.setAdServerFilter(applyFilter[2]['filterValues']);
            this.props.setSalesRep(applyFilter[3]['filterValues']);
            this.props.handleFilterClick('SaveFilters');
            this.props.setUpdateFilter(item.filterName);
            this.props.autoAdvFilterName(applyFilter[4]['filterValues']);
        } else {
            let applyFilter = item.filterConfig;
            this.props.setAdvertiser([]);
            this.props.setRegion([]);
            this.props.setAdServerFilter([]);
            this.props.setSalesRep([]);
            this.props.autoAdvFilterName({});
            this.props.handleFilterClick('SaveFilters');
            this.props.setUpdateFilter()
        }

    }
    unSetDefault = (item) => {
        this.props.setAdvertiser([]);
        this.props.setRegion([]);
        this.props.setAdServerFilter([]);
        this.props.setSalesRep([]);
        this.props.autoAdvFilterName({});
        this.props.handleFilterClick('SaveFilters');
        let filterService = new FilterService();
        item.filterConfig = item.filterConfig;
        filterService.advertiserFilter = item.filterConfig[0]['filterValues'];
        filterService.regionFilter = item.filterConfig[1]['filterValues'];
        filterService.adServersFilter = item.filterConfig[2]['filterValues'];
        filterService.salesRepFilter = item.filterConfig[3]['filterValues'];
        filterService.advFilterName = item.filterConfig[4]['filterValues'];
        filterService.nameFilter = item.filterName;
        filterService.deleteValue = 0;
        filterService.defaultValue = 0;
        filterService.setDefaulFilters()
            .then(response => {
                if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                    // this.setdefaultvalue = response.datasetdefaultvalue;
                    filterService.fetchDefaultFilters()
                        .then(response => {
                            response.data.response.map(item => {
                                item.filterConfig = JSON.parse(item.filterConfig)
                                return item;
                            })
                            this.props.setDefaultFiltersList(response.data.response);
                            this.setState({
                                setdefaultvalue: response.data.responseMessage
                            })
                        }).catch(error => { console.log("Exception:", error); });
                }
            }).catch(error => { console.log("Exception:", error); });
    }
    setDefault = (item) => {
        this.applyFilter(item);
        let filterValues = item.filterConfig;
        let filterService = new FilterService();
        filterService.advertiserFilter = filterValues[0]['filterValues'];
        filterService.regionFilter = filterValues[1]['filterValues'];
        filterService.adServersFilter = filterValues[2]['filterValues'];
        filterService.salesRepFilter = filterValues[3]['filterValues'];
        filterService.advFilterName = item.filterConfig[4]['filterValues'];
        filterService.nameFilter = item.filterName;
        filterService.deleteValue = 0;
        filterService.defaultValue = 1;
        filterService.setDefaulFilters()
            .then(response => {
                if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                    // this.setdefaultvalue = response.datasetdefaultvalue;
                    filterService.fetchDefaultFilters()
                        .then(response => {
                            response.data.response.map(item => {
                                item.filterConfig = JSON.parse(item.filterConfig)
                                return item;
                            })
                            this.props.setDefaultFiltersList(response.data.response);
                            this.setState({
                                setdefaultvalue: response.data.responseMessage
                            })
                        }).catch(error => { console.log("Exception:", error); });
                }
            }).catch(error => { console.log("Exception:", error); });
    }
    deleteFilters = (item) => {
        let filterService = new FilterService();
        filterService.nameFilter = item.filterName;
        filterService.url = 'POST';
        filterService.deleteFilters()
            .then(response => {
                if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                    // this.setdefaultvalue = response.datasetdefaultvalue;
                    filterService.fetchDefaultFilters()
                        .then(response => {
                            response.data.response.map(item => {
                                item.filterConfig = JSON.parse(item.filterConfig)
                                return item;
                            })
                            this.props.setDefaultFiltersList(response.data.response)
                            this.setState({
                                setdefaultvalue: response.data.responseMessage
                            })
                            if (item.isDefault == 1) {
                                this.props.setAdvertiser([]);
                                this.props.setRegion([]);
                                this.props.setAdServerFilter([]);
                                this.props.setSalesRep([]);
                                this.props.handleFilterClick('SaveFilters');
                            }
                        }).catch(error => { console.log("Exception:", error); });
                } else {
                    this.setState({
                        setdefaultvalue: response.data.responseMessage
                    })
                }
            }).catch(error => { console.log("Exception:", error); });
    };
    manageFilters = () => {
        this.toggles();
        this.setState({ manageopen: !this.state.manageopen });
    }
    managetoggle = () => {
        this.setState({ manageopen: !this.state.manageopen });
    }
    render() {
        const { classes } = this.props;
        //    let nametest = this.props.responseValue.filter(f=>f.filtername=='vivek');
        return (
            <>
                {this.props.responseValue.length > 0 && <Button
                    handleButtonClick={this.toggles}
                    cssClasses='btn btn-small btn-grey btn-xp'
                    displayname='Saved Filters'
                />
                }
                <Drawer anchor={'right'} open={this.state.open} onClose={this.toggles}>
                    <div className="filter-header">
                        <div>Saved Filters</div>
                        <div className="icon" onClick={this.toggles} >&times;</div>
                    </div>
                    <div className={classes.drawercontainer}>
                        <div className="default-filter-list" >
                            <div className={classes.searchFilter}>
                                <input type="text" className="search" onChange={this.handleSearch} value={this.state.search} placeholder="Search" />
                                <span onClick={() => this.applyFilter()}>Clear All</span>
                            </div>
                            {this.props.responseValue.filter(f => f.filterName.toLowerCase().includes(this.state.search)).map((item, index) => {
                                if (item.dashboardType == 'analyticsSavedFilter' && item.isDeleted == 0 && item.filterConfig[4]) {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className={item.isDefault == 0 ? classes.filterContainer : classes.defaultActive}>
                                                <div className={classes.filterLogo} onClick={() => this.applyFilter(item)} >
                                                    {item.filterName}
                                                </div>
                                                <div className={classes.filterAction}>
                                                    {item.isDefault == 0 ? (<div className={classes.setDefaultLogo} onClick={() => this.setDefault(item)} > </div>) : (<div className={classes.unSetDefaultLogo} onClick={() => this.unSetDefault(item)} > </div>)}
                                                    <div className={classes.deleteFilterLogo} onClick={() => this.deleteFilters(item)} ></div>
                                                </div>
                                            </div>
                                        </ React.Fragment>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </Drawer>

            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        responseValue: state.defaultFiltersreducer.defaultFilters
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    setDefaultFiltersList: setDefaultFiltersList,
    setAdvertiser: setAdvertiser,
    setRegion: setRegion,
    setAdServerFilter: setAdServerFilter,
    setSalesRep: setSalesRep,
    setUpdateFilter: setUpdateFilter,
    autoAdvFilterName: autoAdvFilterName
}, dispatch);
// export default connect(mapStateToProps, mapDispatchToProps)(SetDefaultFilters);
export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SetDefaultFilters)));

