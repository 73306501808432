import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataFetchService from '../../service/DataFetchService';
import ActivityIndicator from '../common/ActivityIndicator';
import CommonUtil from '../../utils/CommonUtil';

const dbmLogo = `${process.env.PUBLIC_URL}/assets/images/dbmlogo.png`;

class Dbm extends Component{

    constructor(props){
        super(props);
        this.state={
            fbLineItems:[],
            indicator:true,
            totalMetrics:{}
        };
        this.renderData=this.renderData.bind(this);
        this.renderFbAdSet=this.renderFbAdSet.bind(this);
        this.renderTotalMetrics=this.renderTotalMetrics.bind(this);
        this.getTotalMetricsObj=this.getTotalMetricsObj.bind(this);
    }
    getTotalMetricsObj=()=>{
        let totalMetrics={};
        totalMetrics.Rate=0;
        totalMetrics.Impressions=0;
        totalMetrics.Clicks=0;
        totalMetrics.CTR=0
        totalMetrics.responsePer10KImpressions=0
        totalMetrics.postViewConversions=0
        return totalMetrics
    }

    renderFbAdSet=(fbLineItems)=>{
        if(fbLineItems.length>0){
            let fbView=fbLineItems.map(obj=>{
                return <tr  class="border-bot-table">
                    <td title={obj.lineItemName}>{obj.lineItemName}</td>
                    <td>{obj.startDate}</td>
                    <td>{obj.endDate}</td>
                    <td>{obj.Impressions}</td>
                    <td>{obj.Clicks}</td>
                    <td>{obj.CTR + "%"}</td>
                    <td>{obj.responsePer10KImpressions}</td>
                    <td>{obj.postViewConversions}</td>
                </tr>
            });
            return fbView;
        }
        return null;
    }

    renderTotalMetrics=(totalMetrics)=>{

        if(Object.keys(totalMetrics).length==0)return null;
        return <tr class="border-bot-table">
            <th class="th">Total</th>
            <th class="th"></th>
            <th class="th"></th>
            <th class="th"><p>{totalMetrics.Impressions}</p></th>
            <th class="th"><p>{totalMetrics.Clicks}</p></th>
            <th class="th"><p>{totalMetrics.CTR + "%"}</p></th>
            <th class="th"><p>{totalMetrics.responsePer10KImpressions}</p></th>
            <th class="th"><p>{totalMetrics.postViewConversions}</p></th>
        </tr>;

    }

    renderData=(metaData)=>{

        let { trackingNo, rangeQueryData }=this.props;
        var filters = metaData.filters[0];
        filters.values=[];
        filters.values.push(trackingNo);

        var adServerFilter = {};
        adServerFilter.field = "adServer";
        adServerFilter.values = [];
        adServerFilter.values.push("DBM");

        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.dimensions=metaData.dimensions;
        dataFetchService.size=100;
        dataFetchService.rangeQueryData=rangeQueryData;
        dataFetchService.metricObj=metaData.metrics;
        dataFetchService.operationType=metaData.operationType;
        dataFetchService.filters=[filters, adServerFilter];
        dataFetchService.fethData()
            .then(response=>{

                if(response.hasOwnProperty('data') &&
                    response.data.hasOwnProperty('resultAsList') &&
                    response.data.resultAsList.length>0){
                    var data=response.data.resultAsList;
                    let fbLineItems=[];
                    let fbObj={};
                    let totalMetrics=this.getTotalMetricsObj();
                    for(let index in data){
                        fbObj={};
                        fbObj.lineItemName=data[index]['Line item name']==undefined?'NA':data[index]['Line item name'];
                        fbObj.CTR=data[index].CTR==undefined?'NA':data[index].CTR.toFixed(2);
                        fbObj.Impressions=data[index].Impressions==undefined?'NA':data[index].Impressions;
                        fbObj.Clicks=data[index].Clicks==undefined?'NA':data[index].Clicks;
                        fbObj.startDate=data[index]['Start Date']==undefined?'NA':CommonUtil.getDateWithFormat(data[index]['Start Date']);
                        fbObj.endDate=data[index]['End Date']==undefined?'NA':CommonUtil.getDateWithFormat(data[index]['End Date']);
                        fbObj.postViewConversions=data[index]['Post View Conversions']==undefined?'NA':data[index]['Post View Conversions'];
                        fbObj.responsePer10KImpressions=data[index]['Response per 10K Impressions']==undefined?'NA':data[index]['Response per 10K Impressions'];
                        fbLineItems.push(fbObj);
                        console.log("asd", data[index]['Response per 10K Impressions'])
                        //Total Metrics Cal
                        if(fbObj.Impressions!='NA')
                            totalMetrics.Impressions=totalMetrics.Impressions+fbObj.Impressions;
                        if(fbObj.CTR!='NA')
                            totalMetrics.CTR=parseFloat((totalMetrics.Clicks/totalMetrics.Impressions)*100).toFixed(2);
                        if(fbObj.Clicks!='NA')
                            totalMetrics.Clicks=totalMetrics.Clicks+fbObj.Clicks;
                        if(fbObj.postViewConversions !='NA')
                            totalMetrics.postViewConversions=totalMetrics.postViewConversions+fbObj.postViewConversions;
                        if(fbObj.responsePer10KImpressions !='NA')
                            totalMetrics.responsePer10KImpressions=parseFloat((totalMetrics.Clicks/totalMetrics.Impressions)*10000).toFixed(2);
                    }
                    this.setState({fbLineItems,totalMetrics, indicator:false});
                }
            }).catch(error=>{console.log("Exception:", error);});

    }

    componentWillReceiveProps(newProps){
        console.log("newProps", newProps);
        this.renderData(newProps.dbmLineItemMetaData);
    }

    render(){
        return(
            <div class="lineItemTables">
                <div class="countTitle">
                    <h3 style={{'width':270,position : 'relative', top: '-12px', left: '2px'}}>
                        <img width='13%' style={{'padding-right':6}} src={dbmLogo} alt="dbm logo"/>
                        Audience Extension<span>{this.state.fbLineItems.length}</span></h3>
                </div>
                <div class="tableBorder scrollTable">
                    <table class="table">
                        <thead>
                        <tr class="border-bot-table position">
                            <th class="th sticky"><a href="#" class="sort-by">Line&nbsp;item&nbsp;name</a></th>
                            <th class="th sticky"><a href="#" class="sort-by">Start&nbsp;date</a></th>
                            <th class="th sticky"><a href="#" class="sort-by">End&nbsp;date</a></th>
                            <th class="th sticky"><a href="#" class="sort-by">Impressions</a></th>
                            <th class="th sticky"><a href="#" class="sort-by">Clicks</a></th>
                            <th class="th sticky"><a href="#" class="sort-by">CTR</a></th>
                            <th className="th sticky"><a href="#" className="sort-by">Response&nbsp;per&nbsp;10K&nbsp;Impressions</a></th>
                            <th className="th sticky"><a href="#" className="sort-by">Post&nbsp;view&nbsp;conversions</a></th>
                        </tr>
                        </thead>
                        <tbody class="body">
                        {this.renderTotalMetrics(this.state.totalMetrics)}
                        {this.renderFbAdSet(this.state.fbLineItems)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}



const mapStateToProps=state=>{
    return {
        dbmLineItemMetaData:state.campaignInfoMetaData.dbmLineItemMetaData
    }
};

export default connect(mapStateToProps)(Dbm);