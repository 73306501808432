"use strict";
import C from '../../actions/constants';
const initialState = {
    isActive:false
}
export default function coverSpinnerReducer(state=initialState, action) {
    switch(action.type) {
      case C.ACTIVE_INACTIVE_SPINNER:
        return {
          ...state,
          isActive:action.isActive
      };
      default:
        return state;
    }
};