import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import CampaignInfo from '../campaignDetailView';
import DashBoard from '../dashboard/DashBoard';
import PageNotFound from '../common/PageNotFound';
import Help from '../help/Help';
import SliceAndDice from '../reports/sliceAndDice';
import { connect } from 'react-redux';
import BenchMarkReport from '../reports/benchmarking';
import { bindActionCreators } from 'redux';
import { setSearchBoxFilter,showHideTopSearchBox } from '../../actions/filter/SearchBoxFilterACtion';
import { setTopFilterMetaData } from '../../actions/filter/TopFilterMetaData';
import { setCorpPermission } from '../../actions/permision/PermisionAction';
import ActivityIndicator from '../common/ActivityIndicator';
import HolisticView from '../holistic/Holistic';
import UserUtil from '../../utils/UserUtil'; 

//import ReactTableExample from '../reports/ReactTableExample';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const styles = {
    "margin-left": "50%",
    'position':'relative',
    'left':'-20px',
    "margin-top": "250px"
  };
  

class AppRouter extends Component {
    
    constructor(props){
        super(props);
        this.state={hasPermissionReceived:false};
        this.props.setTopFilterMetaData();
        this.enableAndDisableTopSearchBox=this.enableAndDisableTopSearchBox.bind(this);
    }

    enableAndDisableTopSearchBox=(location)=>{
        if(location=='/')
         this.props.showHideTopSearchBox(true);
         else this.props.showHideTopSearchBox(false);
    }
    
    setCorporatePermission= async ()=>{
        let userUtil=new UserUtil();
        let permision = await userUtil.getUserDetails();
        this.props.setCorpPermission(permision);
        this.setState({hasPermissionReceived:true});
    }

    componentWillMount(){
        //this.props.setCorpPermission();
        this.enableAndDisableTopSearchBox(this.props.location.pathname);
        this.setCorporatePermission(); 
    }
    componentWillReceiveProps(newProps){
        
        let location=newProps.location.pathname;
        this.enableAndDisableTopSearchBox(location);
        if(this.props.location.pathname!=location||location=='/'){
           this.props.setSearchBoxFilter({poNumber:parseInt(`-${(Math.random() * (1000 - 1) + 1).toString()}`),isSet:false});
        }
    }

    render(){
        const {hasPermissionReceived} = this.state;
        if(!hasPermissionReceived) return <ActivityIndicator style={styles} height="40" width="40" indicating={true} />;
        return (
            <Switch>
                <Route exact path="/" component={DashBoard} />
                <Route exact path="/campaign-info/:trackingNo" component={CampaignInfo}  />
                <Route exact path="/campaign-report" component={SliceAndDice} />
                <Route exact path="/benchmark-report" component={BenchMarkReport} />
                <Route exact path="/help" component={Help} />
                <Route exact path="/holistic-view" component={HolisticView} />
                <Route exact path="/pdfdownload" component={HolisticView} />
                <Route component={PageNotFound} />
            </Switch>
        )
    }
}

const mapDispatchToProps=dispatch=>bindActionCreators({
    setSearchBoxFilter:setSearchBoxFilter,
    setTopFilterMetaData:setTopFilterMetaData,
    showHideTopSearchBox:showHideTopSearchBox,
    setCorpPermission:setCorpPermission
},dispatch);

export default connect(null, mapDispatchToProps)(withRouter(AppRouter));
