import React,  { Component } from 'react';
import {withRouter } from 'react-router-dom';
import RenderNavMenu from './RenderNavMenu';
import SideMenus from './SideMenus';
import { connect } from 'react-redux';
import _ from 'lodash';
import { vWrap } from '@progress/kendo-drawing';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

class LeftDrawerNavBar extends Component{

    state={
        isAtiveRoute:'',
        menus:[]
    }
    removeOldActive=()=>{
        let menus=this.state.menus;
        return menus.map(item=>{
            item.isAtiveRoute='';
            return item;
        });
    }

    getPermitedPermissionMenu = (sideMenus) =>{
        return sideMenus.filter(item=>{
            return item.isPermissionAvail;
        })
    }
    
    handleRouteClick=e=>{
        let displayName=e.currentTarget.id;
        this.setState({menus:this.removeOldActive().map(item=>{
            if(item.displayName==displayName) {
                item.isAtiveRoute='active';
                localStorage.setItem("advertiserId", "");
            }
            return item;
        })});
    }

    setSideMenus =(menus)=>{
       let pathname=this.props.location.pathname;
       this.setState({
        menus:menus.map(item=>{
            if(item.url==pathname)item.isAtiveRoute='active';
            return item;
        })});
    }
    componentWillMount(){
       this.setSideMenus(this.getPermitedPermissionMenu(SideMenus));
    }

    setPermission=(sideMenu, menuName)=>{
        for(let index in sideMenu){
            if(sideMenu[index].name==menuName){
                sideMenu[index].isPermissionAvail=true;
                return sideMenu;
            }
        }
        return sideMenu;
    }

    componentWillReceiveProps(newProps){
        console.log('vivekvivek',newProps   )
        const {history} = this.props;
        let displayName="Dashboard Info";
        if (history.location.pathname == "/")
            this.setState({menus:this.removeOldActive().map(item=>{
                if(item.displayName==displayName)item.isAtiveRoute='active';
                return item;
            })});

        if(_.isEqual(this.props.permissions.sort(), newProps.permissions.sort())) return;
        const { permissions } = newProps;
       
        let sideMenu=SideMenus;
        for(let item of permissions){
            if(item=='reports.jda.sliceanddice.view'){
                sideMenu=this.setPermission(sideMenu, 'campaignReport');
            }
            else if(item=='reports.dfs.benchmarking.view'){
                sideMenu=this.setPermission(sideMenu, 'benchMarkReport');
            }
            else if(item=='reports.jda.holisticView.view'){
                sideMenu=this.setPermission(sideMenu, 'holisticView');
            }
        }
        this.setSideMenus(this.getPermitedPermissionMenu(sideMenu));        
    }
    
    render() {
        console.log('vivekvivek',this.state)
        const {menus}=this.state;
       return <RenderNavMenu handleRouteClick={this.handleRouteClick} menus={menus} />
    }
}

const mapStateToProps = state => {
    return {
        permissions:state.corp.permissions
    }
};

export default connect(mapStateToProps,null)(withRouter(LeftDrawerNavBar));