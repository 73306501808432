import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import Toast from '../../common/Toast';
import 'jspdf-autotable';
import FormUtil from '../../../utils/FormUtil';
import CommonUtil from '../../../utils/CommonUtil';
import config from '../../../config/config';
import AuthUtil from '../../../utils/AuthUtil';
import ActivityIndicator from '../../common/ActivityIndicator';

import axios from 'axios';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
const styles2 = {
  "margin-left": "0px",
  "margin-top": "3px"
  
};
const styles = theme => ({
  root: {
    margin: 0,
    padding: '16px',
    display: 'inline-block',
    fontSize: '24px',
    background: '#f3f3f3',
    padding: '18px 18px 17px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    color: '#444',
    fontSize: '22px',
    lineHeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    display: 'block'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CustomizedDialogs extends React.PureComponent {


    componentWillReceiveProps(newProps){
        this.setState({
            subject: `Delivery details of your digital campaign ${this.props.location.pathname.split("/")[2]}`,
            emailBody: `I’m pleased to share with you the delivery details of your digital campaign  ${this.props.location.pathname.split("/")[2]} and look forward to discussing how we can make your next campaign even better.
        
Kind regards, 
${localStorage.getItem("UserName")}`,
        })

    }

  state = {
    open: false,
    email: '',
    subject: `Delivery details of your digital campaign ${this.props.trackingNo}`,
    emailBody: `I’m pleased to share with you the delivery details of your digital campaign  ${this.props.trackingNo} and look forward to discussing how we can make your next campaign even better.

    Kind regards, 
    ${localStorage.getItem("UserName")}`,
    toastOpen: false,
    errorMessage: '',
    variant: '',
    disableButton:false
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleExport = () => {
        const domElement = document.getElementById('campaignInfoPage')
        html2canvas(domElement, {
            onclone: (document) => {
                try{
                    document.getElementById('generatePdf').style.visibility = 'hidden'
                    var val = document.getElementsByClassName('removeArrowForPdf');
                    val[0].classList.remove('sort-by');
                    val[1].classList.remove('sort-by');
                    val[2].classList.remove('sort-by');
                    val[3].classList.remove('sort-by');
                    val[4].classList.remove('sort-by');
                    val[5].classList.remove('sort-by');
                    val[6].classList.remove('sort-by');
                    val[7].classList.remove('sort-by');
                    val[8].classList.remove('sort-by');
                    val[9].classList.remove('sort-by');
                }
                catch(e){};
            }
        })
            .then((canvas) => {
                const img = canvas.toDataURL('image/png', 2.0);
                const pdf = new jsPdf('p', 'mm', [1035, 985]);
                pdf.addImage(img, 'jpg', 8, -15);//imgWidth,imgHeight
                pdf.save();
            });
    }

  handleClose = () => {
    this.setState({ open: false, email: '',disableButton:false });
  }

  validateEmailForm = () => {

    const { toastOpen, email, subject, emailBody } = this.state;
    if (email == '' || subject == '' || emailBody == '') {
      this.setState({ toastOpen: !toastOpen, variant: 'error', errorMessage: 'Please fill the required fields!' });
      return true;
    }
    else if (!FormUtil.validateEmail(email)) {
      this.setState({ toastOpen: !toastOpen, variant: 'error', errorMessage: "Invalid email address!" });
      return true;
    }
    return false;
  }

  handlePageScreenShot = () => {

    if (this.validateEmailForm()) return;
    
     this.setState({disableButton:true});
    const domElement = document.getElementById('campaignInfoPage');
    html2canvas(domElement, {
      onclone: (document) => {
       
        try{
          document.getElementById('generatePdf').style.visibility = 'hidden';
          var val = document.getElementsByClassName('removeArrowForPdf');
          val[0].classList.remove('sort-by');
          val[1].classList.remove('sort-by');
          val[2].classList.remove('sort-by');
          val[3].classList.remove('sort-by');
          val[4].classList.remove('sort-by');
          val[5].classList.remove('sort-by');
          val[6].classList.remove('sort-by');
          val[7].classList.remove('sort-by');
          val[8].classList.remove('sort-by');
          val[9].classList.remove('sort-by');
        }
        catch(e){};
      }
    })
      .then((canvas) => {
        this.props.sendPdf().then(data => {
            var {data, trackingUrn} = data;

            data = data.replace("data:application/pdf;base64,", "");

            const { email, subject, emailBody } = this.state;
            let body = {};
            body.base64 = data;
            body.trackingId = trackingUrn;
            body.toEmail = email;
            body.subject = `Delivery details of your digital campaign ${trackingUrn}`;
            body.mailBody = `I’m pleased to share with you the delivery details of your digital campaign  ${trackingUrn} and look forward to discussing how we can make your next campaign even better.

    Kind regards, 
    ${localStorage.getItem("UserName")}`;

            body.firstName = `${localStorage.getItem("UserName")}`;
            body.lastName = '';
            let postObj = {};
            postObj.method = "POST";
            postObj.url = config.reports.SEND_PDF;
            postObj.data = body;
            postObj.crossDomain = true;
            postObj.withCredentials = true;
            postObj.headers = {
              corp: 4,
              'content-type': 'application/json'
            };
            axios(postObj)
              .then(response => {
                this.setState({
                  open:false,
                  email:'',
                  disableButton:false,
                  toastOpen: true,
                  variant: 'success',
                  errorMessage: `Mail will be sent shortly to ${email}`
                 });

              })
              .catch(error => console.log('Exception occured while send pdf to mail', new Error(error).stack));
          });
        });
  }

  handleOnChangeSubject = (e) => {
    this.setState({ subject: e.target.value });
  }

  handleOnChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  handleOnChangeEmailBody = (e) => {
    this.setState({ emailBody: e.target.value });
  }

  render() {

    const { subject, open, email,disableButton, emailBody, toastOpen, errorMessage, variant } = this.state;
    return (
      <>

        {toastOpen && <Toast
          open={toastOpen}
          message={errorMessage}
          variant={variant}
        />}
        <div class="right"><button id="generatePdf" class="btn btn-small btn-grey btn-xp btn-mail" onClick={this.handleClickOpen}>SEND EMAIL</button></div>
        <Dialog aria-labelledby="customized-dialog-title" open={open}>

          <div className="createmenupopup">
            <div class="popup sendEmailModalHeight">

              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                Send Email
                  </DialogTitle>
              <div class="createmenu-body noPadding activeMainTab">
                <div class="stepEmail stepTab2">
                  <div class="wrapperSteps" tabindex="12" style={{"outline": "-webkit-focus-ring-color auto 0px"}}>
                    <div class="fieldWrap">
                      <label class="active">To</label>
                      <input type="text" value={email} onChange={this.handleOnChangeEmail} class="validate valid" />
                    </div>

                    <div class="fieldWrap" style={{"outline": "-webkit-focus-ring-color auto 0px"}}>
                      <label class="active">Subject</label>
                      <input value={subject} type="text" onChange={this.handleOnChangeSubject} class="validate valid" />
                    </div>

                    <div class="fieldWrap">
                      <label class="active">Messages</label>
                      <textarea style={{"outline": "-webkit-focus-ring-color auto 0px"}} value={emailBody} onChange={this.handleOnChangeEmailBody} class="content" ></textarea>
                    </div>
                  </div>
                  <div class="bottomBtns">
                    <div class="right">
                    {
                      disableButton && <ActivityIndicator 
                        style={styles2}
                        height="25"
                        width="30"
                        indicating={true}
                      />
                    }
                      {/* <button class="btn btn-small btn-grey">Preview</button> */}
                      <button class="btn btn-small btn-grey" onClick={this.handlePageScreenShot} disabled={disableButton}>Send Email</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/*  </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Next
                  </Button>
                </DialogActions> */}
          </div>
        </Dialog>
      </>
    );

  }

}

const mapStateToProps=state => {
    return {
        totalCampaignMetaData:state.campaignInfoMetaData.totalCampaignMetaData
    }
}


export default withRouter(connect(mapStateToProps)(CustomizedDialogs));