"use strict";
import C from '../../actions/constants';
const initialState = {
    permissions:[]
}
export default function dateFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.SET_CORP_PERMISSION:
        return {
          ...state,
          permissions:action.permissions
      };
      default:
        return state;
    }
};