"use strict";
import C from '../constants';

export default function setAdvertisersList(advertiserList) {
    return function (dispatch) {
        dispatch({
            type: C.SET_ADVERTISERS_LIST,
            payload: advertiserList
        });
    }
};