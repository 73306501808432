import React from 'react';
import AppRouter from './app/components/router/AppRouter';
import Main from './app/components/layout/Main';

class App extends React.Component {
  render() {
    return (
      <AppRouter />
    )
  }
}
export default Main(App);
