import React, { Component } from 'react';
import DateTypeFilter from './DateTypeFilter';
import DateFilter from './DateFilter';
import RegionFilter from './RegionFilter';
import AdServerFilter from './AdServerFilter';
import AdvertiserFilter from './AdvertiserFilter';
import WebSiteFilter from './WebsiteFilter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reSetWebsite } from '../../actions/filter/WebsiteFilterAction';
import Button from '../Button';
import SaveFilters from './SaveFilters';
import SetDefaultFilters from './SetDefaultFilters';
import SalesRepFilter from './SalesRepFilter';


class Index extends Component {

    constructor(props) {
        super(props);
        this.props.reSetWebsite();
    }

    render() {

        return (

            <div class="col s12 reportsFilter">
                {/* Filter components */}
                <ul style={{ "margin": 0 }}>
                    <li>{this.props.filterConfig.dateType ? <DateTypeFilter /> : ''}</li>
                    <li>{this.props.filterConfig.date ? <DateFilter /> : ''}</li>
                    <li>{this.props.filterConfig.region ? <RegionFilter /> : ''}</li>
                    <li>{this.props.filterConfig.adServer ? <AdServerFilter /> : ''}</li>
                    <li>{this.props.filterConfig.advertiser ? <AdvertiserFilter /> : ''}</li>
                    <li>{this.props.filterConfig.webSite ? <WebSiteFilter /> : ''}</li>
                    <li>{this.props.filterConfig.saleRep ? <SalesRepFilter /> : ''}</li>
                    <li>{this.props.filterConfig.submitFilter ? <Button
                        handleButtonClick={this.props.handleFilterClick}
                        cssClasses='btn btn-small btn-primary btn-sb'
                        displayname='SUBMIT'
                    /> : ''}
                    </li>
                    <li>{this.props.filterConfig.exportFilter ? <Button
                        handleButtonClick={this.props.handleExportReportClick}
                        cssClasses='btn btn-small btn-grey btn-xp'
                        displayname='EXPORT'
                    /> : ''}
                    </li>
                    <li>
                        {this.props.filterConfig.saveFilter ? <SaveFilters /> : ''}
                    </li>
                </ul>
                {this.props.filterConfig.SetDefaultFilters ? <SetDefaultFilters handleFilterClick={this.props.handleFilterClick} /> : ''}

                {/* <button class="btn btn-small btn-primary btn-sb" onClick={this.props.handleFilterClick}>SUBMIT</button> 
            <button class="btn btn-small btn-grey btn-xp">EXPORT</button> */}
            </div>

        )
    }

}


const mapDispatchToProps = dispatch => bindActionCreators({
    reSetWebsite: reSetWebsite
}, dispatch);

export default connect(null, mapDispatchToProps)(Index);