"use strict";
import C from '../constants';
import CommonUtil from '../../utils/CommonUtil';

export  function setLevelFilter(level) {
    return function(dispatch){
        dispatch({
            type:C.SET_BENCHMARK_LEVEL_FILTER,
            level
        });
    }    
};

export  function setAdvertiserLevels(allLevels) {
    return function(dispatch){
        
        let advertiserLevels={};
        for(let item of allLevels) {
            advertiserLevels[item.name]=Array.isArray(item.values)?item.values[0]:item.values;
        }
        dispatch({
            type:C.SET_ADVERTISER_LEVELS,
            advertiserLevels
        });
    }    
};
