import React, { Component } from 'react';
import DataFetchService from '../../service/DataFetchService';
import FilterUtil from '../../utils/FilterUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { setWebsite, reSetWebsite } from '../../actions/filter/WebsiteFilterAction';
import config from '../../config/config';

class WebsiteFilter extends Component{

    constructor(props){
        super(props);
        
        this.state={
            isOpen:'',
            advertiserData:[],
            isClearAllFlag:false,
            advertisers:[],
            selectedWebsites:[]
        };
        this.handleWebsiteShowHideClick=this.handleWebsiteShowHideClick.bind(this);
        this.handleCheckBoxClick=this.handleCheckBoxClick.bind(this);
        this.handleWebsiteFilterChange=this.handleWebsiteFilterChange.bind(this);
        this.getWebsiteFromDfs=this.getWebsiteFromDfs.bind(this);
        this.handleClickOutside=this.handleClickOutside.bind(this);
        this.props.reSetWebsite();
        this.getWebsiteFromDfs(); 
    }  

    handleClickOutside=(e)=>{
        if(!this.node.contains(e.target)) {
            this.setState({isOpen:''});
        }
    }
    getWebsiteFromDfs=()=>{

        var advertiserMetaData={
            "reportType": "JD_METRICS",
            "indexPrefix": config.index.LINEITEM_SERVEDATE_METRICS,
            "offset": 0,
            "queryType":"AGG",
            "metrics": [ {
                "name": "website",
                "displayName": "Website",
                "op": "SELECT"
            }]
        };
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = advertiserMetaData.indexPrefix;
        dataFetchService.rangeQueryData=[];
        dataFetchService.dimensions=null;
        dataFetchService.metricObj=advertiserMetaData.metrics;
        dataFetchService.operationType=advertiserMetaData.queryType;
        dataFetchService.filters=[];
        dataFetchService.fethData()
        .then(response=>{
            if(response.hasOwnProperty('data')  && response.data.hasOwnProperty('resultAsMap') && response.data.resultAsMap!=null){
                let advertisers=response.data.resultAsMap.Website;
                var advertisersList=[];
                for(var index in advertisers) {
                    advertisersList.push({
                        name:advertisers[index],
                        isSelected:false
                    });
                }
                advertisersList.sort(function(a, b){
                    var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0 //default return value (no sorting)
                })

                this.setState({
                    advertiserData:advertisersList,
                    advertisers:advertisersList
                });
            }
        }).catch(error=>{console.log("Exception:", error);});
    }

    handleWebsiteFilterChange=(e)=>{
        this.setState({
            advertisers:FilterUtil.filterByName(
                this.state.advertiserData,
                e.target.value
            )
        });
    }

    handleWebsiteShowHideClick=(e)=>{
        let className=e.target.className;
        if(className!='' && className!='active'
            && className.indexOf("clickable")==-1
            && className.indexOf("ps__thumb-x")==-1
            && className.indexOf("ps__thumb-y")==-1
            && className.indexOf("ps__rail-x")==-1
            && className.indexOf("ps__rail-y")==-1
            && className.indexOf("dropdown-contents stay active")==-1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-x")==-1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-y")==-1
            && className.indexOf("clearable")==-1
            && className.indexOf("dropdown-select")==-1){
            this.setState({
                isOpen:this.state.isOpen==''?'active':''
            });
        }
    }

    handleClearAllClick=(e)=>{
        let advertisers=this.state.advertisers;
        let selectedWebsites=[];
        e.target.checked=true;
        for(let i in advertisers) {
            advertisers[i].isSelected= false;
        }
        this.state.setWebsite=selectedWebsites;
        this.setState({selectedWebsites,advertisers,isClearAllFlag:false});
        this.props.setWebsite(selectedWebsites);
    }

    handleCheckBoxClick=(e)=>{
        let advertisers=this.state.advertisers;
        let selectedWebsites=this.state.selectedWebsites;
        let value=e.target.value;
        for(let i in advertisers) {
            if(advertisers[i].name==value) {
                let index=selectedWebsites.indexOf(value);
                if(index==-1)selectedWebsites.push(value)
                else selectedWebsites.splice(index, 1);
                advertisers[i].isSelected= !advertisers[i].isSelected;
                break;
            }
        }
        this.state.selectedWebsites=selectedWebsites;
        selectedWebsites.length>0?this.setState({isClearAllFlag:true}):this.setState({isClearAllFlag:false});
        this.setState({advertisers});
        this.props.setWebsite(selectedWebsites);
    }

    componentWillMount=()=>{
        document.addEventListener("mousedown", this.handleClickOutside,false);
    }
    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside,false);
    }
    render(){

        return(
            
            <span ref={node=>this.node=node}>
            <span class="taskstatus" onClick={this.handleWebsiteShowHideClick}>
                <span class="order-by dropdown plainPop filterTrigger">
                    <a  class="hover-link"><span class="jdicon jdicon-filter"></span>Website<i class="material-icons">arrow_drop_down</i></a>
                    <div class={`dropdown-contents stay ${this.state.isOpen}`}>
                        <div class="filter-options">
                            <div class="dpSearch input-field clearable">
                                <i class="material-icons prefix">search</i>
                                <input 
                                    type="text" 
                                    placeholder="Search" 
                                    id="assetTypeSearch" 
                                    style={{"border-color": "rgba(0, 0, 0, 0.08)"}}
                                    onChange={this.handleWebsiteFilterChange}
                                />
                            </div>
                        </div>
                        <ul class="dropdown-menu auto dropdown-select " id="media-sort">
                        <PerfectScrollbar>
                        <div className={"scroller"}>
                            {
                                <li className="subtaksRFStatus clickable">
                                    {this.state.isClearAllFlag && <a href="javascript:void(0);" onClick={this.handleClearAllClick} class='clearAll'><u>Clear All</u></a>}
                                    {!this.state.isClearAllFlag && <h8 className="clearAllDisable">Clear All</h8>}
                                </li>
                            }
                            {
                                this.state.advertisers.map(item=>{
                                    return <li class="subtaksRFStatus clickable">
                                        <label onChange={this.handleCheckBoxClick} class="checkboxWrap commonCheck">
                                            <input 
                                                type="checkbox"
                                                class=""
                                                value={item.name}
                                                checked={item.isSelected}
                                            />
                                            <span>{item.name}</span>
                                        </label>
                                    </li>
                                }, this)
                            }
                        </div>
                        </PerfectScrollbar>
                        </ul>
                    </div>
                </span>
            </span>
            </span>
        )
    }
}

const mapDispatchToProps = dispatch=>bindActionCreators({
    setWebsite:setWebsite,
    reSetWebsite:reSetWebsite
}, dispatch);

export default connect(null, mapDispatchToProps)(WebsiteFilter);