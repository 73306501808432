import React, { Component, ReactDOM } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { setAdServerFilter,setAdServerFilterDropDown } from '../../actions/filter/AdServerFilterAction';
import FilterUtil from '../../utils/FilterUtil';
import _ from 'lodash';

class AdServerFilter extends Component{

    constructor(props){
        super(props);
        const adServers=[
            {name:'FB',isSelected:false},
            {name:'GAM',isSelected:false},
            {name:'DBM',isSelected:false},
        ];
        this.state={
            staticAdServerData:adServers,
            adServers:[],
            selectedAdServer:[],
            isOpen:''
        };
        this.props.setAdServerFilter(this.state.selectedAdServer);
        this.props.setAdServerFilterDropDown(adServers);
        this.handleCheckBoxClick=this.handleCheckBoxClick.bind(this);
        this.getIndex=this.getIndex.bind(this);
        this.handleAdvertiserFilterNameChange=this.handleAdvertiserFilterNameChange.bind(this);
        this.handleAdServerShowHideClick=this.handleAdServerShowHideClick.bind(this);
        this.handleClickOutside=this.handleClickOutside.bind(this);
    }
    handleClickOutside(event) {

        if(!this.node.contains(event.target)) {
            this.setState({
                isOpen:''
            });
        }
    }
    handleAdServerShowHideClick(e){
        let className=e.target.className;
        if(className!='' && className!='active' 
        && className.indexOf("clickable")==-1
         && className.indexOf("clearable")==-1
         && className.indexOf("dropdown-select")==-1){
            this.setState({
                isOpen:this.state.isOpen==''?'active':''
            });
        }
    }
    
    handleAdvertiserFilterNameChange=(e)=>{
        this.setState({
            adServers:FilterUtil.filterByName(
                this.state.staticAdServerData,
                e.target.value
            )
        });
    }
    
    getIndex=(adServers, value, flag)=>{
        for(var i in adServers) {
            if(adServers[i].name == value) {
                adServers[i].isSelected=flag;
                break;
            }
        }
        return adServers;
    }

    handleCheckBoxClick=(e)=>{
        let value=e.target.value;
        let selectedAdServer = this.props.adServerFilter;
        if(value!=undefined && value!=""){
            const { adServers }= this.state;
            let index = selectedAdServer.indexOf(value);
            if(index==-1){
                selectedAdServer.push(value);
                this.setState({
                    adServers:this.getIndex(adServers, value, true)
                });   
            }
            else {
                selectedAdServer.splice(index,1);
                this.setState({
                    adServers:this.getIndex(adServers, value, false)
                });  
            }
            this.props.setAdServerFilterDropDown(adServers);
            this.props.setAdServerFilter(selectedAdServer);
        }
    } 

    componentWillMount=()=>{
        document.addEventListener("mousedown", this.handleClickOutside,false);
    }

    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside,false);
    }

    componentWillReceiveProps(newProps){
        const { adServers }= this.state;
        this.setState({adServers:newProps.dropDownData});
        // _.forEach(adServers, (value)=>{
        //     if (value.name==='GAM') {
        //         value.isSelected = true;
        //         let index = this.state.selectedAdServer.indexOf("GAM");
        //         if(index==-1) {
        //             this.state.selectedAdServer.push("GAM");
        //         }
        //     }
        // });
    }

    render(){
        
        return(
            <span ref={node=>this.node=node}>
            <span class="taskstatus"  onClick={this.handleAdServerShowHideClick}>
            <span class="order-by dropdown  plainPop">
            <a  class="hover-link"><span class="jdicon jdicon-filter"></span>Ad Server<i class="material-icons">arrow_drop_down</i></a>
            <div class={`dropdown-contents  stay ${this.state.isOpen}`}>
                <div class="filter-options">
                    <div class="dpSearch input-field clearable">
                        <i class="material-icons prefix">search</i>
                        <input type="text" placeholder="Search" onChange={this.handleAdvertiserFilterNameChange} id="assetTypeSearch" style={{"border-color": "rgba(0, 0, 0, 0.08)"}} />
                    </div>
                </div>

                <ul class="dropdown-menu auto dropdown-select " id="media-sort">
                <span class="arrow-up"></span>
                
                {  
                    
                    this.state.adServers.map(item=>{
                        item.isSelected =false;
                        if(this.props.adServerFilter.indexOf(item.name) !== -1)  item.isSelected =true;
                        return <li class="subtaksRFStatus clickable">
                        <label onChange={this.handleCheckBoxClick} class="checkboxWrap commonCheck">
                            <input 
                                type="checkbox"
                                value={item.name} 
                                checked={item.isSelected}
                                />
                            <span>{item.name=='GAM'?'Newsquest Display':item.name=='FB'?'Facebook':item.name=='DBM'?'Audience Extension':'Audience Extension'}</span>
                        </label></li>
                    }, this) 
                }
                </ul>
            </div>
            </span>
        </span>
        </span>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAdServerFilter:setAdServerFilter,
    setAdServerFilterDropDown:setAdServerFilterDropDown
 },dispatch);

 const mapStateToProps=state=>{
    return  {
        adServerFilter:state.adServerFilter.adServers,
        dropDownData:state.adServerFilter.dropDownData
    }
 }


export default connect(mapStateToProps, mapDispatchToProps)(AdServerFilter);