import React from 'react';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

export default ({indicating,height,width,style}) => 
!indicating?null:<img height={height} width={width}
 style={style} src={process.env.PUBLIC_URL + '/assets/images/preloader.gif'} />
