import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog'
import Button from '../Button';
import { DialogContent, DialogTitle } from '@material-ui/core';
import FilterService from '../../service/FilterService';
import { bindActionCreators } from 'redux';
import { setDefaultFiltersList } from '../../actions/filter/DefaultFiltersAction';
import { removeRegion } from '../../actions/filter/ReginFilterAction';
import { removeAdserver } from '../../actions/filter/AdServerFilterAction';
import { removeAdvertiser, autoAdvFilterName} from '../../actions/filter/AdvertiserFilterAction';
import Chip from '@material-ui/core/Chip';
import styles from './SaveFiltersStyles';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';


class SaveFilters extends React.Component {
    state = {
        open: false,
        filterName: '',
        responseValue: '',
        AlertBackGroundColor: '',
        flagForUpdateFilter: true,
        saveButtonFlag: false
    };
    handleFilterName = (e) => {
        this.setState({
            filterName: e.target.value
        })
    }
    toggle = () => {
        if (this.props.advertiserFilter.length > 0 || this.props.regionFilter.length > 0 || this.props.adServersFilter.length > 0) {

            this.setState({ open: !this.state.open, filterName: '', responseValue: '', saveButtonFlag: true });
        }
        if (this.props.advertiserFilter.length == 0 && this.props.regionFilter.length == 0 && this.props.adServersFilter.length == 0) {

            this.setState({ open: false, filterName: '', responseValue: '', saveButtonFlag: false });
        }
    }
    saveDefaultFilters = () => {
        this.setState({
            saveButtonFlag: false
        })
        this.state.filterName = this.state.filterName.trim();
        if (this.props.advertiserFilter.length < 1 && this.props.regionFilter.length < 1 && this.props.adServersFilter.length < 1 && this.props.selectedSalesRep.length < 1) {
            this.setState({
                responseValue: 'Please Choose Some Filters',
                AlertBackGroundColor: '#d43f3a'
            })
            return 0;
        }
        if (this.state.filterName == '') {
            this.setState({
                responseValue: 'Enter Filter Name',
                AlertBackGroundColor: '#d43f3a'
            })
            return 0;
        }
        let checkIndex = this.props.defaultFilters.findIndex(f => f.filterName == this.state.filterName);
        if (checkIndex != -1) {
            this.setState({
                responseValue: 'Enter Unique Filter Name',
                AlertBackGroundColor: '#d43f3a'
            })
            return;
        }
        let filterService = new FilterService();
        filterService.advertiserFilter = this.props.advertiserFilter;
        filterService.regionFilter = this.props.regionFilter;
        filterService.nameFilter = this.state.filterName;
        filterService.advFilterName = this.props.getAutoAdvFilterName;
        filterService.salesRepFilter = this.props.selectedSalesRep;
        filterService.adServersFilter = this.props.adServersFilter;
        filterService.deleteValue = 0;
        filterService.defaultValue = 0;
        filterService.saveDefaultFilters()
            .then(response => {
                if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                    filterService.fetchDefaultFilters()
                        .then(response => {
                            if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                                response.data.response.map(item => {
                                    item.filterConfig = JSON.parse(item.filterConfig)
                                    return item;
                                })
                                this.props.setDefaultFiltersList(response.data.response)
                                this.setState({
                                    responseValue: 'Filter Saved Sucessfully',
                                    AlertBackGroundColor: '#04AA6D'
                                })
                                setTimeout(() => { this.toggle(); }, 2000);
                            }

                        }).catch(error => { console.log("Exception:", error); });

                } else {
                    this.setState({
                        responseValue: 'Try Again Later Or Contact Technical Team',
                        AlertBackGroundColor: '#d43f3a'
                    })
                    setTimeout(() => { this.toggle(); }, 2000);
                }

                // this.props.addDefaultFiltersList(JSON.parse(response.config.data));
            }).catch(error => { console.log("Exception:", error); });

    }
    updateFilterValue = () => {
        if (this.props.advertiserFilter.length < 1 && this.props.regionFilter.length < 1 && this.props.adServersFilter.length < 1 && this.props.selectedSalesRep.length < 1) {
            this.setState({
                responseValue: 'Please Choose Some Filters',
                AlertBackGroundColor: '#d43f3a'
            })
            return 0;
        }
        if (this.props.updateFilterName == ' ') {
            this.setState({
                responseValue: 'Only update Filter Can update here',
                AlertBackGroundColor: '#d43f3a'
            })
            return 0;
        }


        let filterService = new FilterService();
        filterService.advertiserFilter = this.props.advertiserFilter;
        filterService.regionFilter = this.props.regionFilter;
        filterService.adServersFilter = this.props.adServersFilter;
        filterService.salesRepFilter = this.props.selectedSalesRep;
        filterService.nameFilter = this.props.updateFilterName;
        filterService.advFilterName = this.props.getAutoAdvFilterName;
        filterService.deleteValue = 0;
        filterService.defaultValue = 0;
        filterService.setDefaulFilters()
            .then(response => {
                if (response.data.status != 'INTERNAL_SERVER_ERROR') {
                    // this.setdefaultvalue = response.datasetdefaultvalue;
                    filterService.fetchDefaultFilters()
                        .then(response => {
                            response.data.response.map(item => {
                                item.filterConfig = JSON.parse(item.filterConfig)
                                return item;
                            })
                            this.props.setDefaultFiltersList(response.data.response);
                            this.setState({
                                responseValue: 'Filter Updated Sucessfully',
                                AlertBackGroundColor: '#04AA6D'
                            })
                            setTimeout(() => { this.toggle(); }, 2000);
                        }).catch(error => { console.log("Exception:", error); });
                }
                else {
                    this.setState({
                        responseValue: 'Try Again Later Or Contact Technical Team',
                        AlertBackGroundColor: '#d43f3a'
                    })
                    setTimeout(() => { this.toggle(); }, 2000);
                }
            }).catch(error => { console.log("Exception:", error); });

    }
    deleteRegion = (id) => {
        this.props.removeRegion(id)
    }
    deleteAdserve = (id) => {
        this.props.removeAdserver(id)
    }
    deleteAdvertiser = (id) => {
        this.props.removeAdvertiser(id)
        delete this.props.getAutoAdvFilterName[id];
        this.props.autoAdvFilterName(this.props.getAutoAdvFilterName)
    }
    saveFilterspop = () => {
        this.setState({
            flagForUpdateFilter: true,
            responseValue: ''
        })

    }
    updateFilterpop = () => {
        this.setState({
            flagForUpdateFilter: false,
            responseValue: ''
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Button
                    handleButtonClick={this.toggle}
                    cssClasses={`btn btn-small btn-xp ${this.props.advertiserFilter.length > 0 ? 'btn-primary' : 'btn-grey'}`}
                    displayname='Save Filter'
                    disabled='true'
                />
                <Dialog open={this.state.open} onClose={this.toggle}>
                    <ul className={!this.props.updateFilterName ? ([classes.filterListClass, 'selectList'].join(' ')) : classes.filterListClass}>
                        <li className={!this.state.flagForUpdateFilter ? '' : classes.filterClassActive} onClick={this.saveFilterspop}>Save Filters </li>
                        {this.props.updateFilterName && <li className={!this.state.flagForUpdateFilter ? classes.filterClassActive : ''} onClick={this.updateFilterpop}>Update Filters </li>}
                    </ul>
                    <hr />
                    {
                        this.state.flagForUpdateFilter &&
                        <DialogContent>
                            <div className={classes.overallSelectValue}>
                                {this.props.advertiserFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Advertiser Filter</div>
                                    <hr />
                                    {
                                        this.props.advertiserFilter.map(mdata => {
                                            let { name = "" } = this.props.getAdvertiserList.find(f => f.id == mdata) || {}
                                            return (
                                                <Chip
                                                    label={this.props.getAutoAdvFilterName[mdata]}
                                                    onDelete={() => this.deleteAdvertiser(mdata)}
                                                />
                                            )
                                        })
                                    }
                                </div>}
                                {this.props.adServersFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Adserve Filter</div>
                                    <hr />
                                    {
                                        this.props.adServersFilter.map(mdata => {
                                            return (
                                                <Chip
                                                    label={mdata}
                                                    onDelete={() => this.deleteAdserve(mdata)}
                                                />

                                            )
                                        })
                                    }
                                </div>}
                                {this.props.regionFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Region Filter</div>
                                    <hr />
                                    {
                                        this.props.regionFilter.map(mdata => {
                                            let { name = "" } = this.props.getAllRegionList.find(f => f.id == mdata) || {}
                                            return (
                                                <Chip
                                                    label={name}
                                                    onDelete={() => this.deleteRegion(mdata)}
                                                />

                                            )

                                        })
                                    }
                                </div>}
                            </div>
                            {/* <div className="save_icon" onClick={this.toggle} >&times;</div> */}
                            <div style={{ width: 500 }}>
                                <div className={classes.textClassName}>
                                    <input
                                        type="text"
                                        placeholder="Enter Filter Name"
                                        value={this.state.filterName}
                                        onChange={this.handleFilterName}
                                    />
                                </div>
                                <div className={classes.buttonBlock}>
                                    <Button
                                        handleButtonClick={this.state.saveButtonFlag && this.saveDefaultFilters}
                                        cssClasses='btn btn-small btn-success btn-xp'
                                        displayname='Saves'
                                    />
                                    <Button
                                        handleButtonClick={this.toggle}
                                        cssClasses='btn btn-small btn-grey btn-xp'
                                        displayname='Discard'
                                    />
                                </div>

                            </div>
                            {this.state.responseValue !== '' && <div className="alert-success" style={{ backgroundColor: this.state.AlertBackGroundColor }}>
                                {this.state.responseValue}
                            </div>}
                        </DialogContent>}
                    {this.props.updateFilterName && !this.state.flagForUpdateFilter &&
                        <DialogContent>
                            <div>
                                <span>Filter Name : {this.props.updateFilterName}</span>
                            </div>
                            <div class="input-field clearable">
                                {this.props.advertiserFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Advertiser Filter</div>
                                    <hr />
                                    {
                                        this.props.advertiserFilter.map(mdata => {
                                            let { name = "" } = this.props.getAdvertiserList.find(f => f.id == mdata) || {}
                                            return (
                                                <Chip
                                                    label={this.props.getAutoAdvFilterName[mdata]}
                                                    onDelete={() => this.deleteAdvertiser(mdata)}
                                                />
                                            )
                                        })
                                    }
                                </div>}
                                {this.props.adServersFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Adserve Filter</div>
                                    <hr />
                                    {
                                        this.props.adServersFilter.map(mdata => {
                                            return (
                                                <Chip
                                                    label={mdata}
                                                    onDelete={() => this.deleteAdserve(mdata)}
                                                />

                                            )
                                        })
                                    }
                                </div>}
                                {this.props.regionFilter.length > 0 && <div className="selected-filter-wrapper">
                                    <div className="filter-name">Region Filter</div>
                                    <hr />
                                    {
                                        this.props.regionFilter.map(mdata => {
                                            let { name = "" } = this.props.getAllRegionList.find(f => f.id == mdata) || {}
                                            return (
                                                <Chip
                                                    label={name}
                                                    onDelete={() => this.deleteRegion(mdata)}
                                                />

                                            )

                                        })
                                    }
                                </div>}
                            </div>
                            <br />
                            {/* <div className="save_icon" onClick={this.toggle} >&times;</div> */}
                            <div style={{ width: 500 }}>
                                <div className={classes.buttonBlock}>
                                    <Button
                                        handleButtonClick={this.updateFilterValue}
                                        cssClasses='btn btn-small btn-success btn-xp'
                                        displayname='Update'
                                    />
                                    {' '}
                                    <Button
                                        handleButtonClick={this.toggle}
                                        cssClasses='btn btn-small btn-grey btn-xp'
                                        displayname='Discard'
                                    />
                                </div>
                            </div>

                            {this.state.responseValue !== '' && <div className="alert-success" style={{ backgroundColor: this.state.AlertBackGroundColor }}>
                                {this.state.responseValue}
                            </div>}
                        </DialogContent>

                    }
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        sites: state.regionFilter.sites,
        regionFilter: state.regionFilter.regions,
        topFilterMetaData: state.topFilterMetaData,
        advertiserFilter: state.advertiser.advertiserFilter,
        adServersFilter: state.adServerFilter.adServers,
        defaultFilters: state.defaultFiltersreducer.defaultFilters,
        selectedSalesRep: state.salesRepFilterReducer.selectedSalesRep,
        getAdvertiserList: state.advertiser.advertiserDropDownList,
        getAllRegionList: state.regionFilter.allRegionFilter,
        updateFilterName: state.defaultFiltersreducer.updateFiterName,
        getAutoAdvFilterName: state.advertiser.autoAdvertiserFilterName,
    };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    setDefaultFiltersList: setDefaultFiltersList,
    removeRegion: removeRegion,
    removeAdserver: removeAdserver,
    removeAdvertiser: removeAdvertiser,
    autoAdvFilterName: autoAdvFilterName
    // removeAdvertiser:removeAdvertiser

}, dispatch);
// export default connect(mapStateToProps, mapDispatchToProps)(SaveFilters);
export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SaveFilters)));
