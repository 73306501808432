"use strict";


class FormUtil {

    static validateEmail(email) {
        
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(email)
    }
}

export default FormUtil;