import React, { Component } from 'react';
import TopNavBar from './TopNavBar';
import LeftDrawerNavBar from './LeftDrawerNavBar';
import AuthUtil from '../../utils/AuthUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateToken } from '../../actions/auth/login';
import ActivityIndicator from '../../components/common/ActivityIndicator';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const styles = {
  "margin-left": "50%",
  'position':'relative',
  'left':'-20px',
  "margin-top": "250px"
};

const Main = BaseComponent => {

    var authUtil = new AuthUtil();
    authUtil._sessionID=authUtil.getCookieByKey('_sessionID');
    authUtil._userID=authUtil.getCookieByKey('_userID');  
    if(authUtil.validateSessionCookie()){
      authUtil.renderLoginPage();
      return null;
    }

    class layoutHOC extends React.PureComponent {

      constructor(props) {
        super(props);
        this.state = {};
        this.props.validateToken(authUtil);
        this.renderPage=this.renderPage.bind(this);
      }
    
      renderPage=(tokenStatus)=>{
        if(tokenStatus=="isValid")
        return <ActivityIndicator style={styles} height="40" width="40" indicating={true} />;
        else if(tokenStatus=='Valid')
          return <div><TopNavBar /><LeftDrawerNavBar /><BaseComponent /></div>
        else authUtil.renderLoginPage();
      }
      
      render() {
        return (
            <>
             {this.renderPage(this.props.isValidToken)}
            </>
        )
      }
    }

    const mapStateToProps=state=>{
      return {
        isValidToken:state.auth.isValidToken
      }
    }

    const mapDispatchToProps=dispatch=>bindActionCreators({
      validateToken:validateToken
    }, dispatch)
    return connect(mapStateToProps, mapDispatchToProps)(layoutHOC);
  };

  export default Main;