"use strict";

export default [
    'Skin',
    'Thick Skin',
    'Desktop Skin',
    '360 Degree',
    'Cube',
    'Gallery',
    'Calculator',
    'Video Billboard - In Banner',
    'Video MPU - In Banner',
    'Video DHMPU - In Banner',
    'Video Skin - In Banner',
    'HIM Anchored AdProduct - Care',
    'HIM Anchored AdProduct - Dine',
    'HIM Anchored AdProduct - Enrol',
    'HIM Anchored AdProduct - Furnish',
    'HIM Anchored AdProduct - Health & Wellbeing',
    'HIM Anchored AdProduct - Property',
    'HIM Anchored AdProduct - Refurb',
    'HIM Anchored AdProduct - Travel',
    'HIM Anchored AdProduct - Vanilla',
    'HIM Interscroller AdProduct - Care',
    'HIM Interscroller AdProduct - Dine',
    'HIM Interscroller AdProduct - Enrol',
    'HIM Interscroller AdProduct - Furnish',
    'HIM Interscroller AdProduct - Health & Wellbeing',
    'HIM Interscroller AdProduct - Property',
    'HIM Interscroller AdProduct - Refurb',
    'HIM Interscroller AdProduct - Travel',
    'HIM Interscroller AdProduct - Vanilla',
    'Essential AdProduct',
    'Ultimate HIM Anchored AdProduct - Care',
    'Ultimate HIM Anchored AdProduct - Dine',
    'Ultimate HIM Anchored AdProduct - Enrol',
    'Ultimate HIM Anchored AdProduct - Furnish',
    'Ultimate HIM Anchored AdProduct - Health & Wellbeing',
    'Ultimate HIM Anchored AdProduct - Property',
    'Ultimate HIM Anchored AdProduct - Refurb',
    'Ultimate HIM Anchored AdProduct - Travel',
    'Ultimate HIM Anchored AdProduct - Vanilla',
    'Ultimate HIM Interscroller AdProduct - Care',
    'Ultimate HIM Interscroller AdProduct - Dine',
    'Ultimate HIM Interscroller AdProduct - Enrol',
    'Ultimate HIM Interscroller AdProduct - Furnish',
    'Ultimate HIM Interscroller AdProduct - Health & Wellbeing',
    'Ultimate HIM Interscroller AdProduct - Property',
    'Ultimate HIM Interscroller AdProduct - Refurb',
    'Ultimate HIM Interscroller AdProduct - Travel',
    'Ultimate HIM Interscroller AdProduct - Vanilla',
    'Standard Enhanced Display',
    'Premium Enhanced Display',
    'HIM Anchored Enhanced Display',
    'HIM Interscroller Enhanced Display',
    'Essential Enhanced Display',
    'Supersize Enhanced Display',
    'Motors Enhanced Display',
    'Standard Enhanced Display - Talent Boost',
    'Standard Enhanced Display - Prime',
    'Premium Enhanced Display - Prime',
    'Property Enhanced Display',
    'BMD Enhanced Display',
    'Essential AdProduct',
    'Standard Enhanced Display',
    'Premium Enhanced Display',
    'HIM Anchored Enhanced Display',
    'HIM Interscroller Enhanced Display',
    'Essential Enhanced Display',
    'Supersize Enhanced Display',
    'Motors Enhanced Display',
    'Standard Enhanced Display - Talent Boost',
    'Standard Enhanced Display - Prime',
    'Premium Enhanced Display - Prime',
    'Property Enhanced Display',
    'BMD Enhanced Display',
    'Essential Adproduct'
    ];