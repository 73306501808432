import React from 'react';
import ActivityIndicator from '../../common/ActivityIndicator';
import CommonUtil from '../../../utils/CommonUtil';

const TotalMetricsTable=({totalImpression,totalClicks,metricsObj,indicator })=>{
    
    const styles = {
        "margin-left": "520px"
    };

    const renderTotalMetricView=()=>{
        if(Object.keys(metricsObj).length==0)return null;
        return <tr class="border-bot-table boldTotal">
        <th class="th" width="55%">Total</th>
        <th class="th">{CommonUtil.commaSeparatedNumber(totalImpression)}</th>
        <th class="th">{CommonUtil.commaSeparatedNumber(totalClicks)}</th>
        <th class="th">{CommonUtil.calculateCtr(totalClicks,totalImpression)}%</th>
        <th class="th">{CommonUtil.calculateResponsePer10K(totalClicks,totalImpression)}</th>
        </tr>
    }

    const renderTableHeader=()=>{
        return <thead>
        <tr class="border-bot-table">
            <th class="th" width="50%"></th>
            <th class="th">Impressions</th>
            <th class="th">Clicks</th>
            <th class="th">CTR</th>
            <th style={{whiteSpace:''}} class="th">Response per 10K Impressions</th>
        </tr>
    </thead>;
    }
    const getAdserverNameAndIconTD=(adServer)=>{
        switch(adServer){
            case 'GAM':
            return  <td class="td"><span class="itemIcon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/dfplogo.png`} alt="GAM" height="32"/>
            </span> Newsquest Display</td>
            case 'FB':
            return  <td class="td"><span class="itemIcon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/facebook.jpg`} alt="FACEBOOK" height="32"/>
            </span> Facebook</td>
            case 'DBM':
            return  <td class="td"><span class="itemIcon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/dbmlogo.png`} alt="DBM" height="32"/>
            </span> Audience Extension</td>
            default:return null;
        }
        
    }
    const renderTable=()=>{
        
        let tableRow=[];
        for(let adServer in metricsObj) {
            tableRow.push(
                <tr class="border-bot-table add td wrapTable">
                 {getAdserverNameAndIconTD(adServer)}
                <td class="td">{CommonUtil.commaSeparatedNumber(metricsObj[adServer].impressions)}</td>
                <td class="td art">{CommonUtil.commaSeparatedNumber(metricsObj[adServer].clicks)}</td>
                <td class="td art">{`${metricsObj[adServer].ctr}%`}</td>
                <td class="td art">{ CommonUtil.calculateResponsePer10K(metricsObj[adServer].clicks, metricsObj[adServer].impressions)}</td>
                </tr>
            );
        }
        return tableRow;
    }

    return(
        <div class="boxContainer">
            <div class="headingtagWrap">
                <h2>Campaign Metrics</h2>
            </div>
            {indicator?
             <ActivityIndicator 
                style={styles}
                height="40"
                width="40"
                indicating={indicator}
            />
            :<table class="table">
            {renderTableHeader()}
                <tbody class="body">
                    {renderTotalMetricView()}
                    {renderTable()}
                </tbody>
            </table>}
        </div>
    )
}

export default TotalMetricsTable;
