import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

const ReportDisplayMessage=(props)=>{

    const { level,levelSearch,totalBenchMarkAdvertiserCount } = props;

    return(
        <>
          {props.autoSuggestAdvertiserName !== '' &&
            <div class="cmnInsideWrapper">
                <div class="boxContainer cardPadd noMarTop">
                    <p>Benchmarking Advertiser: {props.autoSuggestAdvertiserName!=''?props.autoSuggestAdvertiserName:''}
                        {(props.advertiserLevels.level1 && props.advertiserLevels.level2 && props.advertiserLevels.level3) &&
                        <span> against <b> LEVEL 1</b> - {props.advertiserLevels.level1}, <b> LEVEL 2</b> - {props.advertiserLevels.level2}, <b> LEVEL 3</b> - {props.advertiserLevels.level3}</span>}
                    </p>
                </div>
            </div>}

            {levelSearch !== ''  && props.autoSuggestAdvertiserName === '' &&
            <div class="boxContainer cardPadd">
                <p>There are {totalBenchMarkAdvertiserCount} advertisers in the selected category and the metrics presented below are the overall metrics achieved by them. Please select a specific category and advertiser to compare them against the benchmark for the respective category.</p>
            </div>}

        </>

    )
}
// {console.log('levelee',props.advertiserLevels.level1)}
const mapStateToProps=state=>{
    return{
        level:state.levelType.level,
        advertiserLevels:state.levelType.advertiserLevels,
        levelSearch:state.search.levelSearch,
        totalBenchMarkAdvertiserCount:state.advertiser.totalBenchMarkAdvertiserCount,
        autoSuggestAdvertiserName:state.advertiser.autoSuggestAdvertiserName,
    }
}

export default connect(mapStateToProps)(ReportDisplayMessage);