import campaignReducer from './campaign/campaignReducer';
import navBarReducer from './layout/navBarReducer';
import DateTypeFilterReducer from './filter/DateTypeFilterReducer'
import DateFilterReducer from './filter/DateFilterReducer';
import campaignMetaDataReducer from './campaign/campaignInfoMetaDataReducer';
import adServerReducer from './filter/AdServerReducer';
import AdvertiserReducer from './filter/AdvertiserReducer';
import RegionFilterReducer from './filter/RegionFilterReducer';
import WebsiteFilterReducer from './filter/WebsiteFilterReducer';
import loginReducer from './auth/loginReducer';
import SearchBoxFilterReducer from './filter/SearchBoxFilterReducer';
import levelTypeFilterReducer from './filter/LevelTypeFilterReducer';
import topFilterMetaDataReducer from './campaign/topFilterMetaDataReducer';
import PermissionReducer from './permission/PermissionReducer';
import CoverSpinnerReducer from './spinner/coverSpinner';
import AdvertiserList from './benchmarking/setAdvertisersList'
import topMetricsCount from './campaign/topMetricsCountReducer';
import defaultFiltersReducer from './filter/DefaultFiltersReducer';
import salesRepFilterReducer from './filter/SalesRepFilterReducer';
export default {
    campaign: campaignReducer,
    navbar: navBarReducer,
    dateType: DateTypeFilterReducer,
    dateFilter: DateFilterReducer,
    campaignInfoMetaData: campaignMetaDataReducer,
    adServerFilter: adServerReducer,
    advertiser: AdvertiserReducer,
    regionFilter: RegionFilterReducer,
    websiteFilter: WebsiteFilterReducer,
    auth: loginReducer,
    search: SearchBoxFilterReducer,
    levelType: levelTypeFilterReducer,
    topFilterMetaData: topFilterMetaDataReducer,
    topMetricsCount: topMetricsCount,
    corp: PermissionReducer,
    spinner: CoverSpinnerReducer,
    advertiserList: AdvertiserList,
    defaultFiltersreducer: defaultFiltersReducer,
    salesRepFilterReducer:salesRepFilterReducer
};