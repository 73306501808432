"use strict";
import { onSelectedFilterMetaData } from '../components/reports/sliceAndDice/MetaData';
class ReportUtil{

    constructor(){};
    static addAndRemoveDimenstions=(dimensions, filterLen,displayName)=>{

        if(filterLen>0) {
            if(!this.checkDimensionExist(dimensions, displayName)) {
               if(displayName=='Advertiser')
               dimensions.push(onSelectedFilterMetaData['advertiserId']);
               if(displayName=='Region')
               dimensions.push(onSelectedFilterMetaData['regionId']);
               dimensions.push(onSelectedFilterMetaData[displayName]);
            }
         }
         else {
            if(this.checkDimensionExist(dimensions, displayName)) {
               if(displayName=='Advertiser')
               dimensions = this.removeDimesnion(dimensions, 'Advertiser Id');
               if(displayName=='Region')
               dimensions = this.removeDimesnion(dimensions, 'Region Value');
               dimensions = this.removeDimesnion(dimensions, displayName);
            }
         }
         return dimensions;
    }

    static checkDimensionExist=(dimensions, displayName)=>{
        for(let index in dimensions){
           if(dimensions[index].displayName == displayName) {
              return true;
           }
        }
        return false;
     }
     /* static taremoveDimesnion=(dimensions, displayName)=>{
        for(let index in dimensions){
           if(dimensions[index].displayName == displayName) {
              dimensions.splice(index, 1);
              return dimensions;
           }
        }
     }
 */
    static removeDimesnion=(dimensions, displayName)=>{
        for(let index in dimensions){
            if(dimensions[index].displayName == displayName) {
                dimensions.splice(index, 1);
                return dimensions;
            }
        }
    }
    static setTableCell(dimensionsMetaData, metricsMetaData){
        let metrics=metricsMetaData.map(obj=>{
            return obj.displayName;
         });
         let dimensions=[];  
         for(var index in dimensionsMetaData){
            if(dimensions.indexOf(dimensionsMetaData[index].displayName) == -1)dimensions.push(dimensionsMetaData[index].displayName); 
         }
         return dimensions.concat(metrics);
    }
    static exportReport(fileName, data){
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName);
      document.body.appendChild(link);
      link.click();
    }

}

export default ReportUtil;