import React from 'react';
import { connect } from 'react-redux';
import DataFetchService from '../../service/DataFetchService';
import CommonUtil from '../../utils/CommonUtil';
import FbLineItemTable from './tables/FbLineItemTable';
import _, { toInteger } from 'lodash';
import axios from 'axios';
const LosslessJSON = require('lossless-json');

class FaceBook extends React.PureComponent{

    constructor(props){
        super(props);
        this.state={
            fbLineItems:[],
            indicator:true,
            totalMetrics:{}
        }
        this.renderData=this.renderData.bind(this);
        this.getTotalMetricsObj=this.getTotalMetricsObj.bind(this);
    }
    getTotalMetricsObj=()=>{
        let totalMetrics={};
        totalMetrics.Rate=0;
        totalMetrics.Impressions=0;
        totalMetrics.Clicks=0;
        totalMetrics.CTR=0
        totalMetrics.totalBudget=0;
        totalMetrics.Reach=0;
        totalMetrics.Frequency=0;
        totalMetrics.PostEngagement=0;
        totalMetrics.UniqueLinkedClicks=0;
        return totalMetrics
    }
    renderData=(metaData)=>{

        let { trackingNo, rangeQueryData }=this.props;
        var filters = metaData.filters[0];
            filters.values=[];
            filters.values.push(trackingNo);
        let modifiedFilter = [filters];
        modifiedFilter.push({field:'adServer', values:['FB']}); 
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.dimensions=metaData.dimensions;
        dataFetchService.size=100;
        dataFetchService.rangeQueryData=rangeQueryData;
        dataFetchService.metricObj=metaData.metrics;
        dataFetchService.operationType=metaData.operationType;
        dataFetchService.filters=modifiedFilter;
        // dataFetchService.fethData()
        let postObject = dataFetchService.fetchPostObject();
        axios(
            {
                ...postObject,
                ...{transformResponse : [data  => data]}
            })
        .then(res=>{
            console.log('FB call response',res.data);
            var response;
            if(res.hasOwnProperty('data')) response = JSON.parse(res.data);
            if(response && response.hasOwnProperty('resultAsList') &&
            response.resultAsList.length>0){
                var lossLessDataForLineItemId = (LosslessJSON.parse(res.data)).resultAsList;
                var data=response.resultAsList;
                let fbLineItems=[];
                let fbObj={};
                let totalMetrics=this.getTotalMetricsObj();
                for(let index in data){
                    fbObj={};
                    
                    fbObj.Impressions='NA';
                    fbObj.Clicks='NA';
                    fbObj.Reach='NA';
                    fbObj.Frequency='NA';
                    fbObj.UniqueLinkedClicks='NA';
                    fbObj.lineItemName=data[index]['Line item name']==undefined?'NA':data[index]['Line item name'];
                    fbObj.CTR=_.isNil(data[index].CTR)?'NA':`${data[index].CTR.toFixed(2)}%`;
                    data[index]['Post Engagement'] = _.toSafeInteger(_.defaultTo(data[index].Post_Engagemnet, data[index]['Post Engagement']));
                    if(!_.isNil(data[index].Impressions)){
                        totalMetrics.Impressions=totalMetrics.Impressions+data[index].Impressions;
                        fbObj.Impressions=CommonUtil.commaSeparatedNumber(data[index].Impressions);
                    }
                    if(!_.isNil(data[index].Clicks)){
                        totalMetrics.Clicks=totalMetrics.Clicks+data[index].Clicks;
                        fbObj.Clicks=CommonUtil.commaSeparatedNumber(data[index].Clicks);
                    }
                    if(!_.isNil(data[index].Reach)){
                        totalMetrics.Reach=Number(totalMetrics.Reach)+Number(data[index].Reach);
                        fbObj.Reach=CommonUtil.commaSeparatedNumber(Number(data[index].Reach));
                    }

                    if(!_.isNil(data[index]['Frequency'])){
                        totalMetrics.Frequency = (Number.parseFloat(totalMetrics.Frequency) + Number.parseFloat(data[index].Frequency)).toFixed(2);
                        fbObj.Frequency=Number(data[index]['Frequency']);
                    }

                    if(!_.isNil(data[index]['Post Engagement'])){
                        totalMetrics.PostEngagement=totalMetrics.PostEngagement+data[index]['Post Engagement'];
                        fbObj.PostEngagement=CommonUtil.commaSeparatedNumber(data[index]['Post Engagement']);
                    }

                    if(!_.isNil(data[index]['Unique Link Clicks'])){
                        totalMetrics.UniqueLinkedClicks=Number(totalMetrics.UniqueLinkedClicks)+Number(data[index]['Unique Link Clicks']);
                        fbObj.UniqueLinkedClicks=CommonUtil.commaSeparatedNumber(Number(data[index]['Unique Link Clicks']));
                    }
                    fbObj['Line Item Id'] = lossLessDataForLineItemId[toInteger(index)]['Line Item Id'].value;
                    fbObj.ProductType=_.isNil(data[index]['Product Type'])?'NA':data[index]['Product Type'];
                    fbObj.startDate=_.isNil(data[index]['Start Date'])?'NA':CommonUtil.getDateWithFormat(data[index]['Start Date'],1);
                    fbObj.endDate=_.isNil(data[index]['End Date'])?'NA':CommonUtil.getDateWithFormat(data[index]['End Date'],0);
                    fbObj.totalBudget=_.isNil(data[index]['Total Budget'])?'NA':data[index]['Total Budget'];
                    fbObj['Response per 10K Impressions'] = data[index]['Response per 10K Impressions'];
                    fbObj.Frequency = Number.parseFloat(data[index]['Frequency']).toFixed(2);
                    fbObj.PostEngagement = data[index]['Post Engagement'];
                    fbObj.UniqueLinkedClicks = data[index]['Unique Link Clicks'];
                    //fbObj.rate=_.isNil(data[index]['rate'])?'£0':data[index]['rate'];
                   // fbObj.engagement=data[index]['engagement']==undefined?'£00.00':data[index]['engagement'];
                    fbLineItems.push(fbObj);
                    if(fbObj.totalBudget!='NA')
                    totalMetrics.totalBudget=totalMetrics.totalBudget+fbObj.totalBudget;
                }
                this.setState({fbLineItems,totalMetrics, indicator:false});
            }
        }).catch(error=>{console.log("Exception:", error);});

    }

    componentWillReceiveProps(newProps){
        this.renderData(newProps.fbLineItemMetaData);
    }
    
    render(){
        return <FbLineItemTable {...this.state} />
    }
}

const mapStateToProps=state=>{
    return {
        fbLineItemMetaData:state.campaignInfoMetaData.fbLineItemMetaData
    }
};

export default connect(mapStateToProps)(FaceBook);