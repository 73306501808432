import React, {Component} from 'react';
import DateFilter from '../../filters/DateFilterBenchMark';
import Button from '../../Button';
import Level from './Filters/Level';
import LevelSearchBox from './Filters/LevelSearchBox';
import AdvertiserSearchBox from './Filters/AdvertiserSearchBox';
// import RegionFilter from '../../filters/RegionFilter';


const TopFilters=(props)=> {

        const { handleSubmitClick, handleExportReportClick}=props;
        return ( 
            <div class="reportsFilter noMarTop">
                <ul class="filter-options noMarTop">
                    <li>Adserve Date: &nbsp;</li>
                    <DateFilter />
                    {/* <li> <RegionFilter /></li> */}
                    <Level />
                    <LevelSearchBox />   
                    <AdvertiserSearchBox />
                    <li><Button 
                    handleButtonClick={handleSubmitClick}
                    cssClasses='btn btn-small btn-primary btn-sb'
                    displayname='SUBMIT'
                    /></li>
                </ul>
                {/* <div class="marginL10">
                    
                </div> */}
                    {/* <Button 
                    handleButtonClick={handleExportReportClick}
                    cssClasses='btn btn-small btn-grey btn-xp'
                    displayname='EXPORT'
                    /> */}
            </div>
         )
}
 
export default TopFilters;