import React from 'react';
import { connect } from  'react-redux';
import DataFetchService from '../../service/DataFetchService';
import ActivityIndicator from '../common/ActivityIndicator';
import SelectComponentModel from '../../components/modelPopup/sendMail/SelectComponentModel';

import CustomTooltip from './tables/CustomTooltip';

/* import html2canvas from 'html2canvas'
import jsPdf from 'jspdf' */

const styles = {
    "margin-left": "502px"
};

class CampaignDetail extends React.PureComponent{

    
    state={
        data:[],
        salesRep:'',
        buisnessType:'',
        isDataLoading:true
    }

    renderAdserverIcons=()=>{

        const {adServers}=this.props.campInfo;
        const IMAGE_BASE_DIR=`${process.env.PUBLIC_URL}/assets/images`;
        const adServerIcons= []
        if(adServers.indexOf('Newsquest Display')>=0)
        adServerIcons.push(<img src={`${IMAGE_BASE_DIR}/dfplogo.png`} height="32"/>);
        if(adServers.indexOf('FACEBOOK')>=0 )
        adServerIcons.push(<img src={`${IMAGE_BASE_DIR}/facebook.jpg`} alt="Couldn't load" height="32"/>);
        if(adServers.indexOf('Audience Extension')>=0)
        adServerIcons.push(<img src={`${IMAGE_BASE_DIR}/dbmlogo.png`} alt="Couldn't load" height="32" />);
        return adServerIcons;

    }

    getDecodedValue(encodedString){
        return decodeURIComponent(encodedString);
    }
    getCampDetailsParams=()=>{

        const {trackingNo,advertiser,startDate,region,endDate}=this.props.campInfo;
        const data = [
            {displayName:'URN', value:this.getDecodedValue(trackingNo)},
            {displayName:'Advertiser', value:this.getDecodedValue(advertiser)},
            {displayName:'Sales rep email',value:'NA'},
            {displayName:'Start Date', value:startDate},
            {displayName:'Business Type', value:'NA'},
            {displayName:'Region', value:this.getDecodedValue(region)},
            {displayName:'Ad Server', value:this.renderAdserverIcons()},
            {displayName:'End Date', value:endDate},
        ];
        return data;
    }

    renderData=(metaData)=>{
        
        /* Modify filter to add poNumber */
        let { trackingNo } = this.props.campInfo;
        let data=this.getCampDetailsParams();
        var filters = metaData.filters[0];
            filters.values = [];
            filters.values.push(trackingNo);
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.dimensions=metaData.dimensions;
        dataFetchService.size=100;
        dataFetchService.operationType=metaData.operationType;
        dataFetchService.filters=[filters];
        dataFetchService.fethData()
        .then(response=>{
            var result=response.data.resultAsList
              , businessType='NA'
              , salesRep='NA';
            if(result.length>0){   
                result=result[0];
                businessType=result['Business Type']!=''?result['Business Type']:'NA';
                salesRep=result['Sales Rep']!=''?result['Sales Rep']:'NA';
                this.state.businessType=result['Business Type']!=''?result['Business Type']:'NA';
                this.state.salesRep=result['Sales Rep']!=''?result['Sales Rep']:'NA';
            }
            data[2]={displayName:'Sales rep email',value:salesRep};
            data[4]={displayName:'Business Type',value:businessType};
            this.setState({data,isDataLoading:false});
        }).catch(error=>{console.log("Exception:", error);this.setState({data,isDataLoading:false});});
    }

    componentWillReceiveProps(newProps){
        this.renderData(newProps.detailsMetaData);
    }

    renderComponentView=()=>{
        
        const {data,isDataLoading}=this.state;    
        if(isDataLoading) return <ActivityIndicator style={styles} height="40" width="40" indicating={isDataLoading} />;
        // return data.map(item=>{
            return <><div class="row">
            <div class='col m3' >
           <div class="detailsText">
               <label>{data[0].displayName+' :'}</label>
               <p>{data[0].value}</p>
           </div>
       </div>
       <div class='col m2' >
           <div class="detailsText">
               <label>{data[3].displayName+' :'}</label>
               <p>{data[3].value}</p>
           </div>
       </div>
       <div class='col m2' >
           <div class="detailsText">
               <label>{data[7].displayName+' :'}</label>
               <p>{data[7].value}</p>
           </div>
       </div>
       <div class='col m2' >
           <div class="detailsText">
               <label>{data[6].displayName+' :'}</label>
               <p>{data[6].value}</p>
           </div>
       </div>
       <div class='col m3' >
           <div class="detailsText">
               <label>{data[5].displayName+' :'}</label>
               <p>{data[5].value}</p>
           </div>
       </div>
       </div>
       <div class="row">
       <div class='col m3' >
           <div class="detailsText">
               <label>{data[2].displayName+' :'}</label>
               <p>{data[2].value}</p>
           </div>
       </div>
       <div class='col m6' >
           <div class="detailsText">
               <label>{data[1].displayName+' :'}</label>
               <p>{data[1].value}</p>
           </div>
       </div>
       <div class='col m3' >
           <div class="detailsText">
               <label>{data[4].displayName+' :'}</label>
               <p>{data[4].value}</p>
           </div>
       </div> 
       </div>      
       </>    
        // });

    }

   render(){
    
    const {trackingNo,advertiser,startDate,region,endDate,adServers}=this.props.campInfo;
    const {totalClicks,totalImpression,ctr}=this.props;
    const {salesRep,businessType}=this.state;
    return (
        <div class="boxContainer">
                <div class="headingtagWrap">
                    <h2 class="left">Details</h2>
                    {/* <div class="right"><button onClick={this.printPDF} id="generatePdf" class="btn btn-small btn-grey btn-xp">SEND EMAIL</button></div> */}
                </div>
                <div class="rowPadding">
                    
                    {this.renderComponentView()}
                
                </div>
        </div>
    );
   }
}


const mapStateToProps=state => {
    return {
        detailsMetaData:state.campaignInfoMetaData.detailsMetaData
    }
}
export default connect(mapStateToProps, null) (CampaignDetail);