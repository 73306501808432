import React, { Component } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSearchBoxFilter } from '../../actions/filter/SearchBoxFilterACtion';
import { setAdServerFilter, setAdServerFilterDropDown } from '../../actions/filter/AdServerFilterAction';
import config from '../../config/config';
import CommonUtil from '../../utils/CommonUtil';
import FilterUtil from '../../utils/FilterUtil';
import { setAdvertiser, autoAdvFilterName } from "../../actions/filter/AdvertiserFilterAction";
import { setRegion } from "../../actions/filter/ReginFilterAction";
import { setSalesRep } from '../../actions/filter/SalesRepFilterAction';
import JSONBigInt from 'json-bigint';
import themestyles from '../../components/holistic/HolisticStyle';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';

const styles = {
    'overflow': 'auto',
    'outline': 'none',
    'display': 'flex'
};

const style2 = {
    'overflow-y': 'hidden',
    'outline': 'none',
    'display': 'flex',
    'height': '93px'
};

class SearchBoxFilter extends Component {

    state = {
        poNumbers: [],
        styles: {},
        advstyles: {},
        selectedPoNumner: '',
        noSearchResult: false,
        advertisers: [],
        searchvalue: 10,
    }

    handleChangeText = (e) => {


        let value = e.target.value.trim();
        if (value == "") this.props.setSearchBoxFilter({ poNumber: '', isSet: false });
        this.setState({ selectedPoNumner: value, noSearchResult: false });
        let url = `${config.reports.SEARCH_BOX_FILTER}?text=${value}&field=poNumber.text`;
        let postObj = {};
        postObj.method = 'POST';
        postObj.url = url;
        postObj.crossDomain = true;
        postObj.withCredentials = true;
        let filterRegion = [];
        if (FilterUtil.setAllSitesToFilterForSearch({})['regions'])
            filterRegion.push({ field: 'region', values: FilterUtil.setAllSitesToFilterForSearch({})['regions'] })
        postObj.data = {
            indexPrefix: config.index.LINEITEM_AGGREGATE_METRICS,
            filters: filterRegion
        };
        postObj.headers = { corp: localStorage.getItem('CorpId'), 'content-type': 'application/json' };
        axios(postObj)
            .then(res => {
                this.setState({ poNumbers: [], styles: {}, noSearchResult: true });
                if (res.hasOwnProperty('data') && Array.isArray(res.data) && res.data.length > 0)
                    this.setState({ poNumbers: res.data, styles: styles, noSearchResult: false });
                else {
                    this.setState({ poNumbers: [], styles: style2, noSearchResult: true });
                }
            }).catch(error => { console.log("error", error) });
    }
    handleAdvertiserFilterNameChange = (e) => {

        let searchKey = e.target.value.trim();
        let url = `${config.reports.SEARCH_BOX_FILTER}?text=${encodeURIComponent(searchKey)}&field=name.text`;
        let postObj = {};
        postObj.method = 'POST';
        postObj.url = url;
        postObj.crossDomain = true;
        postObj.withCredentials = true;
        postObj.data = {
            indexPrefix: config.index.ADVERTISERS,
            "dimensions": [{ "name": "id", "displayName": "id" }],
            "metrics": [{ "name": "name", "displayName": "name" }]
        };
        postObj.headers = { corp: localStorage.getItem('CorpId'), 'content-type': 'application/json' };
        postObj.transformResponse = [data => data];
        axios(postObj)
            .then(res => {
                this.setState({ advertisers: [], advstyles: {}, noSearchResult: true });
                if (res.hasOwnProperty('data') && res.data.length > 0) {
                    console.log('stylesstyles', styles);
                    let advertisersList = this.cleanUpAdvertiserData(JSONBigInt.parse(res.data), 'name', 'id');
                    this.setState({ advertisers: this.removeDuplicateAdvertisers(advertisersList), advstyles: styles, noSearchResult: false });
                }
                else {
                    this.setState({ advertisers: [], advstyles: style2, noSearchResult: true });
                }
            }).catch(error => { console.log("error", error) });

    }
    removeDuplicateAdvertisers = (advertisersList) =>{
        var lookup = {};
        var items = advertisersList;
        var result = [];
        for (var item, i = 0; item = items[i++];) {
        var name = item.name;
            if (!(name in lookup)) {
                lookup[name] = 1;
                result.push(item);
            }
            else{
                console.log("dup Advertiser {}", item );
            }
        }
        console.log("dedup Advertiser  count {}", result.length );
        return result;
    }
    cleanUpAdvertiserData = (advertisers, id, name) => {
        const flagvalue = '';
        var advertisersList = [];
        for (var index in advertisers) {
            advertisersList.push({ id: advertisers[index][name], name: advertisers[index][id], isSelected: false });
        }
        return advertisersList;
    }
    setTextBoxSerachValue = (value) => {
        this.props.setSearchBoxFilter({ poNumber: value, isSet: true });
        this.setState({ poNumbers: [], styles: {}, selectedPoNumner: value });
        this.props.setAdServerFilter([]);
        this.props.setAdvertiser([]);
        this.props.setRegion([]);
        this.props.setSalesRep([]);
    }

    handlePoNumberSelectClick = (e) => {
        let value = e.target.text;
        this.setTextBoxSerachValue(value);
    }
    componentWillReceiveProps(newProps) {
        if (newProps.search == '' || CommonUtil.isNumber(newProps.search)) {
            this.setState({ selectedPoNumner: '' });
            this.props.setSearchBoxFilter({ poNumber: '', isSet: false });
        }
    }

    handleClickOutside = (e) => {
        if (this.state.noSearchResult) {
            this.setState({ noSearchResult: false, styles: {} });
        }
    }

    componentWillMount = () => {
        document.addEventListener("mousedown", this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search != this.props.search && this.props.search != this.state.selectedPoNumner) {
            this.setState({ selectedPoNumner: this.props.search });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside, false);
    }
    keyPress = (e) => {
        if (e.keyCode == 13) {
            const { poNumbers } = this.state;
            if (poNumbers.length == 1) {
                this.setTextBoxSerachValue(poNumbers[0]);
            }
        }
    }
    searchboxValue = (e) => {
        let value = e.target.value.trim();
        console.log('valuevaluevalue', value)
        this.setState({ searchvalue: value });

    }
    render() {
        const { classes } = this.props;
        let locs = [
            { "id": 10, "values": "Search by URN", "name": "selURN"},
            { "id": 11, "values": "Search by Advertiser", "name": "selAdvertiser" }
        ]
        return (

            <div ref={node => this.node = node} className="search-container hide-on-med-and-down">
                <div className="search">
                    <div className="input-field clearable searchWrap" style={{ border: "1px solid rgba(0, 0, 0, 0.08)", background: "rgb(245, 245, 245)" }} >
                        <div className={classes.dropdownWrap}>
                            <Select
                                native
                                value={this.state.searchvalue}
                                onChange={this.searchboxValue}
                                classes={{ root: classes.dropdown }}>
                                {
                                    locs.map(loc => {
                                        console.log('loclocloc', loc.values)
                                        if (this.state.searchvalue == loc.id) {
                                            return (
                                                <option className={classes.selURN} value={loc.id}>{loc.values}</option>
                                            )
                                        } else {
                                            return (
                                                <option className={classes.selAdvertiser} value={loc.id}>{loc.values}</option>
                                            )
                                        }
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    {this.state.searchvalue == 10 && <div className="input-field clearable searchWrap" style={{ border: "1px solid rgba(0, 0, 0, 0.08)", background: "rgb(245, 245, 245)" }} >
                        {/* <i className="material-icons prefix">search</i> */}
                        <input placeholder="Enter URN here"
                            onKeyDown={this.keyPress}
                            onChange={this.handleChangeText}
                            value={this.state.selectedPoNumner}
                            type="text" className="validate"
                            autoComplete="off" />
                        <span className="search-clear"></span>
                        <div className="searchDropdown" style={this.state.styles}>
                            <span className="arrow-up"></span>
                            <div className="searchDropdownCon">
                                <ul>
                                    {
                                        this.state.poNumbers.map(poNumber => {
                                            return <li><a onClick={this.handlePoNumberSelectClick}>{poNumber}</a></li>
                                        }, this)
                                    }
                                    {this.state.noSearchResult ? <ul><li><a>No Result</a></li></ul> : ''}
                                </ul>
                            </div>
                        </div>
                        <i className="close jdicon jdicon-close"></i>
                    </div>}
                    {this.state.searchvalue == 11 && <div className="input-field clearable searchWrap" style={{ border: "1px solid rgba(0, 0, 0, 0.08)", background: "rgb(245, 245, 245)" }} >
                        {/* <i className="material-icons prefix">search</i> */}
                        <input
                            type="text"
                            placeholder="Enter Advertiser here"
                            id="advTypeSearch"
                            style={{ "border-color": "rgba(0, 0, 0, 0.08)" }}
                            onChange={this.handleAdvertiserFilterNameChange}
                        />
                        <span className="search-clear"></span>
                        <div className="searchDropdown" style={this.state.advstyles}>
                            <span className="arrow-up"></span>
                            <div className="searchDropdownCon">
                                <ul>
                                    {
                                        //  console.log('item.id',this.state.advertisers)
                                        this.state.advertisers.map(item => {
                                            var locationPath = "/holistic-view?advertiser=" + item.name + "&advertiserId=" + item.id;
                                            return <li><a href={locationPath}>{item.name}</a></li>
                                            item.isSelected = false;
                                            if (this.props.responseValue.indexOf(item.id.toString()) !== -1) item.isSelected = true;
                                            return <li class="subtaksRFStatus clickable">
                                                <label onChange={this.handleCheckBoxClick} class="checkboxWrap commonCheck">
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        value={item.id}
                                                        checked={item.isSelected}
                                                    />
                                                    <span>{item.name}</span>
                                                </label>
                                            </li>
                                        }, this)
                                    }
                                    {this.state.noSearchResult ? <ul><li><a>No Result</a></li></ul> : ''}
                                </ul>
                            </div>
                        </div>
                        <i className="close jdicon jdicon-close"></i>
                    </div>}
                </div>
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        search: state.search.poNumber
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setSearchBoxFilter: setSearchBoxFilter,
    setAdServerFilter: setAdServerFilter,
    setAdServerFilterDropDown: setAdServerFilterDropDown,
    setAdvertiser: setAdvertiser,
    setRegion: setRegion,
    setSalesRep: setSalesRep,
}, dispatch);

export default withRouter(withStyles(themestyles)(connect(mapStateToProps, mapDispatchToProps)(SearchBoxFilter)));