import React from 'react';
import CommonUtil from '../../../utils/CommonUtil';

//import PropTypes from 'prop-types';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-07-04
*/


const Table=(props)=>{

    const { tableCell }=props;
    const getTd=(obj)=>{
        var temp=[];
        if(obj.hasOwnProperty("adServer")) temp.push(<td>{CommonUtil.changeAdServerDisplayName(obj.adServer)}</td>);
        if(obj.hasOwnProperty("Region")) temp.push(<td>{obj.Region}</td>);
        if(obj.hasOwnProperty("Advertiser")) temp.push(<td>{obj.Advertiser}</td>);
        if(obj.hasOwnProperty("website")) temp.push(<td>{obj.website}</td>);
        if(obj.hasOwnProperty("Impressions")) temp.push(<td>{CommonUtil.commaSeparatedNumber(obj.Impressions)}</td>);
        if(obj.hasOwnProperty("Clicks")) temp.push(<td>{CommonUtil.commaSeparatedNumber(obj.Clicks)}</td>);
        if(obj.hasOwnProperty("CTR")) temp.push(<td>{obj.CTR.toFixed(2)}%</td>)
        return temp;
    };
    var toTalImpression=0
      , totClicks=0
      , totalCtr=0;
    const reportData=props.reportData.map(row=>{
        toTalImpression+=row.Impressions;
        totClicks+=row.Clicks;
        totalCtr+=row.CTR;
        return <tr>{getTd(row)}</tr>;
    });
    
    const getTotalTableHeadrs=()=>{
        let th=[];
        if(reportData.length==0) return th;
        th.push(<th class="th">Total</th>);
        for(let index in tableCell){
            if(tableCell[index]=='Region' || tableCell[index]=='Advertiser' || tableCell[index]=='website') th.push(<th></th>);
        }
        th.push(<th class="th">{CommonUtil.commaSeparatedNumber(toTalImpression)}</th>);
        th.push(<th class="th">{CommonUtil.commaSeparatedNumber(totClicks)}</th>);
        th.push(<th class="th">{totalCtr.toFixed(2)}%</th>);
        return th;
    }

    const getTableCell=()=>{

       let fixedHeaders=[
        <th class="th" width="330">Platform</th>,
        <th class="th" width="330">Impression</th>,
        <th class="th" >Clicks</th>,
        <th class="th" width="320">CTR</th>   
       ];
        if(tableCell.length==0)return fixedHeaders;
        let tCell=tableCell.map(cell=>{
            if(cell=='adServer') return <th class="th" width="330">Platform</th>;
            else if(cell=='Region')return <th class="th" width="330">{cell}</th>;
            else if(cell=='Advertiser')return <th class="th" width="330">{cell}</th>;
            else if(cell=='website')return <th class="th" width="330">{cell}</th>;
        });
        fixedHeaders.shift();
       return tCell.concat(fixedHeaders); 
    }

    return(
        
        <div id="default">
        <table class="table " >
              <thead>
              <tr class="border-bot-table">
                    {getTableCell()}
              </tr>
           </thead>
              <tbody class="body">
                    <tr class="border-bot-table">
                       {getTotalTableHeadrs()}
                    </tr>
                    {reportData}
              </tbody>       
           </table>
        </div>
 
    )
}

Table.defaultProps={
    reportData:['']   
}
export default Table;