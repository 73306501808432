"use strict";
import C from '../constants';
export  function setSearchBoxFilter({poNumber, isSet}) {
    return function(dispatch){
        dispatch({
            type:C.SET_SEARCH_BOX_FILTER,
            poNumber,
            isSet
        });
    }    
};

export  function setLevelSearchBoxFilter(levelSearch) {
    return function(dispatch){
        dispatch({
            type:C.SET_LEVEL_SEARCH_BOX_FILTER,
            levelSearch
        });
    }    
};


export  function showHideTopSearchBox(booleanValue) {
    return function(dispatch){
        dispatch({
            type:C.SHOW_HIDE_TOP_SEARCH_BOX,
            booleanValue
        });
    }    
};


