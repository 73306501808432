"use strict";
import moment from 'moment';


class CommonUtil{

    
    static dateFormat='DD/MMM/YYYY';
    static dateFormat2='YYYY-MM-DD HH:mm:ss';
    static hours='hours';

    constructor(){};
    static getDateWithFormat(date, hours){
        return moment(date).add(hours,'hours').format(CommonUtil.dateFormat);
    }
    static getDateFromTimeStamp(timeStamp, hours){
        return moment.unix(parseInt(timeStamp.toString().slice(0, -3))).utc().add(hours, CommonUtil.hours).format(CommonUtil.dateFormat);
    }
    static getBudget(amount){
        return (amount/1000000);
    }
    static isNumber(num){
        return Number(parseFloat(num)) === num;
    }
    static parseChartLabelDate(timeStamp){
        return moment.unix(parseInt(timeStamp.toString().slice(0, -3))).utc().add(1, CommonUtil.hours).format('DD MMM');
    }
    static minusHourFromDate(dateAndTime,numOfHours){
        
        return moment(dateAndTime).subtract(numOfHours, CommonUtil.hours).format(CommonUtil.dateFormat2);
    }

    static findLargest(data) {
        let largest;
        largest=Math.max.apply(null, data);
        return largest;
    }
    static findSmallest(data) {
        let smallest;
        smallest=Math.min.apply(null, data);
        return smallest;
    }

    static compareTwoDatesAreSame(date1, date2){
        return moment(date1).isSame(date2);
    }
    static commaSeparatedNumber(num){
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static numToMillion(value){
      try {

            if(isNaN(value)) return value;
            var thousand = 1000
              , million = 1000000;

            if (value < thousand) 
            return String(value);   
            if (value >= thousand && value <= 1000000) 
            return  (value/thousand).toFixed(1) + 'k';   
            return (value/million).toFixed(1) + 'M';   
            /* Enable below login to calculate number in trillion,trillion  
            , billion = 1000000000
              , trillion = 1000000000000;
            if (value >= billion && value <= trillion) {
            return (value/billion).toFixed(1) + 'B';   
            }
            else {
            return (value/trillion).toFixed(1) + 'T';   
            } */
       }
       catch(e){return value;}
    }

    static calculateResponsePer10K(clk, impr) {

        let metrics=0;
        try {metrics=(clk * 10000 / impr).toFixed(2);}catch(e){};
        return metrics=='NaN'?0:metrics;
    }

    static calculateCtr(clk, impr) {

        let totalCtr=0;
        try {totalCtr=((clk/impr)*100).toFixed(2);}catch(e){};
        return totalCtr=='NaN'?0:totalCtr;

        /* if(clk!=''&&clk!=0&&impr!=''&&impr!=0)
        return ((clk/impr)*100).toFixed(2);
        return 0; */
    }
    static reportFileName(filePrefix){
        return `${filePrefix}-${ moment(new Date()).format("DD-MMM-YYYY hh-mm-ss A")}.xlsx`;
    }
    
    static changeAdServerDisplayName(adServers) {

        try{
            return adServers.replace('GAM', 'Newsquest Display').replace('FB', 'FACEBOOK').replace("DBM", "Audience Extension");
        }
        catch(e){return adServers};
    }

    static toHHMMSS(min){

        var sec_num = parseFloat(min) * 60
          , hours   = Math.floor(sec_num / 3600)
          , minutes = Math.floor((sec_num - (hours * 3600)) / 60)
          , seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        try {
            seconds = seconds.toString().split('.')[0];
        }catch(e){}
        return hours+':'+minutes+':'+seconds;
    }

    static addNotBlankCondition(filters) {
       
        return[...filters, {
            "field" : "poNumber",
            "clause" : "MUST_NOT",
            "values" : [""]   
        }];
    }


}

export default CommonUtil;