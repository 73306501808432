"use strict";
export default {
    auth: {
        SSO_URL: 'https://sso-stage-new.jdxsuite.com',
        SSO_LOGIN: 'https://sso-stage-new.jdxsuite.com/login',
        SSO_VALIDATE_SESSION: 'https://sso-stage-new.jdxsuite.com/sso/validate',
    },
    cms: {
        METADATA: 'https://cms-stage-new.jdxsuite.com',
    },
    common: {
        CREATIVE_PREVIEW: 'https://atis-stage-new.jdxsuite.com/creative/preview?lineItemId='
    },
    reports: {
        ids: {
            DASHBOAD_START_END_DATE_ID: 84293,
            DASHBOAD_SERVE_DATE_ID: 84294,
            CAMPAIGN_START_END_DATE_ID: 88095,
            CAMPAIGN_SERVE_DATE_ID: 88096,
            BENCHMARKING_REPORT_ID: 84297,
            TOP_FILTER_METADATA_ID: 43915,
            HOLISTICS_SERVE_DATE_ID: 88751,
            CREATIVE_PERFORMANCE: 'CREATIVE_PERFORMANCE',
            METRICS_REPORT: 'METRICS_REPORT',
            NEON_DAILY_EXPORT: 'NEON_DAILY_EXPORT'
        },
        FILTER_URL: 'https://dfs-stage-new.jdxsuite.com/reports/dimensionValues',
        METADATA: 'https://rcs-stage-new.jdxsuite.com/api/report',
        SEARCH_BOX_FILTER: 'https://dfs-stage-new.jdxsuite.com/reports/autoSuggest',
        DFS_REPORT_MISC: 'https://dfs-stage-new.jdxsuite.com/reports/getMiscReport',
        DFS_REPORT: 'https://dfs-stage-new.jdxsuite.com/reports',
        BENCHMARKING: 'https://dfs-stage-new.jdxsuite.com/jda/benchmark',
        EXPORT: 'https://dfs-stage-new.jdxsuite.com/reports/downloadJdaReport',
        SEND_PDF: 'https://dfs-stage-new.jdxsuite.com/mail/sendMail',
        NEON_METADATA: 'https://dfs-stage-new.jdxsuite.com/api/report/ANALYTICS',
        NEON_TOP_SEARCH_BOX_URL: 'https://dfs-stage-new.jdxsuite.com/reports/autosuggest',
        SAVE_FILTER_URL_CMS: 'https://cms-stage-new.jdxsuite.com/filters/save/4',
        FETCH_FILTER_URL_CMS: 'https://cms-stage-new.jdxsuite.com/getAllFilters/corp/4',
        UPDATE_FILTER_URL_CMS: 'https://cms-stage-new.jdxsuite.com/filters/update/4',
        DELETEFILTER_URL_CMS: 'https://cms-stage-new.jdxsuite.com/filters/deleteByName/4'
    },
    index: {
        LINEITEM_AGGREGATE_METRICS: 'lineitem-aggregate-metrics',
        LINEITEM_SERVEDATE_METRICS: 'lineitem-servedate-metrics',
        ADVERTISERS: 'advertisers',
        CENTRALINDEX_DATA: 'centralindex-data',
    }
}; 
