import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

const SpeedoMeterChart = ({ chartDataPoint, chartLabel, chartDisplayMetrics, displayName, displayCount, displayValue }) => {

  return (
    <div class="boxContainer">
      <div class="headingtagWrap">
        <h2 class="left">{displayName}<span>{displayCount}</span></h2>
      </div>
      <div class="graphDetails">
        <div class="graph">
          <ReactSpeedometer
            maxValue={100}
            width={190}
            height={190}
            ringWidth={30}
            value={displayValue}
            needleColor="gray"
            segments={3}
            needleHeightRatio={0.7}
            segmentColors={["#dc3545", "#ffc107", "#198754",]}
          />
        </div>
        <div class="graphDetails">
          <ul>
            {/* {renderMetricsNames()} */}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default SpeedoMeterChart;