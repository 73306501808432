"use strict";
import C from '../constants';
export  function setWebsite(websiteFilter) {
    return function(dispatch){
        dispatch({
            type:C.SET_WEBSITE_FILTER,
            websiteFilter
        });
    }    
};

export  function reSetWebsite() {
    return function(dispatch){
        dispatch({
            type:C.RESET_WEBSITE_FILTER
        });
    }    
};