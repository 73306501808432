import React, { Component } from 'react';
import FilterUtil from '../../utils/FilterUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { setRegion, reSetRegion , setAllRegion } from '../../actions/filter/ReginFilterAction';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

class RegionFilter extends Component{

    constructor(props){
        super(props);
        this.state={
            isOpen:'',
            regionsData:[],
            regions:[],
            selectedRegion:[],
            isSelectAll:true,
            isClear:false,
            selectAllCheckBox:false
        };
        this.handleRegionShowHideClick=this.handleRegionShowHideClick.bind(this);
        this.selectRegionCheckBox=this.selectRegionCheckBox.bind(this);
        this.filterRegionByName=this.filterRegionByName.bind(this);
        this.getReginFromDfs=this.getReginFromDfs.bind(this);
        this.handleClickOutside=this.handleClickOutside.bind(this);
        this.props.reSetRegion();
        this.getReginFromDfs();
    }  

    handleClickOutside=(e)=>{
        if(!this.node.contains(e.target) && this.state.isOpen=='active') {
            this.setState({
                isOpen:''
            });
        }
    }
    getReginFromDfs=()=>{
        
        let sitesIds = JSON.parse(localStorage.getItem('siteId'));
        let siteNames = JSON.parse(localStorage.getItem('siteNames'));
        let len = sitesIds.length;
        let userSites = [];
        for(let i = 0; i < len; i++) {
            userSites.push({
                id:sitesIds[i],
                name:siteNames[i]
            });
        }
        this.setState({
            regionsData:userSites,
            regions:userSites
            },()=>{this.state.regions.sort(function(a, b){
            var nameA=a.name.toLowerCase(),  nameB=b.name.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1
            if (nameA > nameB)
                return 1
            return 0 //default return value (no sorting)
        })});
    }
    
    filterRegionByName=(e)=>{
        this.setState({
            regions:FilterUtil.filterByName(
                this.state.regionsData,
                e.target.value
            )
        });
    }

    handleClearAllClick=(e)=>{
        let regions=this.state.regions;
        let selectedRegion=[];
        let value=e.target.value;
        e.target.checked=true;
        for(let i in regions) {
            regions[i].isSelected= false;
        }
        this.state.selectedRegion=selectedRegion;
        this.setState({regions,isSelectAll:true,isClear:false,selectAllCheckBox:false});
        this.props.setRegion(selectedRegion);
    }

    handleSelectAllClick=()=>{
        let regions=this.state.regions;
        let selectedRegion=this.state.selectedRegion;
        for(let i in regions) {
            regions[i].isSelected= true;
        }
        for(let i in regions)
            if (regions[i].isSelected && !selectedRegion.includes(regions[i].id)) selectedRegion.push(regions[i].id);
        this.state.selectedRegion=selectedRegion;
        this.setState({regions,isClear:true,isSelectAll:false});
        this.props.setRegion(selectedRegion);
    }

    handleRegionShowHideClick=(e)=>{

        let className=e.target.className;
        if(className!='' && className!='active'
            && className.indexOf("clickable")==-1
            && className.indexOf("ps__thumb-x")==-1
            && className.indexOf("ps__thumb-y")==-1
            && className.indexOf("ps__rail-x")==-1
            && className.indexOf("ps__rail-y")==-1
            && className.indexOf("dropdown-contents stay active")==-1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-x")==-1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-y")==-1
            && className.indexOf("clearable")==-1
            && className.indexOf("dropdown-select")==-1){
            this.setState({
                isOpen:this.state.isOpen==''?'active':''
            });
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.regionFilter.length===JSON.parse(localStorage.getItem('siteId')).length &&
        newProps.regionFilter != this.props.regionFilter) {
            this.handleSelectAllClick();
        }
    }

    selectAllCheckBoxIndicator = () => {
        let regions=this.state.regions;
        for(let i in regions)
            regions[i].isSelected = true;
        this.setState({selectAllCheckBox: true});
    }

    selectRegionCheckBox=(e)=>{   
        let regions=this.state.regions;
        let selectedRegion=this.props.regionFilter;
        let value=Number(e.target.value);
        var checked = document.getElementById('indeterminate');
        for(let i in regions) {
            if(regions[i].id==value) {
                let index=selectedRegion.indexOf(value);
                if(index==-1)selectedRegion.push(value);
                else selectedRegion.splice(index, 1);
                regions[i].isSelected= !regions[i].isSelected;
                break;
            }
        }
        this.state.selectedRegion=selectedRegion;
        if (regions.length===selectedRegion.length) this.setState({selectAllCheckBox:true});
        else this.setState({selectAllCheckBox:false});
        this.setState({regions});
        if (checked) {
            if (selectedRegion.length > 0 && selectedRegion.length < regions.length) checked.indeterminate = true;
            else checked.indeterminate = false;
        }
        this.props.setRegion(selectedRegion);
    }

    componentWillMount=()=>{
        this.getReginFromDfs();
        document.addEventListener("mousedown", this.handleClickOutside,false);
        
    }
    componentDidMount() {
        this.props.setAllRegion(this.state.regions);
    }
    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside,false);
    }


    render(){

        let {isSelectAll,isClear,selectAllCheckBox} = this.state;
        return(
            
            <span ref={node=>this.node=node}>
            <span class="taskstatus" onClick={this.handleRegionShowHideClick}>
                <span class="order-by dropdown plainPop filterTrigger">
                    <a  class="hover-link"><span class="jdicon jdicon-filter"></span>Region<i class="material-icons">arrow_drop_down</i></a>
                    <div class={`dropdown-contents stay ${this.state.isOpen}`}>
                        <div class="filter-options">
                            <div class="dpSearch input-field clearable">
                                <i class="material-icons prefix">search</i>
                                <input 
                                    type="text" 
                                    placeholder="Search" 
                                    id="assetTypeSearch" 
                                    style={{"border-color": "rgba(0, 0, 0, 0.08)"}}
                                    onChange={this.filterRegionByName}
                                />
                            </div>
                        </div>
                        <ul class="dropdown-menu auto dropdown-select " id="media-sort">
                        <PerfectScrollbar>
                            <div className={"scroller"}>

                                {isClear &&
                                    <li className="subtaksRFStatus clickable">
                                        <label onChange={this.handleClearAllClick} className="checkboxWrap commonCheck">
                                            <input
                                                type="checkbox"
                                                className=""
                                                checked={true}
                                                // value={item.id}
                                            />
                                            <span>Clear All</span>
                                        </label>
                                    </li>
                                }

                                {isSelectAll &&
                                    <li className="subtaksRFStatus clickable">
                                        <label onChange={this.handleSelectAllClick} className="checkboxWrap commonCheck">
                                            <input
                                                id='indeterminate'
                                                checked={selectAllCheckBox}
                                                type="checkbox"
                                                className=""
                                                // value={item.id}
                                            />
                                            <span>Select All</span>
                                        </label>
                                    </li>
                                }

                                {
                                    this.state.regions.map(item=>{
                                        item.isSelected =false;
                                        if(this.props.regionFilter.indexOf(item.id) !== -1)  item.isSelected =true;
                                        return <li class="subtaksRFStatus clickable">
                                            <label onChange={this.selectRegionCheckBox} class="checkboxWrap commonCheck">
                                                <input
                                                    type="checkbox"
                                                    class=""
                                                    value={item.id}
                                                    checked={item.isSelected}
                                                />
                                                <span>{item.name}</span>
                                            </label>
                                        </li>
                                    }, this)
                                }
                            </div>
                            </PerfectScrollbar>
                        </ul>
                    </div>
                </span>
            </span>
            </span>
        )
    }
}

const mapStateToProps=state=>{
    return {
        sites:state.regionFilter.sites,
        regionFilter:state.regionFilter.regions
    };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    setRegion:setRegion,
    reSetRegion:reSetRegion,
    setAllRegion:setAllRegion
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RegionFilter);