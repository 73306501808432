"use strict";
import C from '../../actions/constants';
const initialState = {
    isValidToken:'isValid'
}
  export default function loginReducer(state=initialState, action) {
    switch(action.type) {
      case C.VALIDATE_TOKEN:
        return {
          ...state,
          isValidToken:action.isValidToken
      };
      default:
        return state;
    }
  };
  