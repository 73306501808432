"use strict";

export default {
    TEST: 'TEST',
    LOGIN: 'LOGIN',
    GET_CAMPAIGN_LIST: 'GET_CAMPAIGN_LIST',
    GET_CAMPAIGN: 'GET_CAMPAIGN',
    GET_NAVBAR_MENUS: 'GET_NAVBAR_MENUS',
    DATE_TYPE_FILTER: 'DATE_TYPE_FILTER',
    DATE_FILTER: 'DATE_FILTER',
    CAMPAIGN_INFO_METADATA: 'CAMPAIGN_INFO_METADATA',
    TOP_METRICS_COUNT: 'TOP_METRICS_COUNT',
    SET_TOTAL_CAMP_METRICS_PRELOADER: 'SET_TOTAL_CAMP_METRICS_PRELOADER',
    SET_ADSERRVER_FILTER: 'SET_ADSERRVER_FILTER',
    SET_ADSERRVER_FILTER_DROPDOWN: 'SET_ADSERRVER_FILTER_DROPDOWN',
    SET_ADVERTISER_FILTER: 'SET_ADVERTISER_FILTER',
    RESET_ADVERTISER_FILTER: 'RESET_ADVERTISER_FILTER',
    GET_REGION_FILTER_DATA: 'GET_REGION_FILTER_DATA',
    SET_REGION_FILTER: 'SET_REGION_FILTER',
    RESET_REGION_FILTER_DATA: 'RESET_REGION_FILTER_DATA',
    SET_WEBSITE_FILTER: 'SET_WEBSITE_FILTER',
    RESET_WEBSITE_FILTER: 'RESET_WEBSITE_FILTER',
    VALIDATE_TOKEN: 'VALIDATE_TOKEN',
    SET_SEARCH_BOX_FILTER: 'SET_SEARCH_BOX_FILTER',
    REMOVE_SEARCH_BOX_FILTER: 'REMOVE_SEARCH_BOX_FILTER',
    SET_BENCHMARK_LEVEL_FILTER: 'SET_BENCHMARK_LEVEL_FILTER',
    SET_LEVEL_SEARCH_BOX_FILTER: 'SET_LEVEL_SEARCH_BOX_FILTER',
    SHOW_HIDE_TOP_SEARCH_BOX: 'SHOW_HIDE_TOP_SEARCH_BOX',
    SET_AUTO_SUGGEST_ADVERTISER_FILTER: 'SET_AUTO_SUGGEST_ADVERTISER_FILTER',
    SET_TOP_FILTER_METADATA: 'GET_TOP_FILTER_METADATA',
    TOTAL_BENCHMARK_ADVERTISER_COUNT: 'TOTAL_BENCHMARK_ADVERTISER_COUNT',
    SET_ADVERTISER_LEVELS: 'SET_ADVERTISER_LEVELS',
    SET_CORP_PERMISSION: 'SET_CORP_PERMISSION',
    ACTIVE_INACTIVE_SPINNER: 'ACTIVE_INACTIVE_SPINNER',
    SET_ADVERTISERS_LIST: 'SET_ADVERTISERS_LIST',
    SET_DEFAULT_FILTERS_LIST: 'SET_DEFAULT_FILTERS_LIST',
    ADD_DEFAULT_FILTERS_LIST: 'ADD_DEFAULT_FILTERS_LIST',
    SET_SALES_REP: 'SET_SALES_REP',
    SET_ADVERTISER_FILTER_NAME: 'SET_ADVERTISER_FILTER_NAME',
    SET_All_REGION_FILTER: 'SET_All_REGION_FILTER',
    REMOVE_REGION: 'REMOVE_REGION',
    REMOVE_ADSERVER: 'REMOVE_ADSERVER',
    REMOVE_ADVERTISER: 'REMOVE_ADVERTISER',
    SET_UPDATE_FILTER: 'SET_UPDATE_FILTER',
    ADD_SET_ADVERTISER_FILTER_NAME: 'ADD_SET_ADVERTISER_FILTER_NAME',
    AUTO_ADV_FILTER_NAME: 'AUTO_ADV_FILTER_NAME',
    HOLISTIC_DATE_FILTER: 'HOLISTIC_DATE_FILTER',
    BENCHMARK_DATE_FILTER: 'BENCHMARK_DATE_FILTER',
};
