"use strict";
import axios from 'axios'; 
import config from '../config/config';

class AuthUtil {
    
    constructor(){};

    getCookieByKey = key => {
        var value = "; " + document.cookie
          , parts = value.split("; " + key + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }
    renderLoginPage() {
        window.location = config.auth.SSO_LOGIN + '?gotoUrl=' + window.location.href;
    }
    validateSessionCookie() {
        return this._userID == undefined || this._sessionID == undefined;
    }
    validateToken = async () => {
     return await axios({
        method: "POST",
        url: config.auth.SSO_VALIDATE_SESSION,
        data:this.getBody(),
        headers:this.getHeader()
      });
    } 
    getHeader(){
        return {'content-type':'application/json'};
    }
    getBody(){
        return {token:this._sessionID};
    }
    deleteCookie(name){
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.2adpro.com';
    }
    clearSession(){

        var token = this._sessionID;
        axios({
            method: "POST",
            url:config.auth.SSO_URL+"/sso/logout?token="+token,
            withCredentials: true,
            crossDomain: true
        })
        .finally( (response) => {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++){
                this.deleteCookie(cookies[i].split("=")[0]);
            }
            localStorage.clear();
            this.renderLoginPage();
        });

    }

}
export default AuthUtil;