import React, { Component } from 'react';
import AuthUtil from '../../utils/AuthUtil';
import { Link } from 'react-router-dom';
import SearchBoxFilter from '../filters/SearchBoxFilter';
import { connect } from 'react-redux';
import ExportPreloader from '../common/ExportPreloader';
import Cookies from '../Cookies';
/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/
const $ = window.$;

class TopNavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem("UserName"),
            isOpen: "",
            siteLogo: ""
        };
        this.logout = this.logout.bind(this);
        this.handleLeftDrawerOpenCloseClick = this.handleLeftDrawerOpenCloseClick.bind(this);
        this.handleUserInfoPanelClick = this.handleUserInfoPanelClick.bind(this);
    }
    handleLeftDrawerOpenCloseClick = () => {
        $('#sideNav').toggleClass('active');
        $('body').toggleClass('sideMenuOpen');
        $('.menu-link').toggleClass('change');
    }
    handleUserInfoPanelClick = () => {
        this.setState({
            isOpen: this.state.isOpen == '' ? 'active' : '',
            username: localStorage.getItem("UserName")
        });
    }

    componentWillMount() {
        this.setState({ isShowTopSearch: this.props.isShowTopSearch });
    }

    async componentDidMount() {
        let hostName = window.location.hostname.toLocaleLowerCase();
        hostName = hostName.split('.');
        var logoPath = '/assets/images/' + hostName[0] + '/logo.png';
        this.setState({
            siteLogo: logoPath
        })

    }
    addDefaultSrc(ev){
        ev.target.src = '/assets/images/logo.png'
    }
    componentWillReceiveProps(newProps) {
        this.setState({ isShowTopSearch: newProps.isShowTopSearch });
    }

    logout = () => {
        let authUtil = new AuthUtil();
        authUtil._sessionID = authUtil.getCookieByKey('_sessionID');
        authUtil.clearSession();
    }

    refresh = () => {
        window.location.reload();
    }
    render() {

        return (
            <nav className="nav-wrapper center">
                <ExportPreloader />
                <ul className="left mRight">
                    <li className="toggleMenu">
                        <a href="#" className="menu-link center-align" onClick={this.handleLeftDrawerOpenCloseClick}>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </a>
                    </li>
                    <li>
                        <div className="brand-logo" onClick={this.refresh}>
                            <Link exact to="/" className="left-align">
                                <img src={`${process.env.PUBLIC_URL}` + this.state.siteLogo} onError={this.addDefaultSrc} alt="logo" />
                            </Link>
                        </div>
                    </li>
                </ul>
                {this.state.isShowTopSearch ? <SearchBoxFilter /> : null}
                <ul className="right mRight navSideMenu">
                    <Cookies />
                    <li className="dropdown plainPop">
                        <a className="hover-link tooltipped profilemenutooltip" data-position="bottom">
                            <span className="tooltiptext">{this.state.username}</span>
                            <i className="jdicon jdicon-user-circle" onClick={this.handleUserInfoPanelClick}></i>
                        </a>
                        <div className={`profle-dropdown-contents dropdown-contents mt1 dropdown-right profileMenuWrap ${this.state.isOpen}`}>
                            <ul className="dropdown-menu profileMenu" style={{ "width": '0px', height: '96px' }}>
                                <span className="arrow-up"></span>
                                <li className="header"><a href="#"><i className="fas fa-user"></i>{this.state.username}</a></li>
                                <li onClick={this.logout} className="footer"><a className="btn btn-primary btn-small blueGradient">Logout</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>

            </nav>
        )
    }
}

const mapStateToProps = state => {
    return {
        isShowTopSearch: state.search.booleanValue
    }
}

export default connect(mapStateToProps)(TopNavBar);