"use strict";
import C from '../../actions/constants';
const initialState = {
    payload: [],
    isLoading: false,
    error: {}
  }
  export default function campaignReducer(state=initialState, action) {

    switch(action.type) {
      case C.GET_CAMPAIGN_LIST:
        return {
          ...state,
          payload:action.payload,
          isLoading: true
      };
      default:
        return state;
    }
  };
  