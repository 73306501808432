import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { memo } from 'react'

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const CampaignListTable = ({ rangeQueryData, tableDimensions, campaignList }) => {
    var field = 'startDate'
        , from = ''
        , to = '';
    if (rangeQueryData != undefined) {
        field = rangeQueryData.field;
        from = rangeQueryData.from;
        to = rangeQueryData.to;
    }

    const colFormatter = (cell, row) => {
        console.log(encodeURI(row), 'uri')
        return (
            <Link exact to={`/campaign-info/${encodeURIComponent(row.URN)}?advertiser=${encodeURIComponent(row.advertiser)}&startDate=${row.startDate}&endDate=${row.endDate}&region=${encodeURIComponent(row.region)}&adServers=${encodeURIComponent(row.adServer)}&dateTypeFilter=${row.dateTypeFilter}&field=${field}&from=${from}&to=${to}&advertiserId=${row.advertiserId}`} className="primarycolor subtaskid">{cell}</Link>
        )
    }
    const colADVmatter = (cell, row) => {
        console.log('rowrowrowrow',row)
        if (row['advertiser'] == 'NA') {
            return row['advertiser'];
        } else {
            return (
                <Link exact to={`/holistic-view?advertiser=${encodeURIComponent(row.advertiser)}&advertiserId=${row.advertiserId}&level3=${row.level3}`} className="primarycolor subtaskid">{cell}</Link>
            )
        }
    }
    const headerFormatter = (column) => {
        return <span class="sort-by">{column.text}</span>;
        //<img src={`${process.env.PUBLIC_URL}/assets/images/tooltipicon.svg`} height="12px" />
    };
    const headerTableDataMapping = {
        'URN': 'URN',
        'Region': 'region',
        'Advertiser': 'advertiser',
        'advertiserId': 'advertiserId',
        'Ad Server': 'adServerLogo',
        'Start Date': 'startDate',
        'End Date': 'endDate',
        'Impressions': 'impression',
        'Clicks': 'clicks',
        'CTR': 'ctr',
        'Total Amount': 'totalAmountSpent',
        'Response per 10K Impressions': 'responsePer10KImpressions',
        'ARR': 'responsePer10KImpressions',
        'Campaign Name': 'campaignName',
        'Localiq  Facebook  Audience Ext.': 'histrolicLogo'

    };

    const toolTipsMapping = {
        'URN': 'URN',
        'Region': 'Region is defined as the Team name in Google Ad Manager 360',
        'Advertiser': 'The Advertiser name is taken from the advertiser set up in Google Ad Manager 360',
        'Ad Server': 'Data from at least one of Google Ad Manager 360, Bid Manager and Facebook. They can individually be selected at Select Adserver drop down above',
        'Start Date': 'The earliest start date found for the line item across all ad servers',
        'End Date': 'The latest end date found for the line item across all ad servers',
        'Impressions': 'Aggregation of line item impressions across all ad servers delivered against the Run date or End date selected',
        'Clicks': 'Aggregation of line item clicks across all ad servers delivered against the Run date or End date selected',
        'CTR': 'Percentage of impressions resulting in clicks across all ad servers delivered against the Run date or End date selected',
        'Total Amount': 'Value for revenue assigned to the line item in Google Ad Manager 360 only',
        'Campaign Name': 'Campaign Name',
    };

    const getTeableColumns = () => {
        const columns = [];
        let tempObj = {};
        if (!tableDimensions.includes("advertiserId")) {
            tableDimensions.push("advertiserId");
        }
        for (let item of tableDimensions) {
            tempObj = {};
            tempObj.dataField = headerTableDataMapping[item];
            tempObj.text = item;
            tempObj.headerFormatter = headerFormatter;
            tempObj.headerTitle = () => { return toolTipsMapping[item] };
            tempObj.sort = true;
            if (item == 'Region')
                tempObj.title = true;
            if (item == 'Advertiser') {
                tempObj.title = true;
                tempObj.formatter = colADVmatter;
            }
            if (item == 'URN') {
                tempObj.formatter = colFormatter;
                tempObj.style = { paddingLeft: '15px', minWidth: '117px' }
            }
            if (item == 'Campaign Name') {
                tempObj.title = true;
                tempObj.formatter = colFormatter;
                tempObj.style = { paddingLeft: '15px', minWidth: '117px' }
            }
            if (item == 'advertiserId')
                tempObj.hidden = true;
            columns.push(tempObj);
        }

        return columns;
    }

    return (
        <BootstrapTable
            id="dashboardTable"
            keyField='URN'
            data={campaignList}
            columns={getTeableColumns()}
            headerClasses={'react-bootstrap-header-fixed'}
        />

    )
}

CampaignListTable.propsTypes = {
    field: PropTypes.string.isRequired
};

export default memo(CampaignListTable);