"use strict";
import C from '../constants';
export  function setAdServerFilter(adserverFilters) {
    return function(dispatch){
        if(adserverFilters == undefined) {
            //adserverFilters=['FB','GAM'];
            adserverFilters=[];
        }
        dispatch({
            type:C.SET_ADSERRVER_FILTER,
            adserverFilters
        });
    }    
};

export  function setAdServerFilterDropDown(dropDownData) {
    
    return function(dispatch){
        if(dropDownData == undefined) {
            dropDownData=[
                {name:'FB',isSelected:false},
                {name:'GAM',isSelected:false},
                {name:'DBM',isSelected:false},
            ];
        }
        dispatch({
            type:C.SET_ADSERRVER_FILTER_DROPDOWN,
            dropDownData
        });
    }    
};

export  function removeAdserver(payload) {
    return function(dispatch){
        dispatch({
            type:C.REMOVE_ADSERVER,
            payload
        });
    }    
};

