"use strict";
import C from '../../actions/constants';
const initialState = {
    adServers:[],
    dropDownData:[]
}
export default function dateFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.SET_ADSERRVER_FILTER:
        return {
          ...state,
          adServers:action.adserverFilters
      };
      case C.SET_ADSERRVER_FILTER_DROPDOWN: 
      return {
          ...state,
          dropDownData:action.dropDownData
      }
      case C.REMOVE_ADSERVER:
        let index = state.adServers.findIndex(f => f == action.payload);
        state.adServers.splice(index, 1);
        return {
          ...state,
          adServers: [...state.adServers]
  
        }

      default:
        return state;
    }
};