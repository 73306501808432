'use strict';

import config from '../../../config/config';

export const metrics=[{
       'name':'impressions',
       'displayName':'Impressions',
       'op':'SUM',
       'isUIField': true
    },{
         'name':'clicks',
         'displayName':'Clicks',
         'op':'SUM',
         'isUIField': true
    },{
       'name': 'ctr',
       'displayName': 'CTR',
       'op': 'DERIVED',
       'formula': {
           'name': 'RATIO',
           'constant': 100
       },
       'subMetrics': [{
               'name': 'clicks',
               'displayName': 'Clicks',
               'op': 'SUM'
           },{
               'name': 'impressions',
               'displayName': 'Impressions',
               'op': 'SUM'
           }
       ],
       'isUIField': true
   }];

 export const dimensions=[{
    'name':'adServer',
    'displayName':'adServer',
    'op':'GROUP_BY',
    'isUIField': true
 }];

 export const onSelectedFilterMetaData={
   Region:{
      'name':'region',
      'displayName':'Region Value',
      'op':'GROUP_BY',
      'lookUp': null,
      'isUIField':false
  },
  regionId:{
   'name': 'region',
   'displayName': 'Region',
   'op': 'DERIVED',
   'subOp' : 'LOOK_UP',
   'subMetrics': [{
       'name': 'region',
       'displayName': 'Region Value'
   }],
   'lookUp': {
       'index': 'region',
       'queryField': 'sr_site_id',
       'lookupField': 'sr_name'
   },
   'isUIField':true
  },
   advertiserId: {
      'name': 'advertiserId',
      'displayName': 'Advertiser Id',
      'op': 'GROUP_BY',
      'lookUp': null,
      'subMetrics': null,
      'isUIField':false
   },
   Advertiser:{
      'name': 'advertiserId',
      'displayName': 'Advertiser',
      'op': 'DERIVED',
      'subOp' : 'LOOK_UP',
      'subMetrics': [{
      'name': 'advertiserId',
      'displayName': 'Advertiser Id'
      }],
      'lookUp': {
          'index': config.index.ADVERTISERS,
          'queryField': 'id',
          'lookupField': 'name'
      },
      'isUIField':true
  },
   website:{
      'name':'website',
      'displayName':'website',
      'op':'GROUP_BY',
      'isUIField':true
   } 
 };