"use strict";
import C from '../../actions/constants';
const initialState = {
    payload: {},
    totalCampaignMetaData:{},
    dfpLineItemMetaData:{},
    isLoading: false,
    error: {}
  }
  export default function campaignMetaDataReducer(state=initialState, action) {

    switch(action.type) {
      case C.CAMPAIGN_INFO_METADATA:
        return {
          ...state,
          detailsMetaData:action.payload['Details'],
          totalCampaignMetaData:action.payload['Campaign Totals'],
          dfpLineItemMetaData:action.payload['DFP line items'],
          fbLineItemMetaData:action.payload['FB line items'],
          dbmLineItemMetaData:action.payload['DBM line items'],
          isLoading: true
      };
      default:
        return state;
    }
  };
  