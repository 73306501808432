import React, { PureComponent } from 'react';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import CommonUtil from '../../../../utils/CommonUtil';
import ProductList from '../../products/productList';
import CreativePreview from '../../../modelPopup/CreativePreview';
const dfpLogo = `${process.env.PUBLIC_URL}/assets/images/dfplogo.png`;

const rowClass = (row, rowIndex) => {
    let classes = null;
    if (rowIndex === 0) {
        classes = 'index-bigger-than-two';
    }
    return classes;
};
class DfpLineItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            expanded: [],
            isExpand: false,
            selectedLineID: null,
            productName:null,
            metricsData: {}
        }
    }

    componentDidMount() {
        
        this.composeTableHeader();
    }

    componentWillReceiveProps(nextProps) {
    
        if (nextProps.data != this.props.data && !_.isEmpty(nextProps.data)) {
            this.arrangeMetricsData(nextProps.data);
        }
    }

    arrangeMetricsData = (data) => {
    
        let firstObj = {
            "CTR": `${CommonUtil.calculateCtr(data.tClicks, data.tImpression)}%`,
            "Response per 10K Impressions":CommonUtil.calculateResponsePer10K(data.tClicks, data.tImpression),
            "Clicks": CommonUtil.commaSeparatedNumber(data.tClicks),
            "Impressions": CommonUtil.commaSeparatedNumber(data.tImpression),
            "Line Item Id": 0,
            "Rate": `£${data.tRate}`,
            "Total Budget": data.tAmount,
            "Total Viewed": data.tATV,
            "Line Item Type": "",
            "Units": "12500",
            "Start Date": "",
            "Line item name": "Total",
            "End Date": "",
            "Sales Rep.": "",
            "Business Type": null
        }
        this.setState({
            tableData: [firstObj, ...data.lineItems]
        })
    };
    headerFormatter = (column) => {
        return <span class="sort-byd removeArrowForPdf">{column.text}&nbsp;&nbsp;&nbsp;&nbsp;</span>;
    };
    handleSort = (columnName) => {
        const { tableData } = this.state;
        let firstObj = _.head(tableData);
        let newData = _.drop(tableData);
        newData.sort(function (a, b) {
            var nameA = a[columnName].toLowerCase(), nameB = b[columnName].toLowerCase()
            if (nameA < nameB) //sort string ascending
                return -1
            if (nameA > nameB)
                return 1
            return 0 //default return value (no sorting)
        })
        let newTableData = [firstObj, ...newData];
        this.setState({
            tableData: newTableData
        })
    }

    composeTableHeader = () => {
        let columns = [{
            dataField: 'Line item name',
            text: 'Line item name',
            title: true,
            //sort: true,
            sortFunc: (a, b, order, dataField) => {
                if (order === 'asc') {
                    return b - a;
                }
                return a - b; // desc
            },
            headerEvents: {
                onClick: () => { this.handleSort('Line item name') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter:this.headerFormatter
        },
        {
            dataField: 'Product Type',
            text: 'Product',
            title: true,
            headerEvents: {
                onClick: () => { this.handleSort('Product') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },
        {
            dataField: 'Start Date',
            text: 'Start date',
            headerEvents: {
                onClick: () => { this.handleSort('Start Date') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },
        {
            dataField: 'End Date',
            text: 'End date',
            headerEvents: {
                onClick: () => { this.handleSort('End Date') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },
        {
            dataField: 'Rate',
            text: 'Rate',
            headerEvents: {
                onClick: () => { this.handleSort('Rate') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        }, {
            dataField: 'Impressions',
            text: 'Impressions',
            headerEvents: {
                onClick: () => { this.handleSort('Impressions') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter:this.headerFormatter,
            title: true
        }, {
            dataField: 'Clicks',
            text: 'Clicks',
            headerEvents: {
                onClick: () => { this.handleSort('Clicks') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        }, {
            dataField: 'CTR',
            text: 'CTR',
            headerEvents: {
                onClick: () => { this.handleSort('CTR') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },{
            dataField: 'Response per 10K Impressions',
            text: 'Response per 10K Impressions',
            headerEvents: {
                onClick: () => { this.handleSort('Response per 10K Impressions') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },{
            dataField: 'Total Viewed',
            text: 'Time viewed (hrs)',
            headerEvents: {
                onClick: () => { this.handleSort('Total Viewed') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        },
        {
            dataField: 'Line Item Type',
            text: 'Line item type',
            // events: {
            //     onClick: (e, column, columnIndex, row, rowIndex) => {
            //         this.handleDelete(e, row);
            //     }
            // },
            headerEvents: {
                onClick: () => { this.handleSort('Line Item Type') }
            },
            headerClasses: 'demo-row-odd',
            headerFormatter: this.headerFormatter
        }
        ];
        this.setState({
            columns
        });
    }

    handleOnExpand = () => {
        const { selectedLineID, isExpand } = this.state;
        if (isExpand) {
            this.setState(() => ({
                expanded: [selectedLineID]
            }));
        } else {
            this.setState(() => ({
                expanded: []
            }));
        }
    }


    richMediaMetricsAPI = (lineId,canExpand) => {
        const {productName}=this.state;
       /*
        * Test id  
        * lineId=5072988129;
        * let productName='AdProduct Essential';
        * let localUrl=`http://localhost:1448/rich-media-metrics/${lineId}/${productName}`; 
        * */

        if(canExpand)return;
        axios({
            method: 'get',
            url: `https://neonws.2adpro.com/rich-media-metrics/${lineId}/${productName}`,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            let res = result.data;
            this.setState({
                metricsData: res.resultAsObject
            }, () => {
                //if (!_.isEmpty(res.resultAsObject)) {
                    this.setState({                        
                        selectedLineID: null
                    })
                    this.handleOnExpand()
               // }
            })
        });

    }

    callMetricsDataAPI = (row, isExpand, rowIndex, e) => {
     
        /**
         * e.target.className === '' then dont expand row else exapnd row
         *  */ 
        let canExpand=row['Product Type'] == 'NA' || ProductList.indexOf(row['Product Type'])<0 || e.target.className === '';
        if (row['Line Item Id'] != 0) {
            if (isExpand) {
                this.setState({
                    selectedLineID: row['Line Item Id'],
                    productName: row['Product Type'],
                    isExpand,
                }, () => {
                    this.richMediaMetricsAPI(this.state.selectedLineID,canExpand);
                })
            } else {
                this.setState(() => ({
                    expanded: [],
                    selectedLineID: null
                }));
            }
        } else {
            this.setState(() => ({
                expanded: [],
                selectedLineID: null
            }));
        }
        //}
    }

    renderInnerTableView=(productName,metricsData)=>{

        const tableHeaderMapping={
            'call':'CALL',
            'clickToWebsite':'CLICK TO WEBSITE',
            'directions':'DIRECTIONS',
            'multiClickEmail':'EMAIL',
            'clickToSocialMedia':'CLICK TO SOCIAL MEDIA',
            'viewableImpressions':'VIEWABLE IMPRESSIONS',
            'interactions':'INTERACTIONS',
            'uniqueVisitors':'UNIQUE VISITORS',
            'dwelledNextGen':'DWELL TIME',
            'viewedNextGen':'VIEWABLE IMPRESSIONS',
            'interactedImprNextGen':'INTERACTIONS',
            'dwellTime':'DWELL TIME'
        };

        let tableHead = [];
        let tableContent = [];
        _.forOwn(metricsData, function (value, key) {
            tableHead.push(<th style={{'fontSize':'.8rem', 'color':'#9e9e9e'}} >{_.isNil(tableHeaderMapping[key])?key:tableHeaderMapping[key]}</th>);
            tableContent.push(<td>{value}</td>)
        });
        return <React.Fragment>
        <h4 style={{ color:'#666', fontWeight:'bold', marginLeft: '12px', fontSize: '15px'}}>AD PRODUCT INFO - {productName.toUpperCase()}</h4>
        <table>
            <tr>
                {tableHead}
            </tr>
            <tr>
                {tableContent}
            </tr>
        </table>
    </React.Fragment>;
    }

    renderInnerTable = () => {
        const { expanded, metricsData, selectedLineID,productName } = this.state;
        let data = [];
            let productNameCond=productName == 'AdProduct Essential' || productName == 'Essential Adproduct';
            if(productNameCond && !_.isEmpty(metricsData)){
                data.push(this.renderInnerTableView(productName, metricsData));
            } else if(!productNameCond && !_.isNil(metricsData.interactions) && !_.isNil(metricsData.uniqueVisitors)
            && !_.isNil(metricsData.viewableImpressions) && !_.isNil(metricsData.dwellTime)) {
                data.push(this.renderInnerTableView(productName, metricsData));
        } else {
            data.push(<div style={{ textAlign: 'center' }}>Data not available</div>);
        }

        return {
            onlyOneExpanding: true,
            renderer: row => {
               return <div style={{ 'transition': 'height 1s' }}>
                    {data}
                </div>
            },
            expanded: expanded,
            onExpand: this.callMetricsDataAPI,
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return '';
                }
                return '';
            },
            expandColumnRenderer: ({ expanded, rowKey}) => {

               const { tableData } = this.state;
               let serachedProductName=tableData.filter(row=>{
                    return row['Line Item Id']==rowKey;
               });
                if(serachedProductName.length>0){
                    if(serachedProductName[0]['Product Type'] == 'NA' || ProductList.indexOf(serachedProductName[0]['Product Type'])<0)
                    return rowKey==0?null:<CreativePreview  lineId={rowKey} />;
                    if (expanded) {
                        return <><CreativePreview  lineId={rowKey} /> <i class="jdicon-up"></i></>
                    }
                    return <><CreativePreview  lineId={rowKey} /><i class="jdicon-down"></i></>
                    
                } return <CreativePreview  lineId={rowKey} />;
            },
            nonExpandable: [0, selectedLineID]
        };
    }

    //scrollTable
    render() {
        const { tableData, columns } = this.state;
        const lineItemLength=tableData.length;
        return (
            <>
                <div class="countTitle">
                <h3 style={{'width':270}}><img width='13%' src={dfpLogo} alt="dfp logo"/> Newsquest Display<span>{lineItemLength==0?lineItemLength:lineItemLength-1}</span></h3>
                </div>
                    {!_.isEmpty(columns) &&
                    <div className="infocontainer tableBorder" style={{'display':'block', 'overflow-x':'auto', 'white-space':'nowrap'}}>
                        <BootstrapTable
                            styles={{'height':'300px'}}
                            keyField="Line Item Id"
                            data={tableData}
                            columns={columns}
                            expandRow={this.renderInnerTable()}
                            //wrapperClasses={wrapperClass}
                            headerClasses='thHeader'
                            rowClasses={rowClass}
                        //rowStyle={ firstRowStyle }
                        />
                    </div>
                    }
            </>

        )
    }
}

export default (DfpLineItem);