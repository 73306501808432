"use strict";
import C from '../../actions/constants';
const initialState = {
    poNumber:'',
    levelSearch:'',
    isSet:false,
    booleanValue:false
}
export default function dateFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.SET_SEARCH_BOX_FILTER:
        return {
          ...state,
          poNumber:action.poNumber,
          isSet:action.isSet
      };
      case C.SET_LEVEL_SEARCH_BOX_FILTER:
        return {
          ...state,
          levelSearch:action.levelSearch
      };
      case C.SHOW_HIDE_TOP_SEARCH_BOX:
      return{
          ...state,
          booleanValue:action.booleanValue
      }
      default:
        return state;
    }
};