import React, { Component } from 'react';
import DataFetchService from '../../service/DataFetchService';
import ActivityIndicator from '../common/ActivityIndicator';
import FilterUtil from '../../utils/FilterUtil';
import CommonUtil from '../../utils/CommonUtil';
import {connect} from "react-redux";

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const styles = {
    "margin-left": "0px",
    "margin-top": "3px"
    
};

class CampaignsMetricsCount extends Component {

    constructor(props){
        super(props);
        this.state={
            count:0,
            isLoading:true
        };
        this.getCount = this.getCount.bind(this);
        this.setServiceParams=this.setServiceParams.bind(this);
    }

    getCount=(dataFetchService)=>{
        if (this.props.adServerFilter.length < 2) {
            dataFetchService.fethData()
                .then(response => {
                    let data = response.data;
                    let count = 0;
                    if (data.resultAsMap != null) {
                        if (dataFetchService.displayName == 'CTR')
                            count = `${data.resultAsMap[dataFetchService.metricName].toFixed(2)}%`;
                        else {
                            count = CommonUtil.numToMillion(data.resultAsMap[dataFetchService.metricName]);
                            if (count.toString().indexOf('M') == -1) count = CommonUtil.commaSeparatedNumber(data.resultAsMap[dataFetchService.metricName]);
                        }
                    }
                    this.setState({isLoading: false, count});
                }).catch(error => {
                console.log("Exception:", error);
            });
        } else {
            let count = dataFetchService.data.val;
            this.setState({isLoading: false, count});
        }
    }
    
    componentWillReceiveProps(newProps){
        if (this.props.adServerFilter.length < 2) {
            this.getCount(this.setServiceParams(newProps));
        } else {
            this.getCount(newProps);
        }
    }

    setServiceParams=(propsParams)=>{
        let dataFetchService = new DataFetchService();
        var metric;
        dataFetchService.displayName=propsParams.metaData.displayName;
        if(propsParams.metaData.displayName=='CTR') {
            try{
            dataFetchService.metricName=propsParams.metaData.metrics[3].displayName;
            }
            catch(e){
                dataFetchService.metricName=propsParams.metaData.metrics[0].displayName;
            }
            metric = propsParams.metaData.metrics;
            dataFetchService.metricObj=metric;
        }
        else {
            dataFetchService.metricName=propsParams.metaData.metrics[0].displayName;
            metric={
                name:propsParams.metaData.metrics[0].name,
                displayName:propsParams.metaData.metrics[0].displayName,
                op:propsParams.metaData.metrics[0].op
            };
            dataFetchService.metricObj=[metric];
        }

        dataFetchService.indexPrefix = propsParams.metaData.indexPrefix;
        let rangeQueryData = FilterUtil.getDateRangeQuery(propsParams.filters);
        dataFetchService.operationType = propsParams.metaData.operationType;
        dataFetchService.filters = FilterUtil.getFilterObj(FilterUtil.setAllSitesToFilter(propsParams.filters));
        dataFetchService.filters = CommonUtil.addNotBlankCondition(dataFetchService.filters);

        dataFetchService.rangeQueryData=rangeQueryData;
        return dataFetchService;
    }
    componentWillMount(){
        if (this.props.adServerFilter.length < 2) {
            this.getCount(this.setServiceParams(this.props));
        } else {
            this.getCount(this.props);
        }
    }
    render(){

        return(
            <li class={`col s12 m6 l3 ${this.props.metaData.chartStyle.className}` }>
                <dl>
                    <dt>{this.props.metaData.displayName}</dt>
                    { this.state.isLoading?<ActivityIndicator
                        style={styles}
                        height="20"
                        width="20"
                        indicating={true}
                    />
                        :<dd className="cardNumber">{this.state.count}</dd>
                    }

                </dl>
            </li>    
        )
    }   
}

const mapStateToProps = state => {
    return {
        adServerFilter:state.adServerFilter.adServers
    }
}
export default connect(mapStateToProps,null)(CampaignsMetricsCount);