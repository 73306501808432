import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserUtil from '../../../utils/UserUtil';
import CommonUtil from '../../../utils/CommonUtil';
import Filters from '../../filters';
import Table from './Table';
import ActivityIndicator from '../../common/ActivityIndicator';
import DataFetchService from '../../../service/DataFetchService';
import FilterUtil from '../../../utils/FilterUtil';
import ReportUtil from '../../../utils/ReportUtil';
import Result from '../../common/Result';
import { metrics, dimensions } from './MetaData';
import config from '../../../config/config';
import { activateAndInActivateSpinner } from '../../../actions/spinner/coverSpinner';

/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-07-04
*/

const styles = {
   "margin-left": "48%",
   'position': 'fixed',
   'bottom': '2%',
   'left': '-25px',
   'margin-top': '45px'
};


class CampiagnReport extends Component {

   constructor(props) {
      super(props);
      this.state = {
         metaData: [],
         totalRecordCount: 0,
         filters: {},
         indicator: true,
         reportContainer: 'reportContainer',
         filterConfig: {
            dateType: true,
            date: true,
            region: true,
            adServer: true,
            advertiser: true,
            webSite: true,
            saleRep: false,
            SetDefaultFilters: false,
            saveFilter: false,
            submitFilter: true,
            exportFilter: true,
         },
         isLoading: false,
         size: 10,
         dateType: '',
         compositeFilter: {},
         tableCell: [],
         operationType: 'COMPOSITE_GROUP_BY',
         metrics: metrics,
         dimensions: dimensions,
         dataFetchService: {},
         reportData: [],
         displayName: 'Campaign Report'
      };

      this.renderReport = this.renderReport.bind(this);
      this.setOnloadFilter = this.setOnloadFilter.bind(this);
      this.setServiceObj = this.setServiceObj.bind(this);
      this.getReportData = this.getReportData.bind(this);
      this.handleScrollToLoadData = this.handleScrollToLoadData.bind(this);
      this.setDyanamicTableHeaders = this.setDyanamicTableHeaders.bind(this);
      this.setSelectedFilterDimenstions = this.setSelectedFilterDimenstions.bind(this);
      this.handleExportReportClick = this.handleExportReportClick.bind(this);
   }

   handleExportReportClick = () => {
      this.props.activateAndInActivateSpinner(true);
      this.setServiceObj();
      const { dataFetchService } = this.state;
      dataFetchService.url = `${config.reports.EXPORT}/Report`;
      dataFetchService.responseType = 'blob';
      dataFetchService.size = 10000;
      dataFetchService.fethData()
         .then(response => {
            ReportUtil.exportReport(
               CommonUtil.reportFileName('ConsolidatedReport'),
               response.data
            )
            this.props.activateAndInActivateSpinner(false);
         }).catch(err => { this.props.activateAndInActivateSpinner(false); console.log('Exception occured in bench-mark report export', err) });
   }

   setOnloadFilter = () => {

      let index = this.state.reportData.length;
      if (index > 0) {
         let lastRow = this.state.reportData[index - 1];
         let compositeFilter = {};
         if (lastRow.hasOwnProperty('adServer'))
            compositeFilter.adServer = lastRow.adServer;
         if (lastRow.hasOwnProperty('website'))
            compositeFilter.website = lastRow.website;
         if (lastRow.hasOwnProperty('Advertiser Id'))
            compositeFilter.advertiserId = lastRow['Advertiser Id'];
         if (lastRow.hasOwnProperty('Region Value'))
            compositeFilter.region = lastRow['Region Value'];
         this.state.compositeFilter = compositeFilter;
      }
   }

   setDyanamicTableHeaders = () => {
      let fixedDimesntions = ['adServer', 'Region Value', 'Region', 'Advertiser Id', 'Advertiser', 'website'];
      let dimensions = this.state.dimensions;
      let arrangedDimestions = [];
      for (let index in fixedDimesntions) {
         for (let index2 in dimensions) {
            if (fixedDimesntions[index] == dimensions[index2].displayName) {
               arrangedDimestions.push(dimensions[index2]);
            }
         }
      }
      this.state.dimensions = arrangedDimestions;
   }
   setSelectedFilterDimenstions = () => {
      let fixedDimesntions = {
         'Region': 'regionFilter',
         'Advertiser': 'advertiserFilter',
         'website': 'websiteFilter'
      };
      var dimensions = this.state.dimensions;
      for (let key in fixedDimesntions) {
         dimensions = ReportUtil.addAndRemoveDimenstions(
            dimensions, this.props[fixedDimesntions[key]].length, key
         );
         if (dimensions == undefined) continue;
         this.state.dimensions = dimensions;
      }
   }
   setServiceObj = () => {

      let filtersObj={};
      let filters = FilterUtil.setFilterObj(filtersObj);
      this.setSelectedFilterDimenstions();
      this.setDyanamicTableHeaders();
      let dataFetchService = new DataFetchService();
      const { dimensions, metrics, operationType } = this.state;
      let { size } = this.state;
      if (window.innerHeight >= 640) size = 16;
      let dimensionsMetaData = dimensions;
      let metricsMetaData = metrics;
      let indexPrefix = config.index.LINEITEM_AGGREGATE_METRICS;
      this.state.tableCell = ReportUtil.setTableCell(dimensionsMetaData, metricsMetaData);
      if (this.props.dateTypeFilter == 'Run Date') indexPrefix = config.index.LINEITEM_SERVEDATE_METRICS;
      filters = FilterUtil.setFilterObj(this.props);
      dataFetchService.indexPrefix = indexPrefix;
      dataFetchService.size = size;
      dataFetchService.isTotalCount = true;
      dataFetchService.rangeQueryData = FilterUtil.getDateRangeQuery(filters);
      dataFetchService.dimensions = dimensionsMetaData;
      dataFetchService.metricObj = metricsMetaData;
      dataFetchService.operationType = operationType;
      dataFetchService.filters = FilterUtil.getFilterObj(FilterUtil.setAllSitesToFilter(filters));
      this.state.dataFetchService = dataFetchService;
   }

   getReportData = (isLoadMore = false) => {

      const { dataFetchService, compositeFilter } = this.state;
      if (isLoadMore && Object.keys(this.state.compositeFilter).length > 0) {
         dataFetchService.compositeFilter = compositeFilter;
      }
      dataFetchService.fethData()
         .then(res => {
            if (res.hasOwnProperty('data') && res.data.hasOwnProperty('resultAsList') && res.data.resultAsList.length > 0) {
               let reportData = res.data.resultAsList;

               if (isLoadMore) {
                  let oldData = this.state.reportData;
                  reportData = oldData.concat(reportData);
                  this.setState({
                     reportData,
                     indicator: false,
                     isLoading: true
                  });
               } else {

                  this.setState({
                     reportData,
                     tableCell: this.state.tableCell,
                     indicator: false,
                     totalRecordCount: res.data.totalRecordCount,
                     isLoading: false
                  });
               }
            }
            else {
               if (isLoadMore) this.setState({ indicator: false, isLoading: true });
               else this.setState({
                  reportData: [],
                  tableCell: [],
                  indicator: false,
                  isLoading: false
               });
               console.log("No Report Found!");
            }

         }).catch(err => { console.log("err", err); });
   }
   renderReport = () => {
      this.setServiceObj();
      this.getReportData();
   }

   componentWillReceiveProps(newProps) {
      this.state.dateType = newProps.dateTypeFilter;
   }

   componentWillMount() {
      setTimeout(() => {
         let userUtil = new UserUtil();
         //userUtil.getUserDetails();
         this.renderReport();
      }, 1000);
   }
   handleFilterClick = () => {
      this.setState({ indicator: true, reportData: [] });
      this.renderReport();
      if (this.state.tableCell.length == 4) {
         this.setState({ reportContainer: "reportContainer" });
      } else this.setState({ reportContainer: "report-Container" });
   }

   componentDidMount() {
      this.refs.iScroll.addEventListener("scroll", () => {
         if (Math.ceil(this.refs.iScroll.scrollTop) + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight) {
            this.handleScrollToLoadData();
         }
      });
   }
   handleScrollToLoadData = () => {

      //if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      const { indicator, reportData, totalRecordCount } = this.state;
      if (!indicator && reportData.length != totalRecordCount) {
         this.setOnloadFilter();
         this.setState({ indicator: true });
         this.getReportData(true);
      }
   }
   /* componentDidMount() {
      document.addEventListener('scroll', this.handleScrollToLoadData);
   }
   componentWillUnmount(){
      document.removeEventListener("scroll", this.handleScrollToLoadData,false);
   } */
   renderResultMessage = () => {
      const {
         reportData,
         indicator,
         totalRecordCount,
         isLoading
      } = this.state;
      // this.setState({reportContainer : "reportContainer"})
      if (isLoading && reportData.length == totalRecordCount)
         return <Result message={`Showing ${totalRecordCount} of ${totalRecordCount}`} />;
      if (!indicator && reportData.length == 0)
         return <Result message='Sorry, we do not find any data for the selected filters' />;
   }

   renderDisplayMessage = () => {
      let { dateType, indicator, isLoading } = this.state;
      return (
         <div className="messageContainer">
            {dateType === 'Start Date' && indicator === false &&
               <h6 className="displayText">Reporting is based on line items started in the time period chosen</h6>}
            {dateType === 'End Date' && indicator === false &&
               <h6 className="displayText">Reporting is based on line items ended in the time period chosen</h6>}
            {dateType === 'Run Date' && indicator === false &&
               <h6 className="displayText">Reporting is based on line items that are/were active in the time period chosen</h6>}
         </div>
      )
   }

   render() {
      const {
         reportData,
         tableCell,
         indicator,
         filterConfig,
         totalRecordCount,
         displayName
      } = this.state;
      return (
         <>
            <div class="jobdetails rhs" ref="iScroll" style={{ height: "calc(100vh - 20px)", overflow: "auto" }}>
               <div class="content-header scroll-animate ">
                  <div class="jobno">
                     <span class="inline-block header">{displayName}</span>
                  </div>
                  <div class="content-header rhs filters-container">
                     <Filters filterConfig={filterConfig} handleFilterClick={this.handleFilterClick}
                        handleExportReportClick={this.handleExportReportClick}
                     />
                  </div>
               </div>
               {this.renderDisplayMessage()}
               <div className={this.state.reportContainer}>
                  <Table
                     reportData={reportData}
                     tableCell={tableCell}
                     handleScroll={this.handleScroll}
                     totalRecordCount={totalRecordCount}
                  />
                  {this.renderResultMessage()}
               </div>
               <ActivityIndicator style={styles} height="40" width="40" indicating={indicator} />
            </div>
         </>
      )
   }
}

const mapStateToProps = state => {
   return {
      dateTypeFilter: state.dateType.dateFilterType,
      dateFilter: state.dateFilter,
      adServerFilter: state.adServerFilter.adServers,
      advertiserFilter: state.advertiser.advertiserFilter,
      regionFilter: state.regionFilter.regions,
      websiteFilter: state.websiteFilter.websites
   }
}
const mapDispatchToProps = dispatch => bindActionCreators({
   activateAndInActivateSpinner: activateAndInActivateSpinner
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampiagnReport);