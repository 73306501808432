"use strict";
import C from '../constants';
import ReportingConfigService from '../../service/ReportingConfigService';
import config from '../../config/config';

export  function setTopFilterMetaData() {
    return function(dispatch){

       
        let reportingConfigService = new ReportingConfigService();
        reportingConfigService.id=config.reports.ids.TOP_FILTER_METADATA_ID;
        reportingConfigService.fetchMetaDataConfig()
        .then(response=>{
           if(response!=undefined && response.hasOwnProperty("data") && response.data[0]!=undefined) {
                let data = response.data;
                var  payload = {};
                if(data.length>0 && data[0].hasOwnProperty("charts")) {
                    let reports = data[0].charts;
                    for(let i in reports) {
                        payload[reports[i].displayName]=reports[i];
                    }
                }
                dispatch({
                    type:C.SET_TOP_FILTER_METADATA,
                    payload
                });
           }
           else {
              //trigger toast message
              console.log("No meta data found!");
           }
        }).catch(error=>{ console.log("error", error);});

    }    
};

