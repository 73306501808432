import { savePDF } from '@progress/kendo-react-pdf';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';

class DocService {
  createPdf = (html,trackingUrn) => {
    savePDF(html, {
      paperSize: 'A2',
      fileName: trackingUrn
            })
  }

  sendPdf = (html,trackingUrn) => {
    return drawDOM(html, {
      paperSize: 'A2',
      fileName: trackingUrn
    }).then(group=>{
    return exportPDF(group)}).then(data=>{
      return {data,trackingUrn};
    })
  }
}

const Doc = new DocService();
export default Doc;