"use strict"

import C from '../constants';

export  function setDateTypeFilter(dateFilterType) {
    return function(dispatch){
        dispatch({
            type:C.DATE_TYPE_FILTER,
            dateFilterType
        });
    }    
};
