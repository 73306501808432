import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiTooltip from "@material-ui/core/Tooltip";


const styles = {
    tooltip: {
        margin:0,
        backgroundColor:'rgba(0,0,0,0.87)',
        boarderRadious:'10px'
    }
};
const muStyles = {
    tooltip: {
       margin:0,
       maxWidth:'1000px',
       borderRadius: '1px',
       fontSize:'12px',
       backgroundColor:'rgba(0,0,0,0.87)'
    }
};


const Tooltip = withStyles(muStyles)(MuiTooltip);
function MyCustomTooltip({children, title}) {
    return (
        <Tooltip title={title} > 
             {children}
        </Tooltip>
   );
}

export default withStyles(styles)(MyCustomTooltip);