"use strict";
import CommonUtil from './CommonUtil';
class FilterUtil {

    constructor(){};

    static filterByName=(data, q)=>{
        var tempData=data;
        if(q!="") {
            tempData=data.filter(item=>{
                return item.name.toLowerCase().indexOf(q.toLowerCase())>=0;
            });
        }
        return tempData;
    }

    static getDateRangeQuery=(filters)=>{
        if(filters.hasOwnProperty('search') && filters.search!='' && filters.search!=undefined
        && FilterUtil.validateSearchBox(filters.search)) {return [];};
        let dateRangeObj={};
        dateRangeObj.field=FilterUtil.getDateTypeFilter(filters.dateTypeFilter);
        dateRangeObj.from=`${filters.date.fromDate} 00:00:00`;
        dateRangeObj.to=`${filters.date.toDate} 00:00:00`;
        if(CommonUtil.compareTwoDatesAreSame(filters.date.fromDate, filters.date.toDate))
        dateRangeObj.to=`${filters.date.toDate} 23:59:59`;
        if(filters.dateTypeFilter=='Run Date') {
            dateRangeObj.from=filters.date.fromDate;
            dateRangeObj.to=filters.date.toDate;
        }
        else {
            //minus one hour from date
            dateRangeObj.from = CommonUtil.minusHourFromDate(dateRangeObj.from,2).replace('00:00','59:59');
            dateRangeObj.to = CommonUtil.minusHourFromDate(dateRangeObj.to,1).replace('00:00','59:59');
        }
        dateRangeObj.includeFrom=true;
        dateRangeObj.includeTo=true;
        return [dateRangeObj];
    }

    static validateSearchBox(value) {
        return value.toString().indexOf('-')==-1;
    }

    static getDateTypeFilter=(dateType)=>{
        let dateTypeData={
            'Start Date':'startDate',
            'End Date':'endDate',
            'Run Date':'serveDate'
        };
        return dateTypeData[dateType];
    }

    static getDateMapping=(dateType)=>{
        let dateTypeData={
            'startDate':'Start Date',
            'endDate':'End Date',
            'serveDate':'Run Date'
        };
        return dateTypeData[dateType];
    }

    static setAllSitesToFilter = (filters) => {
        
        if(filters.hasOwnProperty('regions') && filters.regions.length>0) console.log('sssss');
        else {
            if (localStorage.getItem('defaultSite') != "null")
                filters.regions = [JSON.parse(localStorage.getItem('defaultSite'))];
        }
        return filters;

    }

    static setAllSitesToFilterForSearch = (filters) => {
        if(filters.hasOwnProperty('regions') && filters.regions.length>0) console.log('sssss');
        else {
            if (localStorage.getItem('defaultSite') != "null")
                filters.regions = JSON.parse(localStorage.getItem('siteId'));
        }
        return filters;

    }

    static getFilterObj=(filterObj)=>{
       
        let filterArray=[];
        if(filterObj.hasOwnProperty('search') && 
        filterObj.search!='' && filterObj.search!=undefined && FilterUtil.validateSearchBox(filterObj.search)) 
            return [{'field':'poNumber','values':[filterObj.search]}];
        let data={
            'adServers':'adServer',
            'regions':'region',
            'advertiserFilter':'advertiserId',
            'websites':'website',
            'salesRepFilter':'salesRep.keyword'
        };
        for(let key in filterObj){
            if(filterObj[key]==undefined || data[key]==undefined) continue;
            if(filterObj[key].length==0)continue;
            filterArray.push({field:data[key],values:filterObj[key]});
        }
        return  filterArray;
    }
    static setFilterObj(filterObj) {
        let filters={};
         filters.dateTypeFilter=filterObj.dateTypeFilter;
         filters.date=filterObj.dateFilter;
         filters.adServers=filterObj.adServerFilter;
         filters.regions=filterObj.regionFilter;
         filters.advertiserFilter=filterObj.advertiserFilter;
         filters.websites=filterObj.websiteFilter;
         filters.search=filterObj.search;
         filters.salesRepFilter=filterObj.selectedSalesRep;
         return filters;
    }

    static getFilterMap(level){
        let levelMap={
            'Level 1':'level1',
            'Level 2':'level2',
            'Level 3':'level3'
        };
        return levelMap[level.trim()];
    }
    static setBenchMarkFilter(filters){
        let filterArray=[];
         if(filters.hasOwnProperty('levelSearch') || filters.hasOwnProperty('advertiser')){
            const {levelSearch,advertiser,level}=filters;
            if(filters.hasOwnProperty('levelSearch') && filters.levelSearch!=''){
                filterArray.push({field:FilterUtil.getFilterMap(level),values:[levelSearch.trim()]});
            }
            if(filters.hasOwnProperty('advertiser') && filters.advertiser!=''){
                if(advertiser!='')filterArray.push({field:'advertiserId',values:[advertiser]});     
            }
            //if(regions.length>0) filterArray.push({field:'region',values:regions});
             if (localStorage.getItem('siteId') != "null"){
                 filterArray.push({field:'region',values:JSON.parse(localStorage.getItem('siteId'))});
             }
        }
        return filterArray;
    }

static getServerDateFilter(dateFilter){

    return [{
        field:'serveDate',
        from:dateFilter.fromDate,
        to:dateFilter.toDate,
        includeFrom: true,
        includeTo: true
    }];

}

}

export default FilterUtil;

