import React from 'react';
import { Line, Chart } from 'react-chartjs-2';
import CommonUtil from '../../utils/CommonUtil';

Chart.defaults.scale.gridLines.display = true;
const styles = {
  fontFamily: 'sans-serif',
};
const DrawChart = ({ chartDataPoint, chartLabel, chartDisplayMetrics, displayName }) => {
  const getChartData = () => {
    let datasets = []
    let obj = {};
    // Chart.defaults.global.defaultFontFamily = "Roboto, sans-serif";
    console.log('familyfamilyfamily',Chart.defaults.global.defaultFontFamily);
    for (var index in chartDataPoint) {
      if (index == 1 || index == 3) {
        obj = {}
        obj.data = chartDataPoint[index];
        if (chartDisplayMetrics[index].label)
          obj.label = chartDisplayMetrics[index].label;
        obj.lineTension = 0;
        obj.borderWidth = 2;
        obj.fill = false;
        obj.pointStyle= "cross";
        obj.rotation= 45;
        obj.radius= 6;
        obj.borderColor = chartDisplayMetrics[index].color;
        obj.backgroundColor = chartDisplayMetrics[index].color;
        obj.yAxisID = 'y-axis-1';
        datasets.push(obj);
      } else if (index == 0 || index == 2) {
        obj = {}
        obj.data = chartDataPoint[index];
        if (chartDisplayMetrics[index].label)
          obj.label = chartDisplayMetrics[index].label;
        obj.lineTension = 0;
        obj.borderWidth = 2;
        obj.pointStyle= "";
        obj.radius= 4;
        obj.fill = false;
        obj.borderColor = chartDisplayMetrics[index].color;
        obj.backgroundColor = chartDisplayMetrics[index].color;
        obj.yAxisID = 'y-axis-2';
        datasets.push(obj);
      }
    }
    return {
      labels: chartLabel,
      datasets: datasets
    };
  }

  const getChartOption = () => {
    var chartOptions = {}
    chartOptions.spanGaps = true;
    chartOptions.maintainAspectRatio = false;
    chartOptions.legend = {
      display: true,
      position: 'top',
      align: 'right',
      fullWidth: false,
      callback: function (label, index, labels) {
        return CommonUtil.numToMillion(label)
      }
    };
    chartOptions.scales = {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-1',
          point: {
            pointStyle : 'cross',
          },
          gridLines: {
            drawOnArea: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
          },
          callback: function (label, index, labels) {
            return CommonUtil.numToMillion(label)
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false,
            drawBorder: false,
            display: false
          },
          ticks: {
            beginAtZero: true,
          },
          callback: function (label, index, labels) {
            return CommonUtil.numToMillion(label)
          }
        },

      ]
    }
    return chartOptions;
  }

  const renderMetricsNames = () => {

    let display = [];
    let classes = chartDisplayMetrics.length == 2 ? ['dot3', 'dot1'] : ['dot2', 'dot3', 'dot1'];
    for (let index in chartDisplayMetrics) {
      display.push(<li><span className={classes[index]}></span>{chartDisplayMetrics[index].label}</li>);
    }
    return display;
  }


  return (
    <div style={styles}>
      <div class="boxContainer">
        {/* <div class="headingtagWrap">
        <h2 class="left">{displayName}</h2>
      </div> */}
        <div class="graphDetails">
          <div class="graph">
            <Line
              data={getChartData()}
              height={250}
              width={100}
              options={getChartOption()}
            />
          </div>
          <div class="graphDetails">
            <ul>
              {renderMetricsNames()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawChart;