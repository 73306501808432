"use strict";
import C from '../../actions/constants';
const initialState = {
    advertiserFilter: [],
    autoSuggestAdvertiser: '',
    totalBenchMarkAdvertiserCount: 0,
    advertiserDropDownList: [],
    autoSuggestAdvertiserName: '',
    autoAdvertiserFilterName: []
}

export default function advertiserFilterReducer(state = initialState, action) {
    switch (action.type) {
        case C.SET_ADVERTISER_FILTER:
            return {
                ...state,
                advertiserFilter: action.advertiserFilter
            };
        case C.RESET_ADVERTISER_FILTER:
            return {
                ...state,
                advertiserFilter: []
            }
        case C.SET_AUTO_SUGGEST_ADVERTISER_FILTER:
            return {
                ...state,
                autoSuggestAdvertiser: action.data.autoSuggestAdvertiserId,
                autoSuggestAdvertiserName: action.data.autoSuggestAdvertiserName,
            }
        case C.TOTAL_BENCHMARK_ADVERTISER_COUNT:
            return {
                ...state,
                totalBenchMarkAdvertiserCount: action.totalBenchMarkAdvertiserCount
            }
        case C.SET_ADVERTISER_FILTER_NAME:
            return {
                ...state,
                advertiserDropDownList: action.advertiserList
            };
        case C.ADD_SET_ADVERTISER_FILTER_NAME:
            return {
                ...state,
                advertiserDropDownList: [...state.advertiserDropDownList, action.advertiserList]
            };
        case C.REMOVE_ADVERTISER:
            let index = state.advertiserFilter.findIndex(f => f == action.payload);
            state.advertiserFilter.splice(index, 1);
            return {
                ...state,
                advertiserFilter: [...state.advertiserFilter]

            }
        case C.AUTO_ADV_FILTER_NAME:
            return {
                ...state,
                autoAdvertiserFilterName: action.advName
            };
        default:
            return state;
    }
};