import React, { Component } from 'react';
import FilterUtil from '../../utils/FilterUtil';
import { connect } from 'react-redux';
import { setAdvertiser, reSetAdvertiser, setAdvertiserList, addSetAdvertiserList, autoAdvFilterName } from '../../actions/filter/AdvertiserFilterAction';
import { bindActionCreators } from 'redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FilterService from '../../service/FilterService';
import axios from 'axios';
import config from '../../config/config';
import JSONBigInt from 'json-bigint';
import _ from "lodash";

class AdvertiserFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: '',
            initialAdvertiserData: [],
            advertisers: [],
            selectedAdvertisers: [],
            isClearAllFlag: false,
            advertiserFilter: this.props.advertiserFilter
        };
        this.handleAdvertiserShowHideClick = this.handleAdvertiserShowHideClick.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleAdvertiserFilterNameChange = this.handleAdvertiserFilterNameChange.bind(this);
        this.getAdvertiserFromDfs = this.getAdvertiserFromDfs.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        //this.getAdvertiserFilterMetaData=this.getAdvertiserFilterMetaData.bind(this);
        this.cleanUpAdvertiserData = this.cleanUpAdvertiserData.bind(this);
        this.props.reSetAdvertiser();
        //this.getAdvertiserFilterMetaData(this.props.topFilterMetaData);
        this.getAdvertiserFromDfs();
    }

    handleClickOutside = (e) => {
        if (!this.node.contains(e.target) && this.state.isOpen == 'active') {
            this.setState({
                isOpen: ''
            });
        }
    }

    cleanUpAdvertiserData = (advertisers, id, name) => {
        const flagvalue = '';
        var advertisersList = [];
        for (var index in advertisers) {

            advertisersList.push({ id: advertisers[index][id], name: advertisers[index][id], isSelected: false });
        }

        return this.removeDuplicateAdvertisers(advertisersList);
    }
    removeDuplicateAdvertisers = (advertisersList) =>{
        var lookup = {};
        var items = advertisersList;
        var result = [];
        for (var item, i = 0; item = items[i++];) {
        var name = item.name;
            if (!(name in lookup)) {
                lookup[name] = 1;
                result.push(item);
            }
            else{
                console.log("dup Advertiser {}", item );
            }
        }
        console.log("dedup Advertiser  count {}"+ JSON.stringify(result).toString());
        return result;
    }

    getAdvertiserFromDfs = () => {

        let metaData = {
            "dimensions": [{
                'name': 'name',
                'displayName': 'name',
                'op': 'SELECT',
                'lookUp': {
                    'index': config.index.ADVERTISERS,
                    'queryField': 'name',
                    'lookupField': 'id'
                }
            }
            ],
            'indexPrefix': config.index.ADVERTISERS
        };
        let filterService = new FilterService();
        filterService.metaData = metaData;
        filterService.size = 100;
        filterService.fetchTopFilterData()
            .then(response => {
                if (response.hasOwnProperty('data') && response.data.length > 0) {
                    var advertisersList = this.cleanUpAdvertiserData(response.data[0].values, 'key', 'value');

                    advertisersList.sort(function (a, b) {
                        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                        if (nameA < nameB) //sort string ascending
                            return -1
                        if (nameA > nameB)
                            return 1
                        return 0 //default return value (no sorting)
                    })

                    this.setState({
                        initialAdvertiserData: this.removeDuplicateAdvertisers(advertisersList),
                        advertisers: advertisersList
                    });
                    this.props.setAdvertiserList(advertisersList);
                }
            }).catch(error => { console.log("Exception:", error); });

    }

    handleAdvertiserFilterNameChange = (e) => {

        let searchKey = e.target.value.trim();
        let url = `${config.reports.SEARCH_BOX_FILTER}?text=${searchKey}&field=name.text`;
        let postObj = {};
        postObj.method = 'POST';
        postObj.url = url;
        postObj.crossDomain = true;
        postObj.withCredentials = true;
        postObj.data = {
            indexPrefix: config.index.ADVERTISERS,
            "dimensions": [{ "name": "id", "displayName": "id" }],
            "metrics": [{ "name": "name", "displayName": "name" }]
        };
        postObj.headers = { corp: localStorage.getItem('CorpId'), 'content-type': 'application/json' };
        postObj.transformResponse = [data => data];
        axios(postObj)
            .then(res => {
                let advertisersList = this.cleanUpAdvertiserData(JSONBigInt.parse(res.data), 'name', 'id');
                this.setState({
                    advertisers: this.removeDuplicateAdvertisers(advertisersList)
                });
                advertisersList.map(item => {
                    this.props.addSetAdvertiserList(item);
                })
                if (searchKey == "") {
                    this.setState({
                        advertisers: this.state.initialAdvertiserData
                    });
                }
            }).catch(error => { console.log("error", error) });

    }

    handleAdvertiserShowHideClick = (e) => {

        let className = e.target.className;
        if (className != '' && className != 'active'
            && className.indexOf("clickable") == -1
            && className.indexOf("ps__thumb-x") == -1
            && className.indexOf("ps__thumb-y") == -1
            && className.indexOf("ps__rail-x") == -1
            && className.indexOf("ps__rail-y") == -1
            && className.indexOf("dropdown-contents stay active") == -1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-x") == -1
            && className.indexOf("scrollbar-container ps ps--active-x ps--active-y ps--scrolling-y") == -1
            && className.indexOf("clearable") == -1
            && className.indexOf("dropdown-select") == -1) {
            this.setState({
                isOpen: this.state.isOpen == '' ? 'active' : ''
            });
        }
    }

    handleClearAllClick = (e) => {
        let advertisers = this.state.advertisers;
        let selectedAdvertisers = [];
        e.target.checked = true;
        for (let i in advertisers) {
            advertisers[i].isSelected = false;
        }
        this.state.selectedAdvertisers = selectedAdvertisers;
        this.setState({ advertisers, isClearAllFlag: false });
        this.props.setAdvertiser(selectedAdvertisers)
    }

    handleCheckBoxClick = (e) => {
        let advertisers = this.state.advertisers;
        let selectedAdvertisers = this.props.responseValue;
        let setAutoAdvFilterName = this.props.getAutoAdvFilterName;
        let value = e.target.value;
        for (let i in advertisers) {
            if (advertisers[i].id == value) {
                let index = selectedAdvertisers.indexOf(value);
                if (index == -1) {
                    selectedAdvertisers.push(value);
                    setAutoAdvFilterName[value] = advertisers[i]['name'];
                }
                else {
                    selectedAdvertisers.splice(index, 1);
                    delete setAutoAdvFilterName[value];
                }
                advertisers[i].isSelected = !advertisers[i].isSelected;
                break;

            }
        }
        this.state.selectedAdvertisers = selectedAdvertisers;
        selectedAdvertisers.length > 0 ? this.setState({ isClearAllFlag: true }) : this.setState({ isClearAllFlag: false });
        this.setState({ advertisers });
        this.props.setAdvertiser(selectedAdvertisers);
        this.props.autoAdvFilterName(setAutoAdvFilterName);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.advertiserFilter != this.props.advertiserFilter) {
            this.setState({
                advertiserFilter: nextProps.advertiserFilter
            }, () => {
                this.handleFilterChange();
            })
        }

    }

    componentWillMount = () => {
        document.addEventListener("mousedown", this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside, false);
    }

    /* getAdvertiserFilterMetaData=(props)=>{
        const { isTopFilterDataAvail }=props;
        if(isTopFilterDataAvail){
            const { advertiser }=props;
            this.getAdvertiserFromDfs(advertiser);
        }
    }
   */
    /* componentWillReceiveProps(newProps){
        this.getAdvertiserFilterMetaData(newProps.topFilterMetaData);
    } */

    render() {
        return (

            <span ref={node => this.node = node}>
                <span class="taskstatus" onClick={this.handleAdvertiserShowHideClick}>
                    <span class="order-by dropdown plainPop filterTrigger">
                        <a class="hover-link"><span class="jdicon jdicon-filter"></span>Advertiser<i class="material-icons">arrow_drop_down</i></a>
                        <div class={`dropdown-contents stay ${this.state.isOpen}`}>
                            <div class="filter-options">
                                <div class="dpSearch input-field clearable">
                                    <i class="material-icons prefix">search</i>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        id="assetTypeSearch"
                                        style={{ "border-color": "rgba(0, 0, 0, 0.08)" }}
                                        onChange={this.handleAdvertiserFilterNameChange}
                                    />
                                </div>
                            </div>
                            <ul class="dropdown-menu auto dropdown-select " id="media-sort">
                                <PerfectScrollbar>
                                    <div className={"scroller"}>
                                        {
                                            <li className="subtaksRFStatus clickable">
                                                {this.state.isClearAllFlag && <a href="javascript:void(0);" onClick={this.handleClearAllClick} class='clearAll'><u>Clear All</u></a>}
                                                {!this.state.isClearAllFlag && <h8 className="clearAllDisable">Clear All</h8>}
                                            </li>
                                        }
                                        {
                                            this.state.advertisers.map(item => {
                                                item.isSelected = false;
                                                if (this.props.responseValue.indexOf(item.id.toString()) !== -1) item.isSelected = true;
                                                return <li class="subtaksRFStatus clickable">
                                                    <label onChange={this.handleCheckBoxClick} class="checkboxWrap commonCheck">
                                                        <input
                                                            type="checkbox"
                                                            class=""
                                                            value={item.name}
                                                            checked={item.isSelected}
                                                        />
                                                        <span>{item.name}</span>
                                                    </label>
                                                </li>
                                            }, this)
                                        }
                                    </div>
                                </PerfectScrollbar>
                            </ul>
                        </div>
                    </span>
                </span>
            </span>
        )
    }
}

const mapStateToProps = state => {
    return {
        responseValue: state.advertiser.advertiserFilter,
        getAdvertiserList: state.advertiser.advertiserDropDownList,
        getAutoAdvFilterName: state.advertiser.autoAdvertiserFilterName,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAdvertiser: setAdvertiser,
    reSetAdvertiser: reSetAdvertiser,
    setAdvertiserList: setAdvertiserList,
    addSetAdvertiserList: addSetAdvertiserList,
    autoAdvFilterName: autoAdvFilterName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserFilter);