"use strict";
import C from '../../actions/constants';
const initialState = {
    websites:[]
}
export default function regionFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.SET_WEBSITE_FILTER:
        return {
          ...state,
          websites:action.websiteFilter
      };
      case C.RESET_WEBSITE_FILTER:
      return {
          ...state,
          websites:[]
      }
      default:
        return state;
    }
};