import React from "react";

const Help=()=>{
    const styles= {
        marginLeft:50,
        marginTop:58
    }
    const iframe=`<iframe class="iframeHeight" src=${process.env.PUBLIC_URL}/assets/help/html/Overview.html width='100%' height='600'></iframe>`;
    return <div style={styles} dangerouslySetInnerHTML={{ __html: iframe}} />
}

export default Help;