import React from 'react';
import DataFetchService from '../../service/DataFetchService';
import { connect } from 'react-redux';
import CommonUtil from '../../utils/CommonUtil';
import DfpLineItem from './tables/DfpLineItem/DfpLineItem';
import _ from 'lodash';

class Dfp extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            lineItems: [],
            indicator: true,
            tRate: 0,
            tImpression: 0,
            tClicks: 0,
            tAmount: 0,
            tATV: 0
        };
        this.renderData = this.renderData.bind(this);
    }

    renderData = (metaData) => {

        let { trackingNo, rangeQueryData } = this.props;
        var filters = metaData.filters[0];
        filters.values = [];
        filters.values.push(trackingNo);
        let modifiedFilter = [filters];
        modifiedFilter.push({field:'adServer', values:['GAM']});
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.dimensions = metaData.dimensions;
        dataFetchService.size = 100;
        dataFetchService.rangeQueryData = rangeQueryData;
        dataFetchService.metricObj = metaData.metrics;
        dataFetchService.operationType = metaData.operationType;
        dataFetchService.filters = modifiedFilter;
        dataFetchService.fethData()
            .then(response => {
                var data = response.data.resultAsList
                    , tRate = 0
                    , tImpression = 0
                    , tClicks = 0
                    , tAmount = 0
                    , tATV = 0;
                for (var i in data) {
                    tRate += _.isNil(data[i]['Rate'])?0:data[i]['Rate'];
                    tImpression += _.isNil(data[i]['Impressions'])?0:data[i]['Impressions'];
                    tClicks += _.isNil(data[i]['Clicks'])?0:data[i]['Clicks'];
                    tAmount += CommonUtil.getBudget(_.isNil(data[i]['Total Budget'])?0:data[i]['Total Budget']);
                    tATV += _.isNil(data[i]['Total Viewed'])?0:data[i]['Total Viewed'];
                    data[i]['CTR']=`${data[i]['CTR']}%`;
                    data[i]['Product Type'] =_.isNil(data[i]['Product Type'])?'NA':data[i]['Product Type'];   
                    data[i]['Rate']=`£${data[i]['Rate']}`;
                    data[i]['Impressions'] = CommonUtil.commaSeparatedNumber(data[i]['Impressions']);
                    data[i]['Clicks'] = CommonUtil.commaSeparatedNumber(data[i]['Clicks']);
                    data[i]['Total Viewed']= data[i]['Total Viewed'];
                    data[i]['Start Date']= CommonUtil.getDateWithFormat(data[i]['Start Date'],1);
                    data[i]['End Date']= CommonUtil.getDateWithFormat(data[i]['End Date'],0);
                }
                this.setState({
                    lineItems:data,
                    indicator:false,
                    tRate,
                    tImpression,
                    tClicks,
                    tAmount,
                    tATV
                });
            }).catch(error => { console.log("Exception:", error); });
    }

    componentWillReceiveProps(newprops) {
        if (newprops.dfpLineItemMetaData != this.props.dfpLineItemMetaData) {
            this.renderData(newprops.dfpLineItemMetaData);
        }
    }

    render() {
        return (
            <DfpLineItem
                data={this.state}                
            />
        )
    }

}
const mapStateToProps = state => {
    return {
        dfpLineItemMetaData: state.campaignInfoMetaData.dfpLineItemMetaData
    }
}
export default connect(mapStateToProps)(Dfp);