"use strict";
import C from '../../actions/constants';
const initialState = {
    menus: [],
    isLoading: false,
    error: {}
  }
  export default function navBarReducer(state=initialState, action) {

    switch(action.type) {
      case C.GET_NAVBAR_MENUS:
        return {
          ...state,
          menus:action.menus,
          isLoading: true
      };
      default:
        return state;
    }
  };
  