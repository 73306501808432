"use strict";
import C from '../constants';

export function activateAndInActivateSpinner(isActive){
    return function(dispatch){
        dispatch({
            type:C.ACTIVE_INACTIVE_SPINNER,
            isActive
        });
    }
};