"use strict";
import axios from 'axios';
import config from '../config/config';
import AuthUtil from '../utils/AuthUtil';
import _ from 'lodash';

axios.interceptors.response.use((response) => {
    if(response.hasOwnProperty('status') && response.status==220){
        let authUtil= new AuthUtil();
        authUtil.renderLoginPage();
    }
    return response;
    }
);

class DataFetchService {

    fethData=()=>{
        return axios(this.getPostObject());
    }

    fetchDataSynch= async ()=>{
        return await axios(this.getPostObject());
    }

    fetchPostObject= ()=>{
        return this.getPostObject();
    }

    fetchLastUpdatedDate = () => {
        return axios.get(config.reports.DFS_REPORT + '/getLastReportQueriedDate', {
            params: {
                corpId: localStorage.getItem('CorpId')
            },
            withCredentials: true
        })
    }

    getHeader=()=>{
        return  {
           corp:localStorage.getItem('CorpId')  ,
          'content-type': 'application/json' 
        };
    }
    getPostObject=()=>{
        let postObj = {};
        postObj.method="POST";
        postObj.url=this.getUrl();
        postObj.data=this.getBody();
        postObj.crossDomain=true;
        postObj.withCredentials=true;
        postObj.headers=this.getHeader();
        if(!_.isNil(this.responseType))postObj.responseType=this.responseType;
        return postObj;
    }
    getBody=()=>{

        let obj = {};
        obj.reportType="JD_METRICS";
        obj.offset=0;
        if (this.bucketSelectorConfig !== 'undefined') {
            obj.bucketSelectorConfig = this.bucketSelectorConfig;
            console.log('ssss', this.bucketSelectorConfig);
        }

        if(this.size!=undefined)obj.size=this.size;
        obj.filters=[];
        if(Array.isArray(this.filters))
        obj.filters=this.filters;
        if(this.compositeFilter!=undefined)
        obj.compositeFilter=this.compositeFilter;
        obj.rangeQueryData=this.rangeQueryData==undefined?[]:this.rangeQueryData;
        obj.queryType=this.operationType;
        obj.fieldsForCountValues=[];
        obj.metrics=this.metricObj;
        if(this.isTotalCount!=undefined)obj.isTotalCount=true;
        if(this.dimensions!=undefined)
        obj.dimensions=this.dimensions;

        obj.indexPrefix = this.getIndexPrefix(obj.rangeQueryData)
        return obj;
    }

    getUrl = () => this.url == undefined ? config.reports.DFS_REPORT_MISC : this.url;


    getIndexPrefix = (dateRange) =>{
        if (dateRange[0] == undefined) return this.indexPrefix;
        let dateRangeIndex = this.rangeQueryData[0];
        if (dateRangeIndex['field'] == 'startDate' || dateRangeIndex['field'] == 'endDate') return config.index.LINEITEM_AGGREGATE_METRICS;

        return this.indexPrefix;
    }
}

export default DataFetchService;