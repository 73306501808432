import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Filters from "../filters";
import MultiChart from "./../chartjs/MultiChart";
import CentralMultiChart from "./../chartjs/CentralMultiChart";
import CustomisedDrawChart from "../chartjs/CustomisedDrawChart";
import FilterService from "../../service/FilterService";
import styles from "./HolisticStyle";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { isEmpty, forOwn } from "lodash";
import ReportingConfigService from "../../service/ReportingConfigService";
import config from "../../config/config";
import CampaignListing from "../../../app/components/dashboard/CampaignListing";
import html2canvas from "html2canvas";
import SpeedoMeterChart from "../chartjs/SpeedoMeterChart";
import { setHolisticFilterDate } from "../../actions/filter/DateFilterAction";
import CommonUtil from "../../utils/CommonUtil";
import jsPdf from "jspdf";

class Holistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterConfig: {
        dateType: false,
        date: true,
        region: false,
        adServer: false,
        advertiser: false,
        webSite: false,
        saleRep: false,
        SetDefaultFilters: false,
        saveFilter: false,
        exportFilter: true,
        submitFilter: true,
      },
      filters: [],
      chartDataObj: {},
      centralchartDataObj: {},
      particularADVImpr: [],
      particularADVClick: [],
      overAllAdvImpr: [],
      overAllAdvClicks: [],
      chartLabel: [],
      chartDisplayMetrics: [
        { label: "Total Views", color: "#8FD16A" },
        { label: "Total Action", color: "#009BFF" },
        { label: "Avg. Peer Views", color: "#bfff00" },
        { label: "Avg. Peer Actions", color: "#00bfff" },
      ],
      centralChartDisplayMetrics: [
        { label: "Search Views", color: "#fec239" },
        { label: "Profile Views", color: "#5a8240" },
        { label: "Website Views", color: "#1296ee" },
      ],
      uniqueAdvCount: 1,
      chartDataObjImpression: {},
      metaData: [],
      advertiserId: "",
      level3: 0,
      sum_month_impr: "",
      sum_month_click: "",
      particularADVCtr: [],
      ctrtotalparticular: 0,
      timeViewed: [],
      totaltimeViewed: 0,
      setdateredux: false,
      claimed: false,
      advertiserName: "",
      claimedListing: [],
      claimedListingName: "No",
      claimedListingStatus: false,
      serpsCentral: [],
      bdpCentral: [],
      websiteCentral: [],
      claimedClassName: "claimed",
      totalAction: [],
      totalImp: [],
    };
  }
  async componentDidMount() {
    var locationPath = window.location.pathname;
    var arrVars = locationPath.split("/");
    if (arrVars[1] == "pdfdownload") {
      window.close();
    }
    await this.setReduxdate();
    await this.loadDataholistic();
  }
  async loadDataholistic() {
    const query = decodeURIComponent(this.props.location.search);
    let advertiserid =  query.split("&advertiserId=")[1].split("&level3=")[0]; //query.get("advertiserId");
    let advertisername =  query.split("&adver")[0].replace("?advertiser=", ""); //query.get("advertiser");
    localStorage.setItem("holisticAdvertiser", [advertisername]);
    // const query = new URLSearchParams(this.props.location.search);
    // let advertiserid = query.get("advertiserId");
    // const advertisername = query.get("advertiser");
    advertiserid = advertiserid.split(",");
    this.setState({
      advertiserId: advertiserid,
      advertiserName: advertisername,
    });
    await this.getAdvitiserFilter();
    await this.getAdvCount();
    await this.getdatafromCentralIndex();
    await this.getdatafromDFS("MUST");
    if (this.state.level3 != 0) {
      await this.getdatafromDFS("MUST_NOT");
    }

    // chartDataObj.displayName = this.state.displayName;

    var centralchart_array = [];
    centralchart_array.push(this.state.serpsCentral);
    centralchart_array.push(this.state.bdpCentral);
    centralchart_array.push(this.state.websiteCentral);

    var centralchartDataObj = {};
    centralchartDataObj.chartDataPoint = centralchart_array;
    centralchartDataObj.chartLabel = this.state.chartLabel;
    centralchartDataObj.chartDisplayMetrics =
      this.state.centralChartDisplayMetrics;

    //combine central inex and config.index.LINEITEM_SERVEDATE_METRICS for impression and action
    let size = this.state.bdpCentral.length;
    for (let index = 0; index < size; index++) {
      this.state.totalImp[index] =
        parseInt(this.state.bdpCentral[index]) +
        parseInt(this.state.particularADVImpr[index]);
      this.state.totalAction[index] =
        parseInt(this.state.websiteCentral[index]) +
        parseInt(this.state.particularADVClick[index]);
    }
    this.state.particularADVImpr = this.state.totalImp;
    var chart_array = [];
    chart_array.push(this.state.totalImp);
    //chart_array.push(this.state.particularADVClick);
    chart_array.push(this.state.totalAction);
    chart_array.push(this.state.overAllAdvImpr);
    chart_array.push(this.state.overAllAdvClicks);

    var chartDataObj = {};
    chartDataObj.chartDataPoint = chart_array;
    chartDataObj.chartLabel = this.state.chartLabel;
    chartDataObj.chartDisplayMetrics = this.state.chartDisplayMetrics;
    this.setState({
      chartDataObj,
      centralchartDataObj,
    });

    this.getDashBoardMetaData().then((response) => {
      if (
        response != undefined &&
        response.hasOwnProperty("data") &&
        response.data[0] != undefined
      ) {
        this.setState({
          metaData: response.data[0].charts,
        });
      }
    });
  }
  setReduxdate() {
    if (!this.state.setdateredux) {
      let today = new Date();
      // let Yesturday = new Date();
      // Yesturday.setDate(today.getDate() - 1);
      let datemin1 = today.getDate() - 1;
      // let fromdate =
      //   today.getFullYear() - 1 + "-" + parseInt(today.getMonth() + 1) + "-01";
      let lastDate = new Date();
      lastDate.setDate(today.getDate() - 365);
      let fromDate = lastDate.getFullYear() +"-"+ parseInt(lastDate.getMonth() + 1) + "-" + lastDate.getDate(); 
      let enddate =
        today.getFullYear() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" + today.getDate();
      this.props.setHolisticFilterDate({
        holisticFromDate: fromDate,
        holisticToDate: enddate,
      });
    }
  }
  getAdvCount() {
    let filterService = new FilterService();
    filterService.fromDate = this.props.dateFilter.holisticFromDate;
    filterService.toDate = this.props.dateFilter.holisticToDate;
    filterService.level3 = this.state.level3;
    filterService.advertiserId = [localStorage.getItem("holisticAdvertiser")];

    filterService.getUniqueCountAdv().then((response) => {
      if (response.status != 400) {
        this.setState({
          uniqueAdvCount: response.data.resultAsMap.uniqueCount,
        });
      }
    });
  }
  async getdatafromCentralIndex() {
    let filterService = new FilterService();
    filterService.advertiserName = [localStorage.getItem("holisticAdvertiser")];
    filterService.fromDate = this.props.dateFilter.holisticFromDate;
    filterService.toDate = this.props.dateFilter.holisticToDate;
    var bdpArr = [];
    var serpsArr = [];
    var websiteArr = [];
    await filterService.getHolisticdataCenteral().then((response) => {
      if (response.status != 400) {
        var overall_bdp = response.data.resultAsMap.overall_bdp;
        var overall_serps = response.data.resultAsMap.overall_serps;
        var overall_website = response.data.resultAsMap.overall_website;
        if (overall_bdp > 0.0 || overall_serps > 0.0 || overall_website > 0.0) {
          this.setState({
            claimedListingName: "Yes",
            claimedListingStatus: true,
            claimedClassName: "claimedYes",
          });
        }
        response.data.resultAsMap.serveDate.map((item) => {
          bdpArr.push(item.Total_bdp);
          serpsArr.push(item.Total_serps);
          websiteArr.push(item.Total_website);
        });
        this.setState({
          websiteCentral: websiteArr,
          serpsCentral: serpsArr,
          bdpCentral: bdpArr,
        });
      }
    });
  }
  async getAdvitiserFilter() {
    let filterService = new FilterService();
    // filterService.advertiserId = "4538501895";
    filterService.advertiserId = [localStorage.getItem("holisticAdvertiser")];
    filterService.fromDate = this.props.dateFilter.holisticFromDate;
    filterService.toDate = this.props.dateFilter.holisticToDate;
    filterService.advertiserName = this.state.advertiserName;
    let reqlevel3 = 0;
    filterService.clauseDef = "MUST";
    filterService.level3 = this.state.level3;
    await filterService.getHolisticdata().then((response) => {
      if (response.status != 400) {
        response.data.resultAsMap.serveDate.map((item) => {
          let reslevel3 = response.data.resultAsMap.level3;
          console.log("Advitisetr Filter", reslevel3);
          if (reslevel3 && reslevel3 != "") {
            reqlevel3 = reslevel3;
          }
          this.setState({
            level3: reqlevel3,
          });
        });
      }
    });
  }

  async getdatafromDFS(clause) {
    let filterService = new FilterService();
    // filterService.advertiserId = "4538501895";
    filterService.advertiserId = localStorage.getItem("holisticAdvertiser");
    filterService.fromDate = this.props.dateFilter.holisticFromDate;
    filterService.toDate = this.props.dateFilter.holisticToDate;
    var chartDataPointArr = [];
    var impressionsArr = [];
    var clickArr = [];
    var monthArr = [];
    var ctrParticular = [];
    var time_viewed = [];
    let ctrtotalparticular = 0;
    let Total_time_viewed = 0;
    let reqlevel3 = 0;
    filterService.clauseDef = clause;
    filterService.level3 = this.state.level3;
    await filterService.getHolisticdata().then((response) => {
      if (response.status != 400) {
        response.data.resultAsMap.serveDate.map((item) => {
          var modulo_impr = item.Total_Impressions_Delivered;
          var modulo_count = item.Total_Clicks_Delivered;
          var Time_View = item.Time_View;
          var ctrConcad = 0;
          if (clause == "MUST_NOT") {
            modulo_impr = modulo_impr / (this.state.uniqueAdvCount > 0 ? this.state.uniqueAdvCount : 1);
            modulo_count = modulo_count / (this.state.uniqueAdvCount > 0 ? this.state.uniqueAdvCount : 1);
          } else if (clause == "MUST") {
            let reslevel3 = response.data.resultAsMap.level3;
            console.log("level3level33", reslevel3);
            if (reslevel3 && reslevel3 != "") {
              reqlevel3 = reslevel3;
            }
            ctrConcad = (modulo_count / modulo_impr) * 100;
            if (isNaN(ctrConcad)) {
              ctrConcad = 0;
            }
            if (isNaN(Time_View)) {
              Time_View = 0;
            }
            ctrtotalparticular = ctrtotalparticular + ctrConcad;
            ctrParticular.push(ctrConcad.toFixed(2).toString());
            Total_time_viewed = Total_time_viewed + Time_View;
            time_viewed.push(Time_View.toFixed(1).toString());
            let date = new Date(item.DATE_HISTOGRAM_serveDate);
            let shortMonth = date.toLocaleString("en-us", {
              month: "short",
              year: "2-digit",
            });
            console.log("datedatedate", shortMonth);
            monthArr.push(shortMonth.toString());
          }
          impressionsArr.push(modulo_impr.toFixed(1).toString());
          clickArr.push(modulo_count.toFixed(1).toString());
        });
      }
      if (clause == "MUST") {
        this.setState({
          particularADVImpr: impressionsArr,
          particularADVClick: clickArr,
          particularADVCtr: ctrParticular,
          ctrtotalparticular: ctrtotalparticular,
          timeViewed: time_viewed,
          totaltimeViewed: Total_time_viewed,
          sum_month_impr: response.data.resultAsMap.overall_month_imp,
          sum_month_click: response.data.resultAsMap.overall_month_click,
          chartLabel: monthArr,
          claimed: true,
          level3: reqlevel3,
        });
      } else {
        this.setState({
          overAllAdvImpr: impressionsArr,
          overAllAdvClicks: clickArr,
        });
      }
    });
  }
  getDashBoardMetaData = () => {
    this.setState({
      metaData: [],
    });
    var id = config.reports.ids.HOLISTICS_SERVE_DATE_ID;
    let reportingConfigService = new ReportingConfigService();
    reportingConfigService.id = id;
    return reportingConfigService.fetchMetaDataConfig();
  };

  renderChart = (flag) => {
    let result = [];
    if (!isEmpty(this.state.particularADVImpr)) {
      let data_push = [];
      let data_push12 = [];
      let Displaymeterics = { label: "Total impressions", color: "#c0c0c0" };
      let displayName = "";
      let displayCount = "";
      if (flag == "impressions") {
        data_push.push(this.state.particularADVImpr);
        displayName = "TOTAL IMPRESSIONS";
        Displaymeterics = { label: "Total impressions", color: "#c0c0c0" };
        displayCount = CommonUtil.commaSeparatedNumber(
          this.state.sum_month_impr
        );
      } else if (flag == "click") {
        data_push.push(this.state.particularADVClick);
        displayName = "TOTAL CLICKS";
        Displaymeterics = { label: "Total CLICKS", color: "#c0c0c0" };
        displayCount = CommonUtil.commaSeparatedNumber(
          this.state.sum_month_click
        );
      } else if (flag == "ctr") {
        data_push.push(this.state.particularADVCtr);
        displayName = "CLICK THROUGH RATE";
        Displaymeterics = { label: "CLICK THROUGH RATE", color: "#c0c0c0" };
        let clickThr =
          (this.state.sum_month_click / this.state.sum_month_impr) * 100;
        clickThr = clickThr.toFixed(2);
        if (clickThr == "NaN") {
          clickThr = 0;
        }
        displayCount = clickThr;
      } else if (flag == "timeView") {
        data_push.push(this.state.timeViewed);
        displayName = "TIME VIEWED(IN HRS)";
        Displaymeterics = { label: "TIME VIEWED(IN HRS)", color: "#c0c0c0" };
        displayCount = this.state.totaltimeViewed.toFixed(1);
      } else if (flag == "10k") {
        let tenkvalue =
          (this.state.sum_month_click / this.state.sum_month_impr) * 10000;
        tenkvalue = tenkvalue.toFixed(2);
        if (tenkvalue == "NaN") {
          tenkvalue = 0;
        }
        let data = {
          displayName: "RESPONSE PER 10K IMPRESSIONS",
          displayValue: tenkvalue,
        };
        result.push(<SpeedoMeterChart {...data} />);
        return result;
      }
      data_push12.push(Displaymeterics);
      let data = {
        chartLabel: this.state.chartLabel,
        chartDisplayMetrics: data_push12,
        displayName: displayName,
        displayCount: displayCount,
      };

      data.chartDataPoint = data_push;
      result.push(<CustomisedDrawChart {...data} />);
    }
    return result;
  };
  handleFilterClick = () => {
    this.loadDataholistic();
  };
  handleExportReportClick = () => {
    const name = this.state.advertiserName;
    const domElement = document.getElementById("divToPrint");
    const width = domElement.clientWidth;
    const height = domElement.clientHeight;
    window.open("pdfdownload", "_blank");
    document.getElementById("awarenessperformancediv").style.visibility =
      "hidden";
    document.getElementById("cliveShort2").style.display = "block";
    html2canvas(domElement, 
      {
         scale: width / height
     }).then(function (canvas) {

      const img = canvas.toDataURL("image/png", 2.0);
      const pdf = new jsPdf("l", "px", [width + 60, height + 40]);
      pdf.addImage(img, "png", 10, 10, width, height, "alias", "FAST"); //imgWidth,imgHeight
      pdf.setDisplayMode("fullpage");
      pdf.setProperties({ 
         title: name,  
         subject: "Holistic View"
       });
      pdf.setDisplayMode("fullpage");
      pdf.save(name);
      document.getElementById("awarenessperformancediv").style.visibility =
        "visible";
      document.getElementById("cliveShort2").style.display = "none";
    });
  };
  render() {
    console.log("claimed", this.state.claimed);
    const { classes } = this.props;
    const { filterConfig } = this.state;
    var filters = {};
    filters.advertiserFilter = [this.state.advertiserName];
    filters.adServers = [];
    var date = {};
    date.fromDate = this.props.dateFilter.holisticFromDate;
    date.toDate = this.props.dateFilter.holisticToDate;
    filters.date = date;
    filters.dateTypeFilter = "Run Date";
    filters.regions = [];
    filters.salesRepFilter = [];
    let Header = "Advertiser Name : " + this.state.advertiserName;
    let dateheader =
      this.props.dateFilter.holisticFromDate +
      " to " +
      this.props.dateFilter.holisticToDate;
    return (
      <>
        <div className={classes.drawContainer}>
          <div id="divToPrint">
            <div class="content-header rhs filters-container">
              <div className={classes.holistheader}>
                <div className={classes.cliveShort}>
                  {" "}
                  <h2>{this.state.advertiserName}</h2>
                </div>
                <div id="buttonsdiv" className={classes.dateHolist}>
                  {" "}
                  <Filters
                    filterConfig={filterConfig}
                    handleExportReportClick={this.handleExportReportClick}
                    handleFilterClick={this.handleFilterClick}
                  />
                </div>
              </div>
            </div>
            <div className={classes.overView}>
              <div id="cliveShort2" className={classes.cliveShort2}>
                {" "}
                <h2>{Header}</h2>
                <p>{dateheader}</p>
              </div>
              <h2>Overview</h2>
              <div className={classes.drawChat}>
                {Object.keys(this.state.chartDataObj).length > 0 ? (
                  <MultiChart {...this.state.chartDataObj} />
                ) : null}
                {this.state.claimed && (
                  <div
                    className={
                      this.state.claimedListingName == "Yes"
                        ? classes.claimedYes
                        : classes.claimed
                    }
                  >
                    <span className={classes.claimed1}>Claimed Listings?</span>
                    <span className={classes.claimed2}>
                      {this.state.claimedListingName}
                    </span>
                  </div>
                )}
              </div>
              <div className={classes.centraldrawChat}>
                {this.state.claimedListingStatus && <h2>PRESENCE OVERVIEW</h2>}
                {this.state.claimedListingStatus &&
                Object.keys(this.state.centralchartDataObj).length > 0 ? (
                  <CentralMultiChart {...this.state.centralchartDataObj} />
                ) : null}
              </div>
              <div className={classes.awarenessdiv}>
                <h2>Awareness Overview</h2>
                <div className={classes.drawChatimpression}>
                  {this.renderChart("impressions")}
                  {this.renderChart("timeView")}
                  {this.renderChart("click")}
                  {this.renderChart("ctr")}
                  {this.renderChart("10k")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.awarenessperformanceContainer}>
        <div
          id="awarenessperformancediv"
          className={classes.awarenessperformance}
        >
          <h2>Awareness Performance</h2>
          <div className={classes.drawChattable}>
            {this.state.metaData.map((mdata) => {
              if (mdata.chartType == "TABLE") {
                var adserverFilterCount = 0;
                return (
                  <CampaignListing
                    adserverFilterCount={adserverFilterCount}
                    metaData={mdata}
                    filters={filters}
                  />
                );
              }
            })}
          </div>
        </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setHolisticFilterDate: setHolisticFilterDate,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    dateFilter: state.dateFilter,
    dateTypeFilter: state.dateType.dateFilterType,
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Holistic))
);
