import React, { useState, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import config from "../../config/config";
import 'react-awesome-slider/dist/styles.css';
import _ from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import preloader from '../../../preloader.gif';
const styles = theme => ({
  root: {
    margin: 0,
    padding: '16px',
    display: 'inline-block',
    fontSize: '24px',
    background: '#f3f3f3',
    padding: '18px 18px 17px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    color: '#444',
    fontSize: '22px',
    lineHeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    display: 'block',
    height: 'auto',
    overflow: 'hidden'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: theme.palette.grey[500],
  },
  dPaper:{
    maxWidth:'100vw',
    height:'unset'
  },
  loader:{
    backgroundImage: `url(${preloader})`,
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '50%',
    zIndex: '999999999',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  loaderWrapper:{
    width:'600px',
    height:'300px',
    position:'relative'
  }
});

const reg = {
  scriptTagReg: new RegExp(/(?=src\s*=\s*')(.*?)(")/im),
  removeSrcReg: new RegExp(/src\s*=\s*['|"]([^"]+)/im),
  adUrlTagReg: new RegExp(/(?=iframe\.src\s*=\s*\")(.*?)(?=\?refId\s*)/im),
  removeadUrlSrcReg: new RegExp(/iframe.src\s*=\s*['|"]([^\?]+)/im),
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

async function getAdUrl(data) {
  const { scriptTagReg, adUrlTagReg, removeSrcReg, removeadUrlSrcReg } = reg;
  var finalURL;
  if (scriptTagReg.test(`${data.htmlSnippet}`)) {
    // let withSrc = scriptTagReg.exec(`${scriptTag}`)[0];
    let axiosUrl = await removeSrcReg.exec(`${data.htmlSnippet}`)[1];
    axiosUrl = axiosUrl.replace('%%VIEW_URL_UNESC%%','');
    console.log('removeSrcReg 1', axiosUrl);
    // finalURL = finalURL.replace('%%VIEW_URL_UNESC%%','');
    try {
      let size = data.fileSize && data.fileSize.toLowerCase().includes('x') ? data.fileSize.replace(/\s+/g, '').toLowerCase().split('x') : ['600','300'];
      let result = await axios({
        method: 'get',
        url: axiosUrl,
        crossDomain: true,
        headers: {
          'Content-Type': 'text/html'
        }
      });
      let adUrl = await adUrlTagReg.exec(`${result.data}`);
      let adSrcremoved = await removeadUrlSrcReg.exec(adUrl)
      finalURL = adSrcremoved[1].split(',')[0];
      console.log('adSrcremoved 2', JSON.stringify(finalURL));
      return {url:finalURL,size:size};
    } catch (err) {
      return {url:undefined,size:undefined};
    }
  } else {
    return {url:undefined,size:undefined};
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}


const CreativePreview = memo (({ lineId, classes }) => {

  const [open, setOpen] = useState(false);
  const [creativeData, setCreativeData] = useState([]);
  const [urls, setUrls] = useState([]);
  const [loader, setLoader] = useState([]);

  const handleClickOpen = async (e) => {
    let data = await getData();
    setCreativeData(data);
    setOpen(true);
    setLoader(true);
    let urls = [];
    await asyncForEach(data, async (item, index) => {
      let value = item.fileLocation ? item.fileLocation : item.htmlSnippet ? await getAdUrl(item) : null;
      urls.push({ source: value });
    });
    console.log("urls2", urls)
    setUrls(urls);
    setLoader(false);
  }

  const handleClose = () => setOpen(false);
  const getData = async () => {
    let response = await axios({
      method: 'GET',
      url: `${config.common.CREATIVE_PREVIEW}${lineId}`
      // url:`${config.common.CREATIVE_PREVIEW}50005231`
    });
    return response.data;
  }

  return (
    <>
      <img id='creativeView' style={{ 'cursor': 'pointer', width: '20px', marginRight: '10px' }} onClick={handleClickOpen} src={`${process.env.PUBLIC_URL}/assets/images/viewEye.svg`} />
      <Dialog aria-labelledby="customized-dialog-title" open={open} classes={{paper:classes.dPaper}}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Creative Preview
        </DialogTitle>
        <DialogContent >
          {/*<IFrame lineId={lineId} />*/}
          {/* <AwesomeSlider
                   media={urls}
               /> */}

          {loader && <div className={classes.loaderWrapper}><span className={classes.loader}></span></div>}
          {(!loader && urls.length === 0 )?
            <div style={{ 'background-color': 'white', 'padding': '20px', 'overflow':'hidden','min-width':'180px' }}>No Preview Available</div>
            : !loader && <Carousel showThumbs={false} dynamicHeight={true} >
              {urls.map((res, index) => {
                if(res.source && res.source.url) {
                  return <div>
                          <iframe
                            src={res.source.url}
                            style={{width:res.source.size[0]+'px', height:res.source.size[1]+'px'}}
                            frameborder="0"
                            scrolling="yes"
                            >
                          </iframe>
                        </div>
                }
                else if(res.source) {
                  return <div>
                    <img src={res.source} alt="" />
                  </div>
                }
                else {
                  return <div style={{ 'background-color': 'white', 'padding': '20px', 'overflow':'hidden','min-width':'180px' }}>No Preview Available</div>
                }
              }
              )}
            </Carousel>}

        </DialogContent>
      </Dialog>
    </>
  );

})

export default withStyles(styles)(CreativePreview);