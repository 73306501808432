import React, { Component } from 'react';
import CampaignDetail from './CampaignDetail';
import CampaignTotalMetrics from './CampaignTotalMetrics';
import Dfp from '../lineitems/Dfp';
import FaceBook from '../lineitems/FaceBook';
import Dbm from '../lineitems/Dbm';
import { connect } from 'react-redux';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { bindActionCreators } from 'redux';
import CustomChip from '../common/Chip';
import { getCampaignInfoMetaData } from '../../actions/campaign/CampaignInfoMetaDataAction';
import FilterUtil from "../../utils/FilterUtil";
import FilterService from './../../service/FilterService';
import { isEmpty } from 'lodash';
import Doc from './DocService';
import PdfContainer from './PdfContainer';
import SelectComponentModel from '../../components/modelPopup/sendMail/SelectComponentModel';
import ReportingConfigService from './../../service/ReportingConfigService';
import config from './../../config/config';
import DisplayTopMetrics from './../reports/benchmarking/DisplayTopMetrics';
import LineChart from './../chartjs/LineChart';
import {setAdvertiserLevels} from './../../actions/filter/LevelFilterAction';
import axios from "axios";
import JSONBigInt from "json-bigint";
/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const moment = extendMoment(originalMoment);
const styles = {
    marginLeft: '10px'
}
class CampaignInfo extends Component {

    constructor(props) {

        super(props);
        this.state = {
            campInfo: {},
            totalImpression: '',
            totalClicks: '',
            topMetricsMetaData: [],
            lineChartMetaData: [],
            advertisersList: [],
            advitiserLevels:[],
            ctr: '',
            newprops: [],
            bestCtr: '',
            levelSearch: "",
            filters: [],
            trackingNo: ''
        };
        this.parseUrl = this.parseUrl.bind(this);
        this.getUrlData = this.getUrlData.bind(this);
        this.renderLineItems = this.renderLineItems.bind(this);
        this.getUrlData();
        this.props.getCampaignInfoMetaData(this.state.campInfo.dateTypeFilter);
    }

    getBestCtr = (value) => {
        this.state.bestCtr = value;
        console.log('valuevalue', value);
    }
    getReportMetaData = () => {
        let reportingConfigService = new ReportingConfigService();
        reportingConfigService.id = config.reports.ids.BENCHMARKING_REPORT_ID;
        let filtersObj={};
        let filters = FilterUtil.setFilterObj(filtersObj);
        reportingConfigService.fetchMetaDataConfig()
            .then(response => {
                if (response.data != undefined && response.data.length > 0) {
                    let charts = response.data[0].charts;
                    let lineChartMetaData = [];
                    let topMetricsMetaData = [];
                    for (let item of charts) {
                        switch (item.chartType) {
                            case 'JD_SPLINE':
                                lineChartMetaData.push(item);
                                break;
                            case 'COUNT':
                                topMetricsMetaData.push(item);
                                break;
                        }
                    }
                    //let filters = {};
                    filters.filter = FilterUtil.setBenchMarkFilter(this.state);
                    //Defaul filter for benchmarking, to get only GAM data
                    filters.filter.push({
                        "field": "advertiserId",
                        "values": [localStorage.getItem("advertiserNameForCampaignInfo")]
                    })
                    filters.filter.push({ field: "adServer", values: ["GAM"] });
                    filters.rangeQueryData = FilterUtil.getServerDateFilter({
                        "fromDate": moment(this.state.campInfo.startDate).format('YYYY-MM-DD'),
                        "toDate": moment(this.state.campInfo.endDate).format('YYYY-MM-DD')
                    });
                    console.log("range date", FilterUtil.getServerDateFilter(this.props.dateFilter))
                    console.log("props", moment(this.state.campInfo.startDate).format('YYYY-MM-DD'))
                    this.setState({
                        topMetricsMetaData,
                        lineChartMetaData,
                        filters: filters
                    });
                    localStorage.setItem("advertiserNameForCampaignInfo", '')
                }
            })
            .catch(error => { console.log("error", error); });

    }
    /*Bench mark End*/
    parseUrl = (key) => {
        let Data;
        if (key == "advertiser") {
            Data = window.location.search.split("?")[1].split("&")[0].split("=")[1];
        } else {
            Data = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        }
        console.log("Url Data", Data);
        return Data;
    }
    getUrlData = () => {

        let campInfo = {};
        let data = ['advertiser', 'startDate', 'endDate', 'region', 'adServers', 'dateTypeFilter', 'field', 'from', 'to', "advertiserId"];
        for (var i in data) {
            campInfo[data[i]] = this.parseUrl(data[i]);
        }
        campInfo.trackingNo = this.props.match.params.trackingNo;
        let { field, from, to } = campInfo;
        campInfo.rangeQueryData = [];
        if (from != '' && to != '')
            campInfo.rangeQueryData = [{ field, from, to, 'includeFrom': true, 'includeTo': true }];
        this.state.campInfo = campInfo;

        localStorage.setItem("dateFilterForCampaignInfo", campInfo.rangeQueryData)
        localStorage.setItem("advertiserIdForCampaignInfo", campInfo.advertiserId)
        localStorage.setItem("advertiserNameForCampaignInfo", decodeURIComponent(campInfo.advertiser))
    }

    labelFormat = (adservers) => {
        let data = adservers.split(',');
        let adServers = [];
        if (!isEmpty(data)) {
            data.map((item, index) => {
                let a = item.toLowerCase();
                adServers.push(' ' + a.charAt(0).toUpperCase() + a.slice(1))
            })
        }
        return adServers.toString();
    }
    componentWillMount() {
        this.setState({ campInfo: this.state.campInfo });
        this.getReportMetaData();
    }
    componentDidMount(){
        this.fetchLevelsForSelectedAdvertiser(this.state.campInfo.advertiserId.split(",")[0]);
    }
    fetchLevelsForSelectedAdvertiser(advertiserId){

        let filterService=new FilterService();
        let metaData={};
        metaData.indexPrefix=config.index.LINEITEM_SERVEDATE_METRICS;
        metaData.dimensions= [{
            "name":"level1",
            "displayName":"level1",
            "op":"SELECT"
        },
            {
                "name":"level2",
                "displayName":"level2",
                "op":"SELECT"
            },
            {
                "name":"level3",
                "displayName":"level3",
                "op":"SELECT"
            }];
        filterService.metaData=metaData;
        filterService.filters=[{field:'advertiserId',values:[advertiserId]}];
        filterService.size=100;
        filterService.fetchTopFilterData()
            .then(response=>{
                if(response.hasOwnProperty('data')  && response.data.length>0){
                    let advertisersLevels=response.data;
                    console.log("advertisersLevels", advertisersLevels)
                    this.setState({
                        advitiserLevels: advertisersLevels
                    });
                    //this.props.setAdvertiserLevels(advertisers);
                }
            }).catch(error=>{console.log("Exception:", error);});
    }
    renderLineItems = () => {

        const { rangeQueryData, adServers, trackingNo } = this.state.campInfo;
        const lineItemsComponents = [];
        adServers.indexOf('Newsquest Display') >= 0 && lineItemsComponents.push(<Dfp trackingNo={trackingNo} rangeQueryData={rangeQueryData} />);
        adServers.indexOf('FACEBOOK') >= 0 && lineItemsComponents.push(<FaceBook trackingNo={trackingNo} rangeQueryData={rangeQueryData} />);
        adServers.indexOf('Audience Extension') >= 0 && lineItemsComponents.push(<Dbm trackingNo={trackingNo} rangeQueryData={rangeQueryData} />);
        return lineItemsComponents;
    }

    passMetricsData = (totalImpression, totalClicks, ctr) => {
        if (totalImpression !== undefined && totalClicks !== undefined && ctr !== undefined) {
            this.setState({ totalImpression: totalImpression, totalClicks: totalClicks, ctr: ctr });
        }
    }
    createPdf = (html) => Doc.createPdf(html, this.props.match.params.trackingNo);
    sendPdf = (html) => Doc.sendPdf(html, this.props.match.params.trackingNo);

    render() {
        console.log("advitaiserlist",this.state.advertisersList)
        const { topMetricsMetaData, lineChartMetaData, filters ,advitiserLevels} = this.state;
        const levelList = advitiserLevels.map(level => {
            return level.values
        })
        console.log({levelList})
        const topMetricsComponent = topMetricsMetaData.map(metaData => {
            return <DisplayTopMetrics metaData={metaData} filters={this.state.filters} />;
        });
        const lineChartComponent = lineChartMetaData.map(lineChartMeta => {
            if (this.state.filters)
                return <LineChart getBestCtr={this.getBestCtr} lineChartMetaData={lineChartMeta} filters={this.state.filters}
                                  advertiserNameCI={decodeURIComponent(this.state.campInfo.advertiser)} />
        });
        var { rangeQueryData, adServers, trackingNo, startDate } = this.state.campInfo;
        var { totalImpression, totalClicks, ctr } = this.state;
        let dateStart, dateEnd;
        if (rangeQueryData.length > 0) {
            if (this.state.campInfo.dateTypeFilter !== 'serveDate') {
                // this.state.campInfo.dateTypeFilter
                dateStart = moment(moment(rangeQueryData[0].from).add('1', 'days').format('DD/MM/YYYY'))['_i'];
                dateEnd = moment(moment(rangeQueryData[0].to).add('1', 'days').format('DD/MM/YYYY'))['_i'];
            }
            else {
                dateStart = moment(moment(rangeQueryData[0].from).format('DD/MM/YYYY'))['_i'];
                dateEnd = moment(moment(rangeQueryData[0].to).format('DD/MM/YYYY'))['_i'];
            }
            var dateRange = dateStart + ' to ' + dateEnd;
        }
        else var rangeFlag = 0;

        return (
            <>
                <div class="campaignInfo scroll rhs view">
                    <div class="content-header scroll-animate reportsHead">
                        <div class="jobno">
                            <span style={styles} class="inline-block header">Campaign Info</span>
                        </div>
                    </div>
                    <div class="content-body  taskWrap" style={{ 'padding': '28px 12px' }}>
                        {rangeFlag !== 0 && <CustomChip filterLabel={"Date Type"} label={FilterUtil.getDateMapping(this.state.campInfo.dateTypeFilter)} variant="clearFilter" />}
                        {rangeFlag !== 0 && <CustomChip filterLabel={"date range"} label={dateRange} variant="clearFilter" />}
                        {rangeFlag !== 0 && <CustomChip filterLabel={"adserver"} label={this.labelFormat(adServers)} variant="clearFilter" />}
                        <PdfContainer createPdf={this.createPdf} sendPdf={this.sendPdf}>
                            <div id="campaignInfoPage" class="content-body  taskWrap" style={{ 'padding': '28px 20px' }}>
                                <CampaignDetail ctr={ctr} totalImpression={totalImpression} totalClicks={totalClicks} campInfo={this.state.campInfo} />
                                ​
                                <CampaignTotalMetrics
                                    passMetricsData={this.passMetricsData}
                                    adServer={adServers}
                                    trackingNo={trackingNo}
                                    rangeQueryData={rangeQueryData}
                                />
                            </div>
                        </PdfContainer>
                        ​
                        <div class="boxContainer responsive-facebook">
                            <div class="headingtagWrap">
                                <h2>Line item info</h2>
                            </div>
                            {this.renderLineItems()}
                            ​
                        </div>
                        {this.state.campInfo['adServers'].includes("Newsquest")
                        || this.state.campInfo['adServers'].includes("GAM") ?
                            <div class="boxContainer responsive-facebook">
                                <div class="headingtagWrap">
                                    <h2>Industry benchmark</h2>
                                </div>
                                ​
                                <div class="scroll rhs" id="pagepoup">
                                    <div class="scroll-animate ">
                                        <div class="content-body ">
                                            <div class="cmnInsideWrapper">
                                                <div class="boxContainer cardPadd noMarTop" >
                                                    <p>Benchmarking Advertiser: {decodeURIComponent(this.state.campInfo.advertiser)}
                                                        {levelList[0]== ''? '': <span>  against <b> LEVEL 1 </b> -  {levelList[0]}, <b> LEVEL 2 </b> - {levelList[1]}, <b> LEVEL 3 </b> - {levelList[2]}</span>}

                                                    </p>
                                                </div>
                                            </div>
                                            <ol class="row responsiveDisplay">
                                                {topMetricsComponent}
                                            </ol>
                                            {lineChartComponent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        dateFilter: state.dateFilter,
        level: state.levelType.level,
        levelSearch: state.search.levelSearch,
        advertiser: state.advertiser.autoSuggestAdvertiser,
        topFilterMetaData:state.topFilterMetaData
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getCampaignInfoMetaData: getCampaignInfoMetaData,
    setAdvertiserLevels:setAdvertiserLevels
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfo);