
import config from '../config/config';
import DataFetchService from './DataFetchService';
import SavedFilterService from './SavedFilterService';
import AuthUtil from './../utils/AuthUtil';

import _ from 'lodash';

class FilterService {

    fetchTopFilterData = () => {

        let metaData = this.metaData;
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.url = config.reports.FILTER_URL;
        dataFetchService.rangeQueryData = this.rangeQueryData ? this.rangeQueryData : [];
        dataFetchService.dimensions = metaData.dimensions;
        dataFetchService.metricObj = metaData.metrics;
        dataFetchService.operationType = metaData.queryType;
        //if(!_.isNil(this.resultSize))dataFetchService.resultSize=this.resultSize;
        dataFetchService.filters = this.filters ? this.filters : [];
        dataFetchService.size = this.size ? this.size : [];
        return dataFetchService.fethData();

    }
    saveDefaultFilters = () => {
        let metaData =
        {
            "filters": [
                {
                    "filterType": "advertiser",
                    'filterValues': this.advertiserFilter
                },
                {
                    "filterType": "region",
                    'filterValues': this.regionFilter
                },
                {
                    "filterType": "adServer",
                    'filterValues': this.adServersFilter
                },
                {
                    "filterType": "salesRep",
                    'filterValues': this.salesRepFilter
                },
                {
                    "filterType": "advFilterName",
                    'filterValues': this.advFilterName
                }
            ],
            'dashboardType': 'analyticsSavedFilter',
            'filterName': this.nameFilter,
            "isDefault": this.defaultValue,
            "isDeleted": this.deleteValue
        };
        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = metaData;
        dataFetchService.urlMethod = 'POST';
        dataFetchService.url = config.reports.SAVE_FILTER_URL_CMS;
        return dataFetchService.fethData();

    }
    fetchDefaultFilters = () => {
        var authUtil = new AuthUtil();
        let metaData =
        {
            sessionID: authUtil.getCookieByKey('_sessionID'),
            userID: authUtil.getCookieByKey('_userID')
        };
        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = metaData;
        dataFetchService.urlMethod = 'GET';
        dataFetchService.url = config.reports.FETCH_FILTER_URL_CMS;
        return dataFetchService.fethData();
    }
    setDefaulFilters = () => {

        let metaData =
        {
            "filters": [
                {
                    "filterType": "advertiser",
                    'filterValues': this.advertiserFilter
                },
                {
                    "filterType": "region",
                    'filterValues': this.regionFilter
                },
                {
                    "filterType": "adServer",
                    'filterValues': this.adServersFilter
                },
                {
                    "filterType": "salesRep",
                    'filterValues': this.salesRepFilter
                },
                {
                    "filterType": "advFilterName",
                    'filterValues': this.advFilterName
                }
            ],
            'dashboardType': 'analyticsSavedFilter',
            'filterName': this.nameFilter,
            "isDefault": this.defaultValue,
            "isDeleted": this.deleteValue
        };
        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = metaData;
        dataFetchService.urlMethod = 'PUT';
        dataFetchService.url = config.reports.UPDATE_FILTER_URL_CMS;
        return dataFetchService.fethData();

    }
    deleteFilters = () => {

        let metaData =
        {
            "filters": [
                {
                    "filterType": "advertiser",
                    'filterValues': this.advertiserFilter
                },
                {
                    "filterType": "region",
                    'filterValues': this.regionFilter
                }
            ],// change filter name from: analyticsSavedFilter
            'dashboardType': 'analyticsSavedFilter_ORDER',
            'filterName': this.nameFilter,
            "isDefault": this.defaultValue,
            "isDeleted": this.deleteValue
        };
        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = metaData;
        dataFetchService.urlMethod = 'POST';
        dataFetchService.url = config.reports.DELETEFILTER_URL_CMS;
        return dataFetchService.fethData();

    }
    getHolisticdataCenteral=()=>{
        let requestbody = {};
        requestbody.reportType = 'JD_METRICS';
        requestbody.indexPrefix = config.index.CENTRALINDEX_DATA;
        requestbody.queryType = 'AGG';
        requestbody.fieldsForCountValues = [];
        let filters_config_arr = [];
        let filters_config = {};
        let rangeQueryData_config_arr = []
        let rangeQueryData_config = {};
        let metrics_config_arr = [];
        let metrics_config = {};
        let bounds = {}

        let filters_config_advi = {};
        filters_config_advi.field = "name";
        filters_config_advi.clause = "MUST";
        filters_config_advi.values = this.advertiserName;
        filters_config_arr.push(filters_config_advi);
        filters_config.field = "claim";
        filters_config.clause = "MUST";
        filters_config.values = ["true"];
        filters_config_arr.push(filters_config);

        requestbody.filters = filters_config_arr;

        rangeQueryData_config.field = "serveDate";
        rangeQueryData_config.from = this.fromDate;
        rangeQueryData_config.to = this.toDate;
        rangeQueryData_config.includeFrom = true;
        rangeQueryData_config.includeTo = true;
        rangeQueryData_config_arr.push(rangeQueryData_config)
        requestbody.rangeQueryData = rangeQueryData_config_arr;

        metrics_config.name = "serveDate";
        metrics_config.displayName = "serveDate";
        metrics_config.op = "DATE_HISTOGRAM";
        metrics_config.isUIField = "true";
        bounds.min = this.fromDate;
        bounds.max = this.toDate;
        metrics_config.bounds = bounds;

        let overallbdp = {};
        overallbdp.name = "bdp";
        overallbdp.displayName = 'overall_bdp';
        overallbdp.op = 'SUM';
        overallbdp.isUIField = true;

        let overallserps = {};
        overallserps.name = "serps";
        overallserps.displayName = 'overall_serps';
        overallserps.op = 'SUM';
        overallserps.isUIField = true;

        let overallwebsite = {};
        overallwebsite.name = "website";
        overallwebsite.displayName = 'overall_website';
        overallwebsite.op = 'SUM';
        overallwebsite.isUIField = true;

        let subMetrics_config_arr = [];
        let subMetrics_config = {};
        subMetrics_config.name = "bdp";
        subMetrics_config.displayName = "Total_bdp";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);

        subMetrics_config = {};
        subMetrics_config.name = "serps";
        subMetrics_config.displayName = "Total_serps";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);

        subMetrics_config = {};
        subMetrics_config.name = "website";
        subMetrics_config.displayName = "Total_website";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);

        metrics_config.subMetrics = subMetrics_config_arr;
        metrics_config_arr.push(metrics_config);
        metrics_config_arr.push(overallserps);
        metrics_config_arr.push(overallwebsite);
        metrics_config_arr.push(overallbdp);
        requestbody.metrics = metrics_config_arr;


        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = requestbody;
        dataFetchService.urlMethod = "POST";
        dataFetchService.url = config.reports.DFS_REPORT_MISC;
        return dataFetchService.fethData();
    }

    getHolisticdata = () => {
        let requestbody = {};
        requestbody.reportType = 'JD_METRICS';
        requestbody.indexPrefix = config.index.LINEITEM_SERVEDATE_METRICS;
        requestbody.queryType = 'AGG';
        requestbody.fieldsForCountValues = [];
        let filters_config_arr = [];
        let filters_config = {};
        let rangeQueryData_config_arr = []
        let rangeQueryData_config = {};
        let metrics_config_arr = [];
        let metrics_config = {};
        let bounds = {};
        filters_config.field = "advertiserId";
        filters_config.clause = this.clauseDef;
        filters_config.values = [localStorage.getItem("holisticAdvertiser")];
        filters_config_arr.push(filters_config);

        if (this.level3 != 0) {
            let filters_config_level = {};
            filters_config_level.field = "level3";
            filters_config_level.clause = 'MUST';
            filters_config_level.values = 'string' === typeof this.level3 ? [this.level3]: this.level3;
            filters_config_arr.push(filters_config_level);
        }

        requestbody.filters = filters_config_arr;
        rangeQueryData_config.field = "serveDate";
        rangeQueryData_config.from = this.fromDate;
        rangeQueryData_config.to = this.toDate;
        rangeQueryData_config.includeFrom = true;
        rangeQueryData_config.includeTo = true;
        rangeQueryData_config_arr.push(rangeQueryData_config)
        requestbody.rangeQueryData = rangeQueryData_config_arr;
        metrics_config.name = "serveDate";
        metrics_config.displayName = "serveDate";
        metrics_config.op = "DATE_HISTOGRAM";
        metrics_config.isUIField = "true";
        bounds.min = this.fromDate;
        bounds.max = this.toDate;
        metrics_config.bounds = bounds;
        let overallimpression = {};
        overallimpression.name = "impressions";
        overallimpression.displayName = 'overall_month_imp';
        overallimpression.op = 'SUM';
        overallimpression.isUIField = true;
        //level3
        let reqLevel3= {};
        reqLevel3.name = "level3";
        reqLevel3.displayName = 'level3';
        reqLevel3.op = 'SELECT';
        reqLevel3.isUIField = false;
        //overall clicks
        let overallclicks = {};
        overallclicks.name = "clicks";
        overallclicks.displayName = 'overall_month_click';
        overallclicks.op = 'SUM';
        overallclicks.isUIField = true;


        let subMetrics_config_arr = [];
        let subMetrics_config = {};
        subMetrics_config.name = "impressions";
        subMetrics_config.displayName = "Total_Impressions_Delivered";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);
        subMetrics_config = {};
        subMetrics_config.name = "clicks";
        subMetrics_config.displayName = "Total_Clicks_Delivered";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);
        subMetrics_config = {};
        subMetrics_config.name = "totalLineItemLevelViewedTime";
        subMetrics_config.displayName = "Time_View";
        subMetrics_config.op = "SUM";
        subMetrics_config.isUIField = "true";
        subMetrics_config_arr.push(subMetrics_config);

        metrics_config.subMetrics = subMetrics_config_arr;
        metrics_config_arr.push(metrics_config);
        metrics_config_arr.push(overallclicks);
        metrics_config_arr.push(overallimpression);
        metrics_config_arr.push(reqLevel3);
        requestbody.metrics = metrics_config_arr;


        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = requestbody;
        dataFetchService.urlMethod = "POST";
        dataFetchService.url = config.reports.DFS_REPORT_MISC;
        return dataFetchService.fethData();
    }
    getUniqueCountAdv = () => {
        let requestbody = {};
        requestbody.reportType = 'JD_METRICS';
        requestbody.indexPrefix = config.index.LINEITEM_SERVEDATE_METRICS;
        requestbody.queryType = 'AGG';
        requestbody.fieldsForCountValues = [];

        let filters_config_arr = [];
        if (this.level3 != 0) {
            let filters_config = {};
            filters_config.field = "level3";
            filters_config.clause = 'MUST';
            filters_config.values = 'string' === typeof this.level3 ? [this.level3]: this.level3;
            filters_config_arr.push(filters_config);
        }

        let filters_config_adv = {};
        filters_config_adv.field = "advertiserId";
        filters_config_adv.clause = 'MUST_NOT';
        filters_config_adv.values = [localStorage.getItem("holisticAdvertiser")]; //this.advertiserId;
        filters_config_arr.push(filters_config_adv);

        requestbody.filters = filters_config_arr;

        let rangeQueryData_config_arr = []
        let rangeQueryData_config = {};
        rangeQueryData_config.field = "serveDate";
        rangeQueryData_config.from = this.fromDate;
        rangeQueryData_config.to = this.toDate;
        rangeQueryData_config.includeFrom = true;
        rangeQueryData_config.includeTo = true;
        rangeQueryData_config_arr.push(rangeQueryData_config)
        requestbody.rangeQueryData = rangeQueryData_config_arr;

        let metrics_config_arr = [];
        let metrics_config = {};
        metrics_config.name = "advertiserId";
        metrics_config.displayName = "uniqueCount";
        metrics_config.op = "UNIQUE_COUNT";
        metrics_config_arr.push(metrics_config);
        requestbody.metrics = metrics_config_arr;

        let dataFetchService = new SavedFilterService();
        dataFetchService.metaData = requestbody;
        dataFetchService.urlMethod = "POST";
        dataFetchService.url = config.reports.DFS_REPORT_MISC;
        return dataFetchService.fethData();

    }
}


export default FilterService;