"use strict";
import C from '../constants';

export function setCorpPermission(permissions){

    return function(dispatch){
        /* let permissionsTemp=[
            'jda.report.campaign.view',
            'jda.report.campaign.download',
            'jda.report.benchmarking.view',
            'jda.report.benchmarking.download'
        ]; */

        dispatch({
            type:C.SET_CORP_PERMISSION,
            permissions:permissions
        });
       /*  dispatch({
            type:C.SET_CORP_PERMISSION,
            permissions:permissions.length==0?permissionsTemp:permissions
        }); */
    }
};