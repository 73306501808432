"use strict";

import C from '../constants';

export  function getCampaign() {
    return function(dispatch){
        let payload = [];
        dispatch({
            type:C.GET_CAMPAIGN,
            payload
        });
    }    
};

export  function validateToken(authUtil) {
    return function(dispatch){

        dispatch({
            type:C.VALIDATE_TOKEN,
            isValidToken:'isValid'
        });

        let isValidToken = authUtil.validateToken();
        isValidToken.then(res=>{
            dispatch({
                type:C.VALIDATE_TOKEN,
                isValidToken:res.status!=200?'NotValid':'Valid'
            });
        }).catch(err=>{
            dispatch({
                type:C.VALIDATE_TOKEN,
                isValidToken:'NotValid'
            });
        });
    }    
};
