import React,{ Component } from 'react';
import DataFetchService from '../../service/DataFetchService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TotalMetricsTable from './tables/TotalMetricsTable';
import _ from 'lodash';

class CampaignTotalMetrics extends React.PureComponent{

    constructor(props){
        super(props);
        this.state={
            indicator:true,
            noResultDFP:false,
            metricsObj:{},
            totalImpression:0,
            totalClicks:0
        };
        this.renderData=this.renderData.bind(this);
    }

    renderData=(metaData)=>{
        /* Modify filter to add poNumber */
        let { rangeQueryData,trackingNo, adServer } = this.props;
        
        adServer = adServer.replace('Newsquest Display', 'GAM');
        adServer = adServer.replace('FACEBOOK', 'FB');
        adServer = adServer.replace('Audience Extension', 'DBM');
        var filters = metaData.filters[0];
            filters.values = [];
            filters.values.push(trackingNo);
        let dataFetchService = new DataFetchService();
        dataFetchService.indexPrefix = metaData.indexPrefix;
        dataFetchService.dimensions=metaData.dimensions;
        dataFetchService.size=100;
        dataFetchService.metricObj=metaData.metrics;
        dataFetchService.rangeQueryData=rangeQueryData;
        dataFetchService.operationType=metaData.operationType;
        dataFetchService.filters=[filters];
        dataFetchService.fethData()
        .then(response=>{
            var result=response.data.resultAsList;
            if(result!=null && result.length>0){
            
                var data;
                var metricsObj={}
                  , totalImpression=0
                  , totalClicks=0;
                for(let index in result) {
                    data=result[index];
                    if(adServer.indexOf(data.adServer) === -1) continue;
                    metricsObj[data.adServer]={
                       'impressions':data.Impressions,
                       'clicks':data.Clicks,
                       'ctr':data.CTR.toFixed(2)
                    };
                    totalImpression=totalImpression+data.Impressions;
                    totalClicks=totalClicks+data.Clicks;
                    this.props.passMetricsData(totalImpression,totalClicks,data.CTR.toFixed(2));
                }
                this.setState({
                    metricsObj,
                    totalImpression:totalImpression,
                    totalClicks:totalClicks,
                    indicator:!this.state.indicator,
                    noResultDFP:true
                });
            }
            else {
                this.setState({indicator:!this.state.indicator});
            }
        }).catch(error=>{console.log("Exception:", error);});
    }

    componentWillReceiveProps(newprops){
        this.renderData(newprops.totalCampaignMetaData);
    }

    render(){
       return <TotalMetricsTable  {...this.state}/>
    }
};

const mapStateToProps=state => {
    return {
        totalCampaignMetaData:state.campaignInfoMetaData.totalCampaignMetaData
    }
}
export default withRouter(connect(mapStateToProps) (CampaignTotalMetrics));