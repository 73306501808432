import filterLogoImg from '../../styles/JDicons/funnel.svg'
import defaultFilterImg from '../../styles/JDicons/checkbox_unchecked.svg'
import unsetdefaultFilterImg from '../../styles/JDicons/checkbox_checked.svg'
import deleteFilterLogo from '../../styles/JDicons/delete.svg'
import searchlogo from '../../styles/JDicons/search.svg'




const styles = theme => ({

    filterLogo: {
        forntSize: '15px',
        '&:hover': {
            color: '#000',
            cursor: 'pointer'
        }
    },
    setDefaultLogo: {
        background: `url(${defaultFilterImg}) no-repeat center center `,
        minHeight: '30px',
        cursor: 'pointer',
        backgroundSize: '20px',
        display: 'inline-block',
        width: '30px'

    },
    unSetDefaultLogo: {
        background: `url(${unsetdefaultFilterImg}) no-repeat center center `,
        minHeight: '30px',
        cursor: 'pointer',
        backgroundSize: '20px',
        display: 'inline-block',
        width: '30px'

    },
    deleteFilterLogo: {
        background: `url(${deleteFilterLogo}) no-repeat center center `,
        minHeight: '30px',
        marginLeft: '10px',
        cursor: 'pointer',
        backgroundSize: '20px',
        display: 'inline-block',
        width: '30px'

    },
    filterContainer: {
        fontSize: '18px',
        padding: '12px',
        borderBottom: '1px solid #ddd',
        position: 'relative',
        '&:hover': {
            background: '#eee',
            cursor: 'pointer'
        }

    },
    filterAction: {
        position: 'absolute',
        width: '79px',
        right: '10px',
        display: 'inline-block',
        textAlign: 'center',
        top: '15px',
        right: '10px'
    },
    defaultActive: {
        backgroundColor: '#dfdfdf',
        fontSize: '18px',
        padding: '12px',
        borderBottom: '1px solid #ddd',
        position: 'relative',
        '&:hover': {
            background: '#eee',
            cursor: 'pointer'
        }
    },
    drawercontainer: {
        backgroundColor: '#f8f9fa',
        width: '300px'
    },
    searchFilter: {
        background: `url(${searchlogo})  no-repeat 15px center `,
        padding: '12px 15px 0px 50px',
        borderBottom: '1px solid #ddd',
        backgroundSize: '25px',
        '& input': {
            borderBottom: 'none !important',
            fontSize: '14px',
            width:'75% !important'
        },
        '& span': {
            fontSize: '14px',
            marginTop: '12px',
            '&:hover': {
                color: '#399dff',
                cursor: 'pointer',
            }
        }
    },
    filterListClass: {
        '& ul': {
            width: '500px'
        },
        '& li': {
            fontSize: '18px',
            padding: '15px',
            margin: '0',
            background: '#eee',
            width: '50%',
            textAlign: 'center',
            borderRight: ' 1px solid #aaa',

        },
        '&.selectList': {
            '& li': {
                width: '100%',
            },
        },

    },
    filterClassActive: {
        background: '#d4d4d4 !important'
    },
    buttonBlock: {
        textAlign: 'right',
        '& button': {
            display: 'inline-block',
            marginLeft: '10px'
        }
    },
    overallSelectValue: {
        maxHeight: '50vh',
        overflow: 'auto !important',
    },
    textClassName: {
        background: '#d4d4d4',
        borderRadius: '10px',
        margin: '10px 0',
        paddingLeft: '15px',
        '& input': {
            borderBottom: 'none !important',
        }
    }

});
export default styles;