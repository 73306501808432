import React from 'react';
import FilterService from '../../../../service/FilterService';
import FilterUtil from '../../../../utils/FilterUtil';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAutoSuggestAdvertiser,setTotalBnechMarkAdvertiserCount } from '../../../../actions/filter/AdvertiserFilterAction';
import { setAdvertiserLevels } from '../../../../actions/filter/LevelFilterAction';
import _ from 'lodash';
import config from "../../../../config/config";
import axios from "axios";
import JSONBigInt from "json-bigint";
import AdvertiserList from "../../../../actions/benchmarking/setAdvertisersList";


const styles= {
    'overflow-y': 'scroll',
    'outline': 'none',
    'display': 'block'
};
const style2= {
    'overflow-y': 'scroll',
    'outline': 'none',
    'display': 'block',
    'height': 'auto',
    'max-height': '160px'
};

class AdvertiserSearchBox extends React.Component {
    state = {
        isOpen:false,
        advertisers:[],
        filteredAdvertiser:[],
        styles:{},
        selectedAdvertiser:'',
        noSearchResult:false,
        nullFlag:0,
        from:this.props.dateFilter.fromDate,
        to:this.props.dateFilter.toDate,
        dateRange:[{'field':'serveDate','from':this.props.dateFilter.fromDate,'to':this.props.dateFilter.toDate,includeFrom: true, includeTo: true}],
        metaData:{}
    };

    componentDidMount() {
        const {advertisers} = this.state;
        this.props.setAdvertisersList(this.removeDuplicateAdvertisers(advertisers))
    }

    cleanUpAdvertiserData=(advertisers, id, name)=>{
        var advertisersList=[];
        for(var index in advertisers) {
            advertisersList.push({id:advertisers[index][id],name:advertisers[index][id],isSelected:false});
        }
        return this.removeDuplicateAdvertisers(advertisersList);
    }

    handleOnChang=(e)=>{

        let value=e.target.value;
        if(value=="") {
            this.props.setAutoSuggestAdvertiser("","");
            this.props.setAdvertiserLevels('');
        };
        this.setState({selectedAdvertiser: value,noSearchResult:false});
        let filteredData=FilterUtil.filterByName(
            this.state.advertisers,
            e.target.value
        );
        this.props.setAdvertisersList(this.state.advertisers);
        if (this.props.levelSearch===null || this.props.levelSearch==='') {
            let searchKey=e.target.value.trim();
            let url=`${config.reports.SEARCH_BOX_FILTER}?text=${searchKey}&field=name.text`;
            let postObj = {};
            postObj.method = 'POST';
            postObj.url = url;
            postObj.crossDomain = true;
            postObj.withCredentials = true;
            postObj.data = {
                indexPrefix:config.index.ADVERTISERS,
                "dimensions": [{"name": "id","displayName": "id"}],
                "metrics": [{"name": "name","displayName": "name"}]
            };
            postObj.headers = {corp:localStorage.getItem('CorpId'),'content-type': 'application/json'};
            postObj.transformResponse = [data  => data];
            axios(postObj)
                .then(res=>{
                    let advertisersList = this.cleanUpAdvertiserData(JSONBigInt.parse(res.data), 'name','id');
                    this.setState({
                        filteredAdvertiser:advertisersList
                    },()=>{console.log('responsed',this.state.filteredAdvertiser);});
                }).catch(error=>{ console.log("error", error)});
        }

        if(filteredData.length>0)
            this.setState({isOpen:true,filteredAdvertiser:filteredData, styles:styles,noSearchResult:false});
        else
            this.setState({isOpen:true,filteredAdvertiser:[], styles:style2,noSearchResult:true});
    }

    getAdvertiserFromDfs=(metaData,level, levelSearch)=>{
        let filterService=new FilterService();
        metaData.indexPrefix=config.index.LINEITEM_SERVEDATE_METRICS;
        filterService.metaData=metaData;
        filterService.size=1000;
        filterService.rangeQueryData=this.state.dateRange;
        if(levelSearch!='' && !_.isNil(levelSearch)){
            filterService.filters=[{field:FilterUtil.getFilterMap(level),values:[levelSearch]}];
        }
        //clear advertiser textbox value whenever levels changed
        this.setState({selectedAdvertiser:""});
        //clear selected advertiser levels
        this.props.setAdvertiserLevels('');
        filterService.fetchTopFilterData()
            .then(response=>{
                if(response.hasOwnProperty('data')  && response.data.length>0){
                    let advertisers=response.data[0].values;
                    var advertisersList=[];

                    for(var index in advertisers) {
                        if(!(Number(parseFloat(advertisers[index].value)) === advertisers[index].value))
                            advertisersList.push({id:advertisers[index].key,name:advertisers[index].value,isSelected:false});
                    }
                    if(levelSearch!='')
                        this.props.setTotalBnechMarkAdvertiserCount(advertisersList.length);
                    this.setState({
                        advertisers:advertisersList
                    });
                    this.props.setAdvertisersList(this.removeDuplicateAdvertisers(this.state.advertisers))
                }
            }).catch(error=>{console.log("Exception:", error);});
    }

    fetchLevelsForSelectedAdvertiser(advertiserId){

        let filterService=new FilterService();
        let metaData={};
        metaData.indexPrefix=config.index.LINEITEM_SERVEDATE_METRICS;
        metaData.dimensions= [{
            "name":"level1",
            "displayName":"level1",
            "op":"SELECT"
        },
            {
                "name":"level2",
                "displayName":"level2",
                "op":"SELECT"
            },
            {
                "name":"level3",
                "displayName":"level3",
                "op":"SELECT"
            }];
        filterService.metaData=metaData;
        filterService.filters=[{field:'advertiserId',values:[advertiserId]}];
        filterService.size=100;
        filterService.fetchTopFilterData()
            .then(response=>{
                if(response.hasOwnProperty('data')  && response.data.length>0){
                    let advertisers=response.data;
                    this.props.setAdvertiserLevels(advertisers);
                }
            }).catch(error=>{console.log("Exception:", error);});
    }

    componentWillReceiveProps(newProps){
        let {dateRange}=this.state;
        dateRange[0].from=newProps.dateFilter.fromDate;
        dateRange[0].to=newProps.dateFilter.toDate;
        // if(newProps.levelSearch!="") {
        this.getAdvertiserFilterMetaData(newProps);
        // }
        // else {
        this.props.setTotalBnechMarkAdvertiserCount(0);
        this.setState({
            advertisers:[],
        });
        // }
    }

    setTextBoxSerachValue=(id,name)=>{
        this.props.setAutoSuggestAdvertiser(id,name);
        this.fetchLevelsForSelectedAdvertiser(id);
        this.setState({filteredAdvertiser:[], styles:{},selectedAdvertiser:name});
    }

    keyPress=(e)=>{
        if(e.keyCode == 13 && this.state.filteredAdvertiser.length==1){
            this.setTextBoxSerachValue(e.target.className,e.target.value);
        }
    }
    handleAdvertiserSelectClick=(e)=>{
        let value=e.target.text;
        this.setTextBoxSerachValue(e.target.className, value);
    }

    componentWillMount(){
        this.props.setAutoSuggestAdvertiser("","");
        this.props.setTotalBnechMarkAdvertiserCount(0);
        this.getAdvertiserFilterMetaData(this.props);
        document.addEventListener("mousedown", this.handleClickOutside,false);
    }
    handleClickOutside=(event)=>{
        if(!this.node.contains(event.target) && this.state.isOpen){
            this.setState({isOpen:false,noSearchResult:false, styles:{}});
        }
    }
    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside,false);
    }

    getAdvertiserFilterMetaData=(props)=>{

        const { isTopFilterDataAvail }=props.topFilterMetaData;
        if(isTopFilterDataAvail){
            const {advertiser}=props.topFilterMetaData;
            const {levelSearch,level}=props;
            this.state.metaData=advertiser;
            this.getAdvertiserFromDfs(advertiser,level,levelSearch);
        }
    }
    removeDuplicateAdvertisers = (advertisersList) =>{
        var lookup = {};
        var items = advertisersList;
        var result = [];
        for (var item, i = 0; item = items[i++];) {
        var name = item.name;
            if (!(name in lookup)) {
                lookup[name] = 1;
                result.push(item);
            }
            else{
                console.log("dup Advertiser {}", item );
            }
        }
        console.log("dedup Advertiser  count {}"+ JSON.stringify(result).toString());
        return result;
    }

    render() {
        return (
            <li>
                <span class="textField">Advertiser:</span>
                <span class="textField" ref={node=>this.node=node}>
                <div class="dpSearchBig input-field clearable advertiserWidth">
                    <i class="material-icons prefix">search</i>
                    <input type="text" value={this.state.selectedAdvertiser} onKeyDown={this.keyPress} onChange={this.handleOnChang} placeholder="Search" autoComplete="off" />
                    <span className="search-clear"></span>
                    <div className="searchDropdown" style={this.state.styles}>
                    <span className="arrow-up"></span>
                    <div className="searchDropdownCon">
                        <ul>
                            {
                                this.state.filteredAdvertiser.map(obj=>{
                                    return <li><a style ={{'width':'100%'}} onClick={this.handleAdvertiserSelectClick} className={obj.name}>{obj.name}</a></li>
                                }, this)
                            }
                            { this.state.noSearchResult?<ul><li><a>No Result</a></li></ul>:''}
                        </ul>
                    </div>
                    </div>
                </div>
            </span>
            </li>
        );
    }
}

const mapStateToProps=state=>{
    return {
        levelSearch:state.search.levelSearch,
        level:state.levelType.level,
        topFilterMetaData:state.topFilterMetaData,
        dateFilter:state.dateFilter
    };
}
const mapDispatchToProps=dispach=>bindActionCreators({
    setAutoSuggestAdvertiser:setAutoSuggestAdvertiser,
    setTotalBnechMarkAdvertiserCount:setTotalBnechMarkAdvertiserCount,
    setAdvertiserLevels:setAdvertiserLevels,
    setAdvertisersList:AdvertiserList
}, dispach);

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserSearchBox);