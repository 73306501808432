import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
    avatar:{
        backgroundColor:'#efefef',
        width: 'auto',
        height: 'auto',
        marginRight: '-4px',
        background: '#efefef',
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#666',
        padding: '4px 10px 4px 10px',
        borderRadius: '2px 0 0 2px',
        top: '0px'
    },
    label:{
        backgroundColor:'white',
        width: 'auto',
        height: 'auto',
        marginRight: '-4px',
        background: '#efefef',
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#666',
        padding: '4px 10px 4px 10px',
        borderRadius: '2px 0 0 2px',
        top: '0px'
    },
    root:{
        backgroundColor:'white',
    },
    divChip: {
        backgroundColor:'white',
        textAlign: '-webkit-right',
        display: 'inline-block'
    },
    primary: {
        background: 'linear-gradient(to bottom, #399dff 0%,#297dcf 100%)',
        border: 'none',
        color: '#FFFFFF'
    },
    filterChip: {
        borderRadius: 4,
        height: 20,
        minWidth: '20px !important',
        background: 'linear-gradient(to bottom, #399dff 0%,#297dcf 100%)',
        border: 'none',
        color: '#FFFFFF',
        '& span': {
            padding: 6
        }
    },
    filterChipAssignee: {
        borderRadius: 4,
        height: 20,
        minWidth: '20px !important',
        color: '#888',
        background: '#eee',
        fontSize: '11px',
        border: '1px solid #d5d5d5',
        '& span': {
            padding: 6
        }
    },
    orderChip: {
        margin: 'auto auto auto 10px',
        background: '#373d47',
        color: '#fff',
        padding: '5px 0',
        textTransform: 'uppercase',
        fontSize: '13px',
        fontWeight: 'bold',
        height: '28px',
        borderRadius: '3px',
        fontFamily:"Roboto, Helvetica, Arial, sans-serif",
        border:'none',
        '& > span':{
            userSelect: 'auto',
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
            paddingRight: '10px'
        },
        // '&:hover':{
        //     color: '#FFFFFF',
        //     margin: 'auto auto auto 10px',
        //     background: '#373d47 !important',
        //     borderRadius: '3px'
        // }
    },
    clickableChip: {
        padding: '5px 3px',
        border: '1px solid #d4d4d4',
        borderRadius: 3,
        color: "#666",
        cursor: 'pointer',
        margin: '10px 10px 0 0',
        //line-height: 23px;
        height: 30,
        '&:hover':{
            backgroundColor:'transparent !important'
        },
        '&:focus':{
            backgroundColor:'transparent !important'
        },
        '&:active':{
            boxShadow:'none',
            backgroundColor:'transparent !important'
        }
    },
    deleteIcon:{
        fontSize: 7,
        color: '#666',
        transition: 'none',
        top: '0px !important',
        padding:'0px 0px 0px 9px',
        '&:hover':{
            color: 'black !important'
        }
    }
})

class CustomChip extends PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, label, style, variant, value, id, variantSecondary,filterLabel } = this.props;
        if (variant === 'clearFilter') {
            return (
                <React.Fragment>
                    <Chip
                        key='ads'
                        avatar={<Avatar>{filterLabel}</Avatar>}
                        variant="outlined"
                        size="small"
                        label={label}
                        disabled={true}
                        deleteIcon={<i></i>}
                        // onClick={(event)=>{this.handleChipClick(value, id)}}
                        className={classes.clickableChip}
                        classes={{avatar:classes.avatar,root:classes.root}}
                    />
                </React.Fragment>
            )
        } else {
            return (
                <div className={classes.divChip}>
                    <Chip label={label} className={`${classes.chip} ${variant == "primary" ? classes.primary : variantSecondary == "assignee" ? classes.filterChipAssignee : variant == "filter" ? classes.filterChip : classes.orderChip}`} style={style} />
                </div>
            )
        }
    }
}

export default withStyles(styles)(CustomChip);