"use strict";
import C from '../constants';



export const setDefaultFiltersList = (payload) => {
    return {
        type: C.SET_DEFAULT_FILTERS_LIST,
        payload: payload
    }
}
export const addDefaultFiltersList = (payload) => {
    return {
        type: C.ADD_DEFAULT_FILTERS_LIST,
        payload: payload
    }
}
export const selectDefaultValueList = (payload) => {
    return {
        type: C.SELECT_DEFAULT_VALUE_LIST,
        payload: payload
    }
}
export const setUpdateFilter = (payload) => {
    return {
        type: C.SET_UPDATE_FILTER,
        payload: payload
    }
}