import { ClickAwayListener, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import cookiesIcon from "../styles/JDicons/cookie.svg"

const useStyles = makeStyles(theme => ({
    img: {
        position: 'relative',
    },
    dropDown: {
        position: 'absolute',
        display: 'none',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
        width: '10vw',
        right: '30px'
    },
}));

const Cookies = () => {
    const classes = useStyles();

    const togglePopover = () => {
        let x = document.getElementById("dropdownDiv");
        if (x.style.display === "none") {
            x.style.display = "flex";
        } else {
            x.style.display = "none";
        }
    }

    const popoverClickaway = () => {
        let x = document.getElementById("dropdownDiv");
        x.style.display = "none";
    }

    return (
        <ClickAwayListener onClickAway={popoverClickaway}>
            <div>
                <Tooltip title={"COOKIES"}>
                    <IconButton onClick={togglePopover} id="cookie" color="inherit">
                        <img src={cookiesIcon} className={classes.img}></img>
                    </IconButton>
                </Tooltip>

                <div id="dropdownDiv" className={classes.dropDown}>
                    <a style={{ textDecoration: 'none', cursor: 'pointer', color: "#000000" }} target="_blank" href="https://cookienotice.deloitte.com">Cookies</a>
                    <a style={{ textDecoration: 'none', cursor: 'pointer', color: "#000000" }} className="optanon-toggle-display">Cookie Settings</a>
                </div>
            </div>
        </ClickAwayListener>
    )
}

export default Cookies;
