import React from 'react';
import CustomizedDialogs from './../../components/modelPopup/sendMail/SelectComponentModel'
export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);
  const sendPdf = () => props.sendPdf(bodyRef.current);
  return (< section className="pdf-container" >

    <section> < button class="btn btn-small btn-grey btn-xp btn-pdf" onClick={createPdf} > EXPORT </button>
    <CustomizedDialogs sendPdf={sendPdf} />
    </section >
     < section className="pdf-body"
      ref={bodyRef} > {props.children} </section> </section >
  )
}