import React from 'react';
import { Link } from 'react-router-dom';

const RenderNavMenu=({menus,handleRouteClick})=>{

    return(
        <aside class="sidebar">
            <div id="sideNav" class="side-menu open">
                <ul class="list tabs" id="sideNavTab">
                    {
                    menus.map(menuObj=>{

                        return menuObj.displayName === 'Help' ?
                        <li class="tab" id={menuObj.displayName} onClick={handleRouteClick}>
                            <Link className={menuObj.isAtiveRoute} target="_blank" exact to={menuObj.url}>
                                <div class="spriteWrap">
                                    <span class={`menuSprite ${menuObj.className}`}></span>
                                </div>
                                <p>{menuObj.displayName}</p>
                            </Link> 
                        </li>:
                        <li class="tab" id={menuObj.displayName} onClick={handleRouteClick}>
                            <Link className={menuObj.isAtiveRoute} exact to={menuObj.url}>
                                <div class="spriteWrap">
                                    <span class={`menuSprite ${menuObj.className}`}></span>
                                </div>
                                <p>{menuObj.displayName}</p>
                            </Link>
                        </li>
                    })
                    }
                </ul>
            </div>
        </aside>
    )
};

export default RenderNavMenu;