import React, { Component } from 'react';
import CampaignsMetricsCount from './CampaignsMetricsCount';
import CampaignListing from './CampaignListing';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonUtil from '../../utils/CommonUtil';
import config from '../../config/config';
import ReportingConfigService from '../../service/ReportingConfigService';
import ActivityIndicator from '../common/ActivityIndicator';
import Filters from '../filters';
import _ from 'lodash';
//import Toast from '../common/Toast';
import FilterUtil from '../../utils/FilterUtil';
import { setSearchBoxFilter } from '../../actions/filter/SearchBoxFilterACtion';
import { activateAndInActivateSpinner } from '../../actions/spinner/coverSpinner';
import DataFetchService from '../../service/DataFetchService';
import ReportUtil from '../../utils/ReportUtil';
import { setDateTypeFilter } from '../../actions/filter/DateTypeFilterAction';
import { setAdvertiser, autoAdvFilterName } from "../../actions/filter/AdvertiserFilterAction";
import { setRegion } from "../../actions/filter/ReginFilterAction";
import { setAdServerFilter } from "../../actions/filter/AdServerFilterAction";
import { setDateFilter } from "../../actions/filter/DateFilterAction";
import { setDefaultFiltersList } from '../../actions/filter/DefaultFiltersAction';
import FilterService from '../../service/FilterService';
import { setSalesRep } from '../../actions/filter/SalesRepFilterAction';


/*
 * Author:Umakant Mane(umakant.b@2adpro.com)
 * Date:2019-05-15
*/

const styles = {
   "margin-left": "50%",
   'position': 'relative',
   'left': '-20px',
   "margin-top": "200px"
};

class DashBoard extends Component {

   constructor(props) {
      super(props);
      this.state = {
         metaData: [],
         filters: {},
         loading: true,
         adserverFilterCount: '',
         k: '',
         responseValue: [],
         filterConfig: {
            dateType: true,
            date: true,
            region: true,
            adServer: true,
            advertiser: true,
            webSite: false,
            saleRep: false,
            SetDefaultFilters: true,
            saveFilter: true,
            submitFilter: true,
            exportFilter: true,

         }
      };
      this.getDashBoardMetaData = this.getDashBoardMetaData.bind(this);
      this.getDashBoardData = this.getDashBoardData.bind(this);
   }

   handleExportReportClick = () => {

      this.props.activateAndInActivateSpinner(true);
      let metaData = this.state.metaData.filter(item => {
         return item.chartType == 'TABLE';
      });
      let filtersObj={};
      let filters = FilterUtil.setFilterObj(filtersObj);

      if (metaData.length > 0) {

         let selectedFilters = FilterUtil.setFilterObj(this.props);
         filters = FilterUtil.getFilterObj(FilterUtil.setAllSitesToFilter(selectedFilters));
         let rangeQueryData = FilterUtil.getDateRangeQuery(selectedFilters);
         metaData = metaData[0];
         let dataFetchService = new DataFetchService();
         dataFetchService.url = `${config.reports.EXPORT}/campaigns`;
         dataFetchService.responseType = 'blob';
         dataFetchService.indexPrefix = metaData.indexPrefix;
         dataFetchService.size = 10000;
         dataFetchService.rangeQueryData = rangeQueryData;
         dataFetchService.dimensions = metaData.dimensions;
         dataFetchService.metricObj = metaData.metrics;
         dataFetchService.operationType = metaData.operationType;
         dataFetchService.filters = filters;
         dataFetchService.fethData()
            .then(response => {
               this.props.activateAndInActivateSpinner(false);
               //if(!_.isNil(data)) {
               ReportUtil.exportReport(
                  CommonUtil.reportFileName('CampaignReport'),
                  response.data
               )

               //}
            }).catch(err => { this.props.activateAndInActivateSpinner(false); console.log('Exception occured in export dashboard report', err) });
      }

   }

   getDashBoardData = (submitFilter = '') => {
      this.setState({
         metaData: [],
         filters: {}
      })
      let advertiserId = localStorage.getItem("advertiserId");
      let selectedSalesRep = this.props.selectedSalesRep;
      let adServerFilter = this.props.adServerFilter;
      let advertiserFilter = this.props.advertiserFilter;
      //let filters = new FilterUtil();
      let filtersObj={};
      let filters = FilterUtil.setFilterObj(filtersObj);
      let regionFilter =  this.props.regionFilter;
      this.getDashBoardMetaData()
         .then(response => {
            if (response != undefined && response.hasOwnProperty("data") && response.data[0] != undefined) {
               if (submitFilter == '') {
                  this.props.setAdvertiser(advertiserFilter);
                  this.props.setRegion(regionFilter);
                  this.props.setAdServerFilter(adServerFilter);
                  this.props.selectedSalseRep(selectedSalesRep);
                  //this.props.setDateTypeFilter("Start Date");
               }
               let data = JSON.parse(JSON.stringify(this.props));
               // if (advertiserId) {
               //    data.advertiserFilter.push(advertiserId);
               //    data.regionFilter = JSON.parse(localStorage.getItem("siteId"));
               //    data.dateTypeFilter = "Run Date";
               //    data.adServerFilter = ["GAM"];
               //    this.props.setDateFilter(data.dateFilter);
               //    this.props.setDateTypeFilter("Run Date");
               //    this.props.setRegion(JSON.parse(localStorage.getItem("siteId")));
               //    this.props.setAdServerFilter(["GAM"]);
               //    this.props.setAdvertiser([advertiserId]);
               // }
               filters = FilterUtil.setFilterObj(data);
               //filters.setFilterObj(data);
               var { adserverFilterCount } = this.state;

               switch (filters.adServers.length) {
                  case 1:
                     this.setState({ adserverFilterCount: 1 });
                     break;
                  case 2:
                     this.setState({ adserverFilterCount: 2 });
                     break;
                  case 3:
                     this.setState({ adserverFilterCount: 3 });
                     break;
                  default:
                     this.setState({ adserverFilterCount: 0 });
                     break;
               }
               this.setState({
                  metaData: response.data[0].charts,
                  filters: filters,
                  loading: false,

               });
            }
            else {
               //trigger toast message
               console.log("No meta data found!");
            }
         })
         .catch(error => {
            //trigger toast message
            console.log("error", error);
         });
      localStorage.setItem("advertiserId", '');
   }
   getDashBoardMetaData = () => {
      var id = config.reports.ids.DASHBOAD_START_END_DATE_ID;
      if (this.props.dateTypeFilter === "Run Date")
         id = config.reports.ids.DASHBOAD_SERVE_DATE_ID;
      else if (localStorage.getItem("advertiserName"))
         id = config.reports.ids.DASHBOAD_SERVE_DATE_ID;

      this.state.metaData = [];
      let reportingConfigService = new ReportingConfigService();
      reportingConfigService.id = id;
      return reportingConfigService.fetchMetaDataConfig();
   }
   async componentWillMount() {
      let filterService = new FilterService();
      let salesRepAdvertiser = [];
      let salesRepRegion = [];
      let salesRepAdServerFilter = [];
      let salesRepSalseRep = [];

      if (this.props.selectedSalesRep.length <= 0 && this.props.adServerFilter.length <= 0 && this.props.advertiserFilter.length <= 0 && this.props.regionFilter.length <= 0 && this.props.search == '') {
         await filterService.fetchDefaultFilters()
            .then(response => {
               response.data.response.map(item => {
                  item.filterConfig = JSON.parse(item.filterConfig)
                  let checksalesrepdefault = item.filterConfig[3]['filterValues'];
                  if (checksalesrepdefault.length > 0 && !item.filterConfig[4]) {
                     salesRepAdvertiser = item.filterConfig[0]['filterValues'];
                     salesRepRegion = item.filterConfig[1]['filterValues'];
                     salesRepAdServerFilter = item.filterConfig[2]['filterValues'];
                     salesRepSalseRep = item.filterConfig[3]['filterValues'];
                  }
                  return item;
               })
               this.props.setDefaultFiltersList(response.data.response);
               let defaultVaule = this.props.responseValue.find(f => f.isDefault == 1)
               if (defaultVaule != undefined) {
                  defaultVaule.filterConfig.map((item, index) => {
                     if (item.filterType == 'advertiser') {
                        this.props.setAdvertiser(item.filterValues);
                     }
                     else if (item.filterType == 'region') {
                        this.props.setRegion(item.filterValues);
                     }
                     else if (item.filterType == 'adServer') {
                        this.props.setAdServerFilter(item.filterValues);
                     }
                     else if (item.filterType == 'salesRep') {
                        this.props.selectedSalseRep(item.filterValues);
                     }
                     else if (item.filterType == 'advFilterName') {
                        this.props.autoAdvFilterName(item.filterValues);
                     }
                  })
               } else if (salesRepSalseRep.length > 0) {
                  this.props.setAdvertiser(salesRepAdvertiser);
                  this.props.setRegion(salesRepRegion);
                  this.props.setAdServerFilter(salesRepAdServerFilter);
                  this.props.selectedSalseRep(salesRepSalseRep);
               }

            }).catch(error => { console.log("Exception:", error); });
      }
      this.getDashBoardData();
   }

   componentDidMount() {
      const params = new URLSearchParams(this.props.location.search);
      const urnVal = params.get('urn');
      if (urnVal) {
         this.handleFilterClick();
         this.props.setSearchBoxFilter({ poNumber: urnVal, isSet: false });
      }
   }
   componentDidUpdate(prevProps) {
      if (prevProps.search != this.props.search && this.props.search == '') {
         this.handleFilterClick();
         this.props.setSearchBoxFilter({ poNumber: '', isSet: false });
      }
   }

   handleFilterClick = (SaveFilters = '') => {
      this.setState({ loading: true });
      if (SaveFilters == '') {
         this.getDashBoardData();
      } else {
         this.getDashBoardData('submitFilter');
      }

   }

   componentWillReceiveProps(newProps, prevProps) {
      const { search, isSet } = newProps;
      const params = new URLSearchParams(this.props.location.search);
      const urnVal = params.get('urn');
      if ((search != undefined && search != '' && isSet)) {
         this.handleFilterClick();
         this.props.setSearchBoxFilter({ poNumber: search, isSet: false });
         if (window.history.pushState) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("urn", search);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
         }
      }

   }

   renderTemplateMetricsCount = (filters, metaData) => {
      let tempArray = [];
      let i = 0;
      _.forEach(this.props.topMetricsCount, (key, val) => {
         tempArray.push(<CampaignsMetricsCount
            data={{
               key: val,
               val: key
            }}
            metaData={metaData[i]}
            filters={filters}
         />)
         i++;
      });
      return tempArray;
   }

   render() {
      const { filterConfig, loading, metaData, filters, adserverFilterCount } = this.state;
      return (
         <>
            <div class="body-overlay"></div>
            <div class="content-body">
               <div id="task" class="active">
                  <div class="content-header rhs filters-container">
                     <Filters filterConfig={filterConfig}
                        handleExportReportClick={this.handleExportReportClick}
                        handleFilterClick={this.handleFilterClick} />
                     {/* <Toast /> */}
                  </div>
                  {loading ?
                     <ActivityIndicator style={styles} height="40" width="40" indicating={loading} /> :
                     <div class="content-body rhs">
                        {/* campaign/impression/clicks count filter */}
                        <ol class="row responsiveDisplay">
                           {this.props.adServerFilter.length < 2 ?
                              metaData.map(mdata => {
                                 if (mdata.chartType != 'TABLE') {
                                    return <CampaignsMetricsCount
                                       metaData={mdata}
                                       filters={filters}
                                    />
                                 }
                              })
                              :
                              this.renderTemplateMetricsCount(filters, metaData)
                           }
                        </ol>
                        {/* campaign listing components */}

                        {
                           metaData.map(mdata => {
                              if (mdata.chartType == 'TABLE') {
                                 return <CampaignListing
                                    adserverFilterCount={adserverFilterCount}
                                    metaData={mdata}
                                    filters={filters}
                                 />
                              }
                           })
                        }
                     </div>}
               </div>
            </div>
         </>
      )
   }
}

const mapStateToProps = state => {
   return {
      dateTypeFilter: state.dateType.dateFilterType,
      dateFilter: state.dateFilter,
      adServerFilter: state.adServerFilter.adServers,
      advertiserFilter: state.advertiser.advertiserFilter,
      regionFilter: state.regionFilter.regions,
      search: state.search.poNumber,
      isSet: state.search.isSet,
      topMetricsCount: state.topMetricsCount,
      responseValue: state.defaultFiltersreducer.defaultFilters,
      selectedSalesRep: state.salesRepFilterReducer.selectedSalesRep

   }
}

const mapDispatchToProps = dispatch => bindActionCreators({
   setRegion: setRegion,
   setDateFilter: setDateFilter,
   setAdServerFilter: setAdServerFilter,
   setSearchBoxFilter: setSearchBoxFilter,
   activateAndInActivateSpinner: activateAndInActivateSpinner,
   setDateTypeFilter: setDateTypeFilter,
   setAdvertiser: setAdvertiser,
   setDefaultFiltersList: setDefaultFiltersList,
   selectedSalseRep: setSalesRep,
   autoAdvFilterName: autoAdvFilterName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);