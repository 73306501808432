import React from 'react';

const ReportDisplayName=()=>{
    const displayName='Industry benchmark';
    return(
        <div class="jobno">
        <span class="inline-block header">{displayName}</span>
    </div>
    )
}

export default ReportDisplayName;