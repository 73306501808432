import React, { Component } from 'react';
import LineChart from '../../chartjs/LineChart';
import ReportDisplayMessage from './ReportDisplayMessage';
import DisplayTopMetrics from './DisplayTopMetrics';
import ReportDisplayName from './ReportDisplayName';
import TopFilters from './TopFilters';
import ReportingConfigService from '../../../service/ReportingConfigService';
import { connect } from 'react-redux';
import config from '../../../config/config';
import FilterUtil from '../../../utils/FilterUtil';
import { setBenchMarkReportFilterDate } from '../../../actions/filter/DateFilterAction';
import {bindActionCreators} from 'redux';
class BenchMarkReport extends Component {
    state={
        bestCtr:'',
        topMetricsMetaData:[],
        lineChartMetaData:[],
        filters:[]
    }
    handleSiteOpenCloseClick=()=>{
        this.setState({
            isSiteOpen:this.state.isSiteOpen==''?'active':''
        })
    }

    handleSubmitClick=()=>{
        const { levelSearch, advertiser}=this.props;
        if(levelSearch!='' || advertiser!='') {
            this.setState({
                topMetricsMetaData:[],
                lineChartMetaData:[],
                filters:[]
            });
            this.getReportMetaData();
        }
    }

    getBestCtr=(value)=>{
        this.state.bestCtr=value;
    }

    handleExportReportClick=()=>{
        console.log("Export report handler");
    }
    getReportMetaData=()=>{

        let reportingConfigService = new ReportingConfigService();
        reportingConfigService.id=config.reports.ids.BENCHMARKING_REPORT_ID;
        let filtersObj={};
        let filters = FilterUtil.setFilterObj(filtersObj);
        reportingConfigService.fetchMetaDataConfig()
            .then(response=>{
                if(response.data!=undefined && response.data.length>0){
                    let charts=response.data[0].charts;
                    let lineChartMetaData=[];
                    let topMetricsMetaData=[];
                    for(let item of charts){
                        switch(item.chartType){
                            case 'JD_SPLINE':
                                lineChartMetaData.push(item);
                                break;
                            case 'COUNT':
                                topMetricsMetaData.push(item);
                                break;
                        }
                    }

                    //let filters={};
                    filters.filter=FilterUtil.setBenchMarkFilter(this.props);
                    //Defaul filter for benchmarking, to get only GAM data
                    filters.filter.push({field: "adServer", values: ["GAM"]});
                    filters.rangeQueryData=FilterUtil.getServerDateFilter({
                        "fromDate": this.props.dateFilter.benchMarkFromDate,
                        "toDate": this.props.dateFilter.benchMarkToDate
                    });
                    this.setState({
                        topMetricsMetaData,
                        lineChartMetaData,
                        filters:filters
                    });
                }
            })
            .catch(error=>{console.log("error", error);});
    }
    /* componentWillMount(){
        this.getReportMetaData();
    } */

    render() {
        console.log("benchmarking",this.state.filters)
        const {topMetricsMetaData, lineChartMetaData,filters }=this.state;
        const topMetricsComponent=topMetricsMetaData.map(metaData=>{
            return <DisplayTopMetrics props={this.props} metaData={metaData} filters={filters} />;
        });
        const lineChartComponent=lineChartMetaData.map(lineChartMeta=>{
            return <LineChart getBestCtr={this.getBestCtr} lineChartMetaData={lineChartMeta} filters={filters} />
        });
        return (

            <div class="jobdetails scroll rhs" id="pagepoup">
                <div class="content-header scroll-animate ">
                    <ReportDisplayName />
                    <TopFilters
                        handleSubmitClick={this.handleSubmitClick}
                        handleExportReportClick={this.handleExportReportClick}
                    />
                </div>
                <div class="content-body " style={{"padding-top":"48px"}}>
                    <ReportDisplayMessage />
                    <ol class="row responsiveDisplay">
                        {topMetricsComponent}
                    </ol>

                    {lineChartComponent}

                </div>
                <div class="detailsSS"></div>
            </div>

        );
    }
}

const mapStateToProps=state=>{
    return {
        benchMarkReportFilterDate:state.benchMarkReportFilterDate,
        level:state.levelType.level,
        levelSearch:state.search.levelSearch,
        advertiser:state.advertiser.autoSuggestAdvertiser,
        dateFilter :state.dateFilter
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    setBenchMarkReportFilterDate: setBenchMarkReportFilterDate
 }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BenchMarkReport);