"use strict";
import AuthUtil from './AuthUtil';
import config from '../config/config';
import axios from 'axios'; 
import _ from 'lodash';

class UserUtil {

    constructor(){};

     getUserDetails  = async () => {
        var authUtil = new AuthUtil()
          , _userID = authUtil.getCookieByKey("_userID");
        let response = await axios({
            method: 'GET',
            url:`${config.cms.METADATA}/metadata/userdetails?username=${_userID}`,
            withCredentials: true,
            crossDomain: true
        });
        if(response.responseCode == 220) {
            authUtil.renderLoginPage();
        }
        else {  
            var result = response.data.response;
            var corpId = result.corporateId
              , clusterId = result.clusters["0"].clusterId
              , userID = result.userID
              , sites = []
              , siteNames = []
              , userName = result.name;
              
              /* let siteId=0;
              try{
                 //siteId = result.clusters["0"].sites["0"].siteId;
                 siteId = result.clusters[2]['sites'][0]['siteId'];
              }catch(e){
                  try{
                    siteId = result.clusters["0"].sites["0"].siteId;
                  }catch{}
              }; */
              
              localStorage.setItem('CorpId', corpId);
              localStorage.setItem('ClusterId', clusterId);
              //localStorage.setItem('SiteId', siteId);
              localStorage.setItem('UserName',userName);
              localStorage.setItem("UserId",userID);
              result.clusters.map(cluster => {
                  cluster.sites.map(site => {
                      sites.push(parseInt(site.siteId))
                      siteNames.push(site.siteName);
                  });
              });
            localStorage.setItem("siteId", JSON.stringify(sites));
            localStorage.setItem("defaultSite", JSON.stringify(result.defaultSite));
            localStorage.setItem("siteNames", JSON.stringify(siteNames));
            let permissions=[];
            if(!_.isNil(result.permissions["*"]))
            permissions = result.permissions["*"];
            else {
                let temp=[];
                for(let index in sites){
                    if(!_.isNil(result.permissions[Number(sites[index])]))
                    temp=result.permissions[Number(sites[index])];
                    permissions=[...permissions, ...temp];
                }
            }
            return _.isNil(permissions)?[]:permissions;
      }
    }
}

export default UserUtil;