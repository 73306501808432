"use strict";
import C from '../../actions/constants';
const initialState = {
    level: 'Level 1',
    advertiserLevels:{}
}
export default function levelTypeFilterReducer(state=initialState, action) {
    switch(action.type) {
      case C.SET_BENCHMARK_LEVEL_FILTER:
        return {
          ...state,
          level:action.level
      };
      case C.SET_ADVERTISER_LEVELS:
        return {
          ...state,
          advertiserLevels:action.advertiserLevels
      };
      default:
        return state;
    }
};


